import CheckoutStore from "store/checkoutStore"
import LocationStore from "store/locationStore"
import PaymentStore from "store/paymentStore"
import SessionStore from "store/sessionStore"
import UIStore from "store/uiStore"
import SearchStore from "store/searchStore"
import CartStore from "store/cartStore"
import CriteriaStore from "store/criteriaStore"
import CmsStore from "store/cmsStore"
import CustomerStore from "store/customersStore"
import PortalStore from "store/portalStore"
import ProductStore from "store/productStore"
import RimStore from "store/rimStore"
import TireStore from "store/tireStore"
import ServiceStore from "store/serviceStore"
import AppointmentStore from "store/appointmentStore"
import CarStore from "store/carStore"
import ExternalCustomer from "store/externalCustomer"
import WheelStore from "store/wheelStore"
import ConfirmationStore from "store/confirmationStore"
import config from "./store.config"
import {toast} from "react-toastify"

const promiseCache = {}

class Shop {

    constructor() {
        this.checkoutStore = global.checkoutStore = new CheckoutStore()
        this.paymentStore = global.paymentStore = new PaymentStore()
        this.searchStore = global.searchStore = new SearchStore()
        this.serviceStore = global.serviceStore = new ServiceStore()
        this.criteriaStore = global.criteriaStore = new CriteriaStore()
        this.appointmentStore = global.appointmentStore = new AppointmentStore()
        this.uiStore = global.uiStore = new UIStore()
        this.cartStore = global.cartStore = new CartStore()
        this.carStore = global.carStore = new CarStore()
        this.cmsStore = global.cmsStore = new CmsStore()
        this.customerStore = global.customerStore = new CustomerStore()
        this.portalStore = global.portalStore = new PortalStore()
        this.productStore = global.productStore = new ProductStore()
        this.rimStore = global.rimStore = new RimStore()
        this.wheelStore = global.wheelStore = new WheelStore()
        this.tireStore = global.tireStore = new TireStore()
        this.sessionStore = global.sessionStore = new SessionStore()
        this.externalCustomer = global.externalCustomer = new ExternalCustomer()
        this.locationStore = global.locationStore = new LocationStore()
        this.confirmationStore = global.confirmationStore = new ConfirmationStore()
    }


    fetch = (url, options = {}) => {
        return fetch(`${config.API_BASE}/${url}`, {
            ...options,
            mode: "cors",
            headers: {
                "IdentityUserId": sessionStore.identityUserId,
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": sessionStore.authorization
            }
        })
            .then(res => {
                // if(res.status === 401) toast.error("Bitte melden Sie sich an.")
                return res
            })
    }


    fetchOnceCache = {}
    fetchOnce = (url, options = {}) => {
        // if (this.fetchOnceCache[url]) return this.fetchOnceCache[url]
        let promise = fetch(`${config.API_BASE}/${url}`, {
            ...options,
            mode: "cors",
            headers: {
                "IdentityUserId": sessionStore.identityUserId,
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": sessionStore.authorization
            }
        })
            .then(res => {
                // if(res.status === 401) toast.error("Bitte melden Sie sich an.")
                return res
            })

        this.fetchOnceCache[url] = promise
        return promise
    }

    cachedFetch = (url, options = {}) => {
        if (promiseCache[url]) {
            return promiseCache[url]
        }
        let expiry = 5 // 2 min default
        if (typeof options === "number") {
            expiry = options
            options = undefined
        } else if (typeof options === "object") {
            expiry = options.seconds || expiry
        }
        let cacheKey = url
        let cached = sessionStore.getItem(cacheKey)
        let whenCached = this.sessionStore.getItem(cacheKey + ":ts")
        if (cached !== null && whenCached !== null) {
            let age = (Date.now() - whenCached) / 1000
            if (age < expiry) {
                return Promise.resolve(JSON.parse(cached))
            } else {
                // We need to clean up this old key
                this.sessionStore.removeItem(cacheKey)
                this.sessionStore.removeItem(cacheKey + ":ts")
            }
        }

        let promise = fetch(url, options)
            .then(async res => {
                if (res.status === 200) {
                    return res.json()
                } else {
                    let error = await res.text()
                    return {error}
                }
            })
            .then(content => {
                if (content) {
                    this.sessionStore.setItem(cacheKey, JSON.stringify(content))
                    this.sessionStore.setItem(cacheKey + ":ts", Date.now())
                }
                return content
            })

        promiseCache[url] = promise
        return promise
    }
}

const shop = new Shop()
window["shop"] = shop

export default shop
