import {observable, makeObservable, action } from "mobx"

export default class ConfirmationStore {
    overriddenProfile = {}

    overrideProfile(profile) {
        this.overriddenProfile = profile
    }

    constructor() {
        makeObservable(this, {
            overriddenProfile: observable,
            overrideProfile: action
        })
    }
}
