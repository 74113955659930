import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function Icon( props ) {
    const { icon, className = "", ...rest } = props
    if ( icon === null ) return null
    return <FontAwesomeIcon className={`ui icon ${className}`} icon={icon} {...rest} />
}

Icon.Light = Icon
Icon.Solid = Icon

global.Icon = Icon
