import axios from "axios"
import { action, observable, makeObservable } from "mobx";
import React from "react"
//// import criteriaStore from "store/criteriaStore"
import config from "store/store.config"
// // import sessionStore from "store/sessionStore"
import session from "utils/session"
import moment from "moment"
import _ from "lodash-es"
import { parsePlate, VehicleProfile } from "store/VehicleProfile"


const compareReducedSlug = ( a, b ) => {
    let s1 = a.split( "-" ).join( "" )
    let s2 = b.split( "-" ).join( "" )
    return s1 === s2
}

class Customer {
    
    storeById = new Map()
    stores = []
    vehicles = []
    addresses = []
    primaryVehicle = null;
    
    constructor( data = {}, licensePlate ) {
        makeObservable( this, {
            primaryVehicle  : observable,
        } );
        
        Object.assign( this, data )
        
        this.licensePlate = licensePlate || data.licensePlate
        this.storeById = new Map()
        this.stores = []
        this.licensePlateSlug = parsePlate( this.licensePlate )
        
        // console.log( "NEW CUSTOMER", this.licensePlate, this.licensePlateSlug )
        
        if ( data.storeReferences ) {
            data.storeReferences.forEach( store => {
                this.storeById.set( store.storeKey, store )
            } )
            this.stores = [...this.storeById.entries()].map( a => a[ 1 ] )
        }
        
        this.vehicles = this.vehicles.map( data => {
            let vehicleProfile = new VehicleProfile( data )
            sessionStore.vehicleById.set( vehicleProfile.vehicleId, vehicleProfile )
            // criteriaStore.vehicleById.set( vehicleProfile.vehicleId, vehicleProfile )
            return vehicleProfile
        } )
        
        this.primaryVehicle = _.first( this.vehicles.filter( v => {
            // licensePlateSlug could also be a VIN...
            if ( v.vehicleIdentificationNumber === this.licensePlateSlug ) return true
            return compareReducedSlug( v.licensePlateSlug, this.licensePlateSlug )
        } ) )
        
        if ( this.primaryVehicle ) {
            // console.log( "LOADING", this.primaryVehicle )
            this.loadVehicle( this.primaryVehicle )
        }
    }

    
    
    async loadVehicle( vehicleProfile ) {
        let vehicle = await criteriaStore.findCar( vehicleProfile )
            .then( res => {
                res.externalCustomerContext = vehicleProfile
                return res
            } )
            .catch( err => false )
        // console.log( "FOUND VEHICLE", { vehicleProfile, vehicle } )
        sessionStore.vehicleById.set( vehicleProfile.vehicleId, vehicleProfile )
    }
}

export default class ExternalCustomer {
    customerById = new Map();
    customerByPlate = new Map();
    
    currentCustomer = false;
    currentVehicle = false;
    notificationEmail = "";
    orderRef = "";
    licensePlate = "";
    
    vehicles = [];
    vehicleById = new Map();
    
    
    constructor() {
        makeObservable( this, {
            customerById                : observable,
            customerByPlate             : observable,
            currentCustomer             : observable,
            currentVehicle              : observable,
            notificationEmail           : observable,
            orderRef                    : observable,
            licensePlate                : observable,
            vehicles                    : observable,
            vehicleById                 : observable,
            setEmail                    : action,
            hydrate                     : action,
            setOrderRef                 : action,
            setLicensePlate             : action,
            selectCustomer              : action,
            loadVehicles                : action,
            selectVehicleFromReferenceId: action,
            selectVehicle               : action,
            fetch                       : action
        } );
    }
    
    
    getBillingAddress() {
        if ( !this.currentCustomer || !this.currentCustomer.addresses ) return false
        const address = _.first( this.currentCustomer.addresses.filter( a => a.type === "Default" ) )
        if ( address && address.companyName ) {
            address.title = "Firma"
        }
        return address
    }
    
    setEmail( email ) {
        this.notificationEmail = email
    }
    
    get currentLicensePlate() {
        if ( this.currentVehicle ) {
            return this.currentVehicle.licensePlate
        } else {
            
            if ( this.currentCustomer )
                return this.currentCustomer.licensePlate
        }
        
        return false
    }
    
    hydrate() {
        this.currentCustomer = new Customer( session.get( "currentCustomer" ) )
        this.currentVehicle = session.get( "currentVehicle" )
        if ( this.currentCustomer ) this.customerByPlate.set( this.currentCustomer.licensePlate, this.currentCustomer )
        if ( this.currentCustomer ) {
            // this.loadVehicles( this.currentCustomer.vehicles )
            this.notificationEmail = this.currentCustomer.email
        }
    }
    
    getDefaultStore( customer ) {
        const DEFAULT = "AUTOHAUS_NAGEL_ERDING"
        if ( customer && customer.storeReferences ) {
            let lastStore = false
            customer.storeReferences
                .filter( store => store.storeKey !== "" && store.name !== "NAGEL_SERVICES" )
                .forEach( storeRef => {
                    if ( !lastStore || moment( lastStore.lastChange ).isBefore( storeRef.lastChange ) ) {
                        lastStore = storeRef
                    }
                } )
            return lastStore.storeKey || DEFAULT
        }
        
        return DEFAULT
        
    }
    
    setOrderRef = value => {
        this.orderRef = value
    }
    
    setLicensePlate = value => {
        this.licensePlate = value
    };
    
    selectCustomer( customerData ) {
        
        let customer = new Customer( customerData, this.licensePlate )
        this.currentCustomer = customer
        this.notificationEmail = customer.email;
        session.set( "currentCustomer", customer )
    }
    
    loadVehicles( vehicles = [] ) {
        let promises = vehicles
            .map( data => {
                // --> console.log( "LOADING CARS", this.currentCustomer.email, data.licensePlate, data.vehicleIdentificationNumber )
                return criteriaStore.findCar( data )
                    .then( res => {
                        res.externalCustomerContext = data
                        return res
                    } )
                    .catch( err => false )
            } )
        
        return Promise.all( promises )
            .then( data => {
                // --> console.log( "RECEIVED", {data} )
                let vehicles = []
                let vehicleById = new Map()
                data.forEach( vehicle => {
                    if ( vehicle ) {
                        vehicles.push( vehicle )
                        vehicleById.set( vehicle.referenceId, vehicle )
                    }
                } )
                
                // --> console.log( {vehicles} )
                this.vehicles = vehicles
                this.vehicleById = vehicleById
                
                if ( vehicles.length > 0 ) {
                    sessionStore.profile.setVehicles( this.vehicles )
                }
            } )
        
    }
    
    async selectVehicleFromReferenceId( referenceId ) {
        const car = await criteriaStore.getCar( referenceId )
        await this.selectVehicle( car )
    }
    
    async selectVehicle( data ) {
        const car = await criteriaStore.getCarByVin( data.vehicleIdentificationNumber )
        const vehicle = { ...data, ...car }
        // --> console.log( "SET VEHICLE", {vehicle} )
        this.currentVehicle = vehicle
        session.set( "currentVehicle", vehicle )
    }
    
    fetch( { licensePlate } ) {
        return axios( `${config.URL_BASE}api/v1/customers/external?licensePlate=${licensePlate}`, {
            method : "GET",
            headers: {
                "Content-Type" : "application/json",
                "Authorization": sessionStore.authorization
            },
        } )
            .then( res => res.data )
            .then( user => {
                let customer = new Customer( user, licensePlate )
                customer.licensePlate = licensePlate
                this.customer = user
                this.customerByPlate.set( licensePlate, customer )
                return user
            } )
            .catch( err => {
                if ( err.response && err.response.status === 404 ) {
                    return { error: "Fahrzeug nicht gefunden" }
                }
                return { error: "API: Keine Antwort erhalten." }
            } )
    }
}

