import {action, observable, makeObservable} from "mobx"
import seasonNames from "utils/seasonNames"
import React from "react"
import {slugify} from "utils/slugify"
import currency from "../utils/currency"
import moment from "moment";

const seasonKeys = {
    "S": "summer",
    "s": "summer",
    "W": "winter",
    "w": "winter",
    "a": "yearround",
    "A": "yearround"
}

const maxSpeedByRating = {
    P: "bis 150 km/h",
    Q: "bis 160 km/h",
    R: "bis 170 km/h",
    S: "bis 180 km/h",
    T: "bis 190 km/h",
    H: "bis 210 km/h",
    V: "bis 240 km/h",
    VR: "über 210 km/h",
    W: "bis 270 km/h",
    Y: "bis 300 km/h",
    ZR: "über 240 km/h"
}

export const searchBehaviour = {
    TO_CART: "TO_CART",
    LINK_PRODUCT: "LINK_PRODUCT"
}

export default class ProductModel {
    quantity = 0
    associatedProducts = []
    productID = null
    images = []
    workPackages = []
    priceByStore = {}
    availableDays = [1, 2, 3, 4, 5, 6] // restricts appointments to certain dates
    searchBehaviour = searchBehaviour.TO_CART // add the product to cart
    subCategory = {}
    tag = {}
    tags = []

    constructor(position) {
        makeObservable(this, {
            quantity: observable,
            associatedProducts: observable,
            add: action
        })

        Object.assign(this, position)
        this.qualityType = this.type
        this.price = this.priceGrossInEur || this.priceGross || this.price

        this.productID = this.productID || this.id || this.eanProductID
        this.type = this.productID.substring(0, 3)
        this.slug = slugify(this.name)
        this.serviceKey = this.serviceKey || this.m
        this.maxCount = position.maxCount || this.maxCount

        // handle tire.offers
        //  {
        //       "contractor": "Tyre24",
        //       "shippingType": "Cheapest",
        //       "distributorId": "206429",
        //       "supplierArticleId": "709833",
        //       "stock": 14,
        //       "retailPriceNet": 92.56,
        //       "retailPriceGross": 110.15,
        //       "recommendedRetailPriceNet": 120.33,
        //       "recommendedRetailPriceGross": 143.19,
        //       "estimatedDeliveryDate": "2024-07-16T00:00:00+02:00",
        //       "minEstimatedDeliveryDate": "2024-07-16T00:00:00+02:00",
        //       "maxEstimatedDeliveryDate": "2024-07-16T00:00:00+02:00"
        //     }
        this.deliveryDateText = "Verfügbar"
        if (this.offers) {
            const firstOffer = this.offers[0]
            if (firstOffer) {
                let daysMin = moment(firstOffer.minEstimatedDeliveryDate).diff(moment(), 'days') + 1
                let daysMax = moment(firstOffer.maxEstimatedDeliveryDate).diff(moment(), 'days') + 1
                if (daysMin === 0) daysMin = 1
                if (daysMin === daysMax) daysMax = daysMin + 2
                this.deliveryDateText = `${daysMin} - ${daysMax} Tage`
            }
        } else {
            this.deliveryDateText = "Verfügbar"
        }


        // this product is available at a fixed delivery date
        this.fixedDeliveryDates = false

        // this product can be assembled/delivered in x days
        this.availabilityInDays = 2

        this.priceGrossInEur = this.price
        this.priceText = currency(this.price, 2)
        this.recommendedRetailPriceGrossInEur = this.recommendedRetailPriceGrossInEur || this.recommendedRetailPriceGross || this.price
        this.discount = Math.round((this.recommendedRetailPriceGrossInEur - this.priceGrossInEur) / this.recommendedRetailPriceGrossInEur * 100) / 100
        this.hasDiscount = this.recommendedRetailPriceGrossInEur < this.priceGrossInEur

        // this.discount = Math.abs( Math.round( (1 - (this.this.priceGrossInEur / this.recommendedRetailPriceGrossInEur)) * 100 ))
        if (!this.image && this.images) {
            this.image = this.images[0]
        }

        if (this.speedRating) {
            this.maxSpeed = maxSpeedByRating[this.speedRating]
        }

        // this.type = this.type || this.productID?.substr( 0, 3 )

        if (this.priceGross) {
            this.price = this.priceGrossInEur
        }

        if (this.height && this.width && this.season && this.speedRating && this.diameter) {
            this.matchcode = `${this.width}${this.height}${this.diameter}${this.speedRating}`
        }

        if (this.season) {
            this.seasonName = seasonKeys[this.season]
            this.seasonTitle = seasonNames[this.season]
        }

        if (this.hasTPMS) {
            // // --> console.log( "HAS TPMS, PRICE ADDED" )
            // this.priceGrossInEur += 149
            // this.price += 149
            // this.recommendedRetailPriceGrossInEur += 149
        }

        if (!this.description && this.workPackages && this.workPackages.length > 0) {
            this.description = this.workPackages[0].description
            this.descriptionTitle = this.workPackages[0].title
        }

        this.parseCategories()

        this._productModelInitialized = true
    }

    parseCategories() {
        this.categories = this.categories || []
        this.tag = {}
        this.tags = []

        this.categories.forEach(({mainCategory, subCategory}) => {
            if (mainCategory) {
                this.mainCategory = mainCategory.id
                this.mainCategoryName = mainCategory.name
                this.tag[mainCategory.id] = mainCategory.name
                this.tags.push(mainCategory.id)
            }

            if (subCategory) {
                this.subCategory[subCategory.id] = subCategory.name
                this.tag[subCategory.id] = subCategory.name
                this.tags.push(subCategory.id)
                this.subCategoryName = subCategory.name
                this.subCategoryId = subCategory.id
            }

        })
    }

    // @computed
    // get lowestPriceFlag() {
    //     // JB: price flag of product & associated products
    //     // calculate "lowest" priceFlag of all product children
    //     const priceFlagsOrder = ["workprice", "workprice+", "variantprice", "fixedprice", "specialprice"]
    //     const associatedProducts = this.associatedProducts || []
    //     console.log(`XXX ${this.name} 01`, this.associatedProducts.length, this.priceFlag)
    //     const ret = associatedProducts.reduce((lowestFlagYet, {priceFlag}, n) => {
    //         console.log(`XXX ${this.name} 02`, n, lowestFlagYet, this.priceFlag)
    //         const i = priceFlagsOrder.indexOf(lowestFlagYet)
    //         const j = priceFlagsOrder.indexOf(priceFlag)
    //         return i < j ? lowestFlagYet : priceFlag
    //     }, this.priceFlag)
    //     console.log(`XXX ${this.name} 03`, ret)
    //     return ret
    // }
    // @computed
    // get priceFlagDescription() {
    //     let manufacturerName

    //     try {
    //         manufacturerName = this.partGroups[ 0 ].parts[ 0 ].manufacturer
    //     } catch (err) {
    //         manufacturerName = "der Marke Ihres Vertrauens"
    //     }
    //     switch (this.lowestPriceFlag) {
    //         case "fixedprice":
    //         case "specialprice":
    //             return `Wir haben für Sie den Festpreis mit Ersatzteilen von “${manufacturerName}” auf Basis Ihrer Fahrzeugeingabe zusammengestellt. `
    //             // return `Wir haben für Sie den Festpreis für die Reparatur “${this.itemMountPosition}” mit Ersatzteilen von “${manufacturerName}” auf Basis Ihrer Fahrzeugeingabe zusammengestellt. `
    //         case "variantprice":
    //             return `Bei der Ermittlung des Festpreises für die Reparatur “${this.itemMountPosition}” haben wir unterschiedliche Ausstattungsvarianten für Ihr Fahrzeug gefunden. Der aktuelle Preis zeigt eine Variante mit Ersatzteilen von ${manufacturerName}.`
    //             // return `Bei der Ermittlung des Festpreises für die Reparatur “${this.itemMountPosition}” haben wir unterschiedliche Ausstattungsvarianten für Ihr Fahrzeug gefunden. Der aktuelle Preis zeigt eine Variante mit Ersatzteilen von ${manufacturerName}.`
    //         case "workprice":
    //         case "workprice+":
    //             return `Bei der Ermittlung des Festpreises für die Reparatur “${this.itemMountPosition}” haben wir nicht alle Ersatzteile ermitteln können. Der Preis ist daher noch unvollständig.`
    //             // return `Bei der Ermittlung des Festpreises für die Reparatur “${this.itemMountPosition}” haben wir nicht alle Ersatzteile ermitteln können. Der Preis ist daher noch unvollständig.`
    //         default:
    //             return null
    //     }
    // }

    setMeta(data) {
        this.metafield = this.metafield || {}
        Object.assign(this.metafield, data, {})
        console.log("ADD META", data, this.metafield)
    }

    add(product) {
        this.associatedProducts.push(product)
    }

    export() {

        let json = {
            productID: this.productID,
            quantity: this.quantity
        }

        if (this.associatedProducts.size >= 1) {
            let products = [...this.associatedProducts]
            json.associatedProducts = products.map(([productID, product]) => product.toJSON())
        }

        return json
    }
}
