import React from "react";
import moment from "moment";
import DatePicker, {registerLocale} from "react-datepicker";
import "./TimePicker.css"
import de from "date-fns/locale/de"

registerLocale( "de", de )

export function TimePicker( { checkInTime, onChange, defaultValue = false, sameDay = true } ) {
    const [date, setDate] = React.useState( defaultValue )
    const earliestRetrieval = moment( checkInTime ).subtract( 1, "minute" ).add( 4, "hours" )
    const [earliestRetrievalWrapped, setEarliestRetrievalDate] = React.useState( earliestRetrieval )
    const isoEarliestRetrieval = earliestRetrieval.toISOString()
    
    React.useEffect( () => {
        const closingTime = moment( checkInTime ).hour( 18 ).startOf( "hour" )
        const nextDay = earliestRetrieval.isAfter( closingTime )
        
        if ( nextDay ) {
            const checkOutTimeProposal = moment( checkInTime ).add( 1, "day" ).hour( 13 )
            setDate( checkOutTimeProposal.toDate() )
            setEarliestRetrievalDate( checkOutTimeProposal )
        }
    }, [isoEarliestRetrieval] )
    
    
    return <DatePicker
        inline={true}
        filterTime={time => {
            const selectedDate = moment( time )
            const openingTime = moment( time ).hour( 8 ).startOf( "hour" ).subtract( 1, "minute" )
            const closingTime = moment( time ).hour( 17 ).startOf( "hour" ).add( 1, "minute" )
            return earliestRetrievalWrapped.isBefore( selectedDate ) && closingTime.isAfter( selectedDate ) && openingTime.isBefore( selectedDate )
        }  }
        filterDate={d => {
            const date = moment(d).endOf("day")
            return earliestRetrievalWrapped.isBefore(date)
        }}
        className={"ui input pa3"}
        selected={date}
        onChange={( date ) => {
            setDate( date )
            onChange( date )
        } }
        locale={"de"}
        showTimeSelectOnly={false}
        timeCaption={"Zeit"}
        timeFormat="HH:mm"
        showTimeSelect
        dateFormat="HH:mm"
    />
}
