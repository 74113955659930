const fuelName = {
    "Petrol": "Benzin",
    "Diesel": "Diesel",
    "0000" :  "", // unbekannt
    "0001" :  "Benzin",
    "0002" :  "Diesel",
    "0003" :  "Vielstoff",
    "0004" :  "Elektro",
    "0005" :  "LPG",
    "0006" :  "Benzin/LPG",
    "0007" :  "Benzin/CNG",
    "0008" :  "Hybrid",
    "0009" :  "Erdgas",
    "0010" :  "Hybrid",
    "0011" :  "Wasserstoff",
    "0012" :  "Wasserstoff",
    "0013" :  "Hybrid",
    "0014" :  "Hybrid",
    "0015" :  "Brennstoffzelle",
    "0016" :  "Brennstoffzelle",
    "0017" :  "Brennstoffzelle",
    "0018" :  "Brennstoffzelle",
    "0019" :  "Hybrid",
    "0020" :  "Biogas",
    "0021" :  "Hybrid",
    "0022" :  "Erdgas/Elektro",
    "0023" :  "Benzin/Ethanol",
    "0024" :  "LPG/Hybrid",
    "0025" :  "Hybrid",
    "0026" :  "Hybrid",
    "0027" :  "Hybrid",
    "0028" :  "Hybrid",
    "0029" :  "Hybrid",
    "0030" :  "Hybrid",
    "0031" :  "Hybrid",
    "0032" :  "Wasserstoff/Erdgas",
    "0033" :  "Hybrid/Erdgas",
    "0034" :  "Ethanol",
    "0035" :  "Hybrid",
    "0036" :  "Hybrid",
    "0037" :  "Diesel/LNG",
    "0038" :  "LNG",
    "9999" :  "Andere",
}

export function getFuelName(title){
    return fuelName[title] || title
}
export default fuelName
