import { action, computed, observable, makeObservable } from "mobx";

export default class RegistrationStore {

  car = {
    make:          "",
    manufacturer:  "",
    model:         "",
    chassisNumber: "",
    kilometers:    "",
    licensePlate:  "",
    licensenumber: ""
  };

  user = {
    firstName:       "",
    lastName:        "",
    street:          "",
    plz:             "",
    city:            "",
    email:           "",
    password:        "",
    title:           "",
    company:         "",
    phoneNumber:     "",
    confirmPassword: "",
    newsletter:      false,
  };

  constructor( { sessionStore } ) {
    makeObservable(this, {
      car: observable,
      user: observable,
      setUser: action,
      setCar: action,
      setRegistrationCheckbox: action,
      hasEqualPasswords: computed
    });

    this.sessionStore = sessionStore
  }

  setUser(item, value) {
    this.user[ item ] = value
  }

  setCar(item, value) {
    this.car[ item ] = value
  }

  setRegistrationCheckbox = () => {
    this.user.newsletterConfirmed = !this.user.newsletterConfirmed
  };

  get hasEqualPasswords() {
    return this.user.password.length > 6 && this.user.password === this.user.confirmPassword
  }
}
