import queryString from "query-string"
import {useLocation} from "react-router"

export default function parseQuery( {location = {}, match = {}} = {} ) {
    const {params = {}} = match
    let queryContext = queryString.parse( location.search )
    Object.assign( queryContext, params )
    return queryContext
}

function useQuery() {
    return parseQuery( {location: useLocation()} )
}

export {useQuery}
