import moment from "moment"
import {action, computed, makeObservable, observable} from "mobx"
import labourServiceStore from "page/LabourTimeServicePage/labourServiceStore"
import {differenceInMonths, differenceInDays, getMonth} from "date-fns"
import vehicleServiceStore from "../page/VehicleServicePage/vehicleServiceStore"
import {slugifyLicensePlate} from "../utils/slugifyLicensePlate"
import {debounce} from "lodash"
import {faExclamation} from "@fortawesome/pro-solid-svg-icons"
import {faCheck} from "@fortawesome/pro-light-svg-icons"

import shop from "store/shop"
import {TyreProfileStore} from "./sessionStore";
import {sortBy} from "lodash-es";

export const mileageFormat = new Intl.NumberFormat("de-DE", {})

export const notificationLevels = {
    RED: "red", // IMMEDIATE ACTION
    YELLOW: "yellow", // NOTIFICATION IN DATA
    GREEN: "green", // NO ACTION REQUIRED
    BLUE: "blue", // OPPORTUNITY / OFFER
    DONE: "done", // CHECKED ITEM
}

class Notification {
    id = false
    type = false
    message = false
    date = false
    isRead = false
    isDismissed = false
    level = notificationLevels.GREEN
    color = "yellow"

    constructor(data = {}) {
        Object.assign(this, data)
    }
}

function makeInspectionHash(vehicleProfile) {
    return vehicleProfile.vehicleReferenceId + "///" + vehicleProfile.mileage + "///" + vehicleProfile.firstRegistration + "///" + vehicleProfile.gearBoxId + "///" + vehicleProfile.configurationId
}

export class VehicleProfile {
    isDefault = false
    isActive = false
    showReplacementImage = true
    vehicleClass = false
    classId = false
    showPictogram = false
    vehicle = false
    meta = false
    gearBoxId = false
    summary = false
    configurations = false
    configuration = ""
    wheels = []
    tyres = []
    mileage = 0
    firstRegistration = null

    inspectionData = {
        maintenanceServices: [],
        additionalServices: [],
        serviceRecommendations: [],
        priceMinByQuality: {},
    }

    notifications = []
    notificationByKey = new Map()

    maintenanceHistory = []
    maintenanceByKey = {}
    inspectionRequestHash = false
    displayName = ""

    constructor(data = {}, {autoload = false} = {}) {
        makeObservable(this, {
            vehicle: observable,
            meta: observable,
            showReplacementImage: observable,
            gearBoxId: observable,
            summary: observable,
            configurations: observable,
            configuration: observable,
            mileage: observable,
            firstRegistration: observable,
            inspectionData: observable,
            wheels: observable,
            vehicleClass: observable,
            classId: observable,
            notifications: observable,
            notificationByKey: observable,
            showPictogram: observable,
            showIAMServices: computed,
            put: action,
            setVehicleReferenceId: action,
            addNotification: action,
            maintenanceHistory: observable,
            maintenanceByKey: observable,
            putVehicleMaintenanceHistoryItem: action,
            updateMaintenanceHistory: action,
        })

        this.notifications = []
        if (data.notificationByKey) {
            this.notificationByKey = new Map(Object.entries(data.notificationByKey))
            this.notifications = Array.from(this.notificationByKey.values())
        }

        this.autoload = autoload
        this._vehicleProfile = true
        delete data.isLocal
        Object.assign(this, data)

        this.notificationByKey = this.notificationByKey || new Map()

        this.setFirstRegistrationDate(this.firstRegistration)
        this.vehicleId = this.vehicleId || `local-${Math.random().toString(32)}`
        this.hash = `${this.manufacturerKeyNumber}-${this.typeKeyNumber}`.toUpperCase()
        this.inspectionRequestHash = makeInspectionHash(this)

        this.displayName = data.licensePlate || `${data.manufacturer}`

        Object.freeze(this.configurationId)

        this.url = `/konto/vehicle/${this.vehicleId}/`
        this.licensePlateSlug = slugifyLicensePlate(this.licensePlate)
        this.vehicleId = this.vehicleId || slugifyLicensePlate(this.licensePlate)
        this.licensePlateSlug = slugifyLicensePlate(this.licensePlate)
        this.bestStore = "ok"

        this.invalidVehicle = !this.manufacturer && !this.model
        this.nextInspectionDate = false

        this.signals = []
        this.analyseData()
        // get next TÜV HU DATE
        this.updateMaintenanceHistory()
        this.updateTyreSummary()

        const month = getMonth(new Date()) + 1
        const winterIsComing = 10 // 10 === October
        const summerIsComing = 4 //  4 === April

        if (month === winterIsComing) {
            let comingSeasonCapitalized = "WINTER"
            this.notifications.push(new Notification({
                id: "TYR_WINTER",
                message: "Winterreifenwechsel",
                title: `Winterreifen montieren`,
                description: `Der ${comingSeasonCapitalized} steht vor der Tür. Es ist zeit Ihre Räder zu wechseln.`,
                cta: `${comingSeasonCapitalized}reifen montieren`,
                href: "/reifen/",
                search: `season=winter`

            }))
        }

        if (month === summerIsComing) {
            let comingSeasonCapitalized = "SOMMER"
            this.notifications.push(new Notification({
                id: "TYR_SUMMER",
                message: "Sommerreifenwechsel",
                title: `Sommerreifen montieren`,
                description: `Der ${comingSeasonCapitalized} steht vor der Tür. Es ist zeit Ihre Räder zu wechseln.`,
                cta: `${comingSeasonCapitalized}reifen montieren`,
                href: "/reifen/",
                search: `season=summer`

            }))
        }


        if (this.maintenanceByKey["INS"]) {
            this.nextInspectionDate = this.maintenanceByKey["INS"].nextDate
        }

        this.updateVehicleDescription()

        this.update = {}
        this.debouncedPut = debounce((update) => {
            Object.assign(this, update)
            this.put(update)
            this.analyseData()

        }, 900, {
            leading: false, trailing: true
        })
        this.hasAlert = false
        // this.hasAlert = this.signals.length > 0

    }

    addNotification(notification) {
        this.notificationByKey.set(notification.id, notification)
        this.notifications = Array.from(this.notificationByKey.values())
    }


    updateOfferSummary() {
        const vehicleOffers = (sessionStore.offersByLicensePlate[this.licensePlate] || [])

        vehicleOffers.map(offer => {
            this.addNotification(new Notification({
                id: offer.internalId || offer.offerNumber, message: `Angebot`, type: "offer", level: notificationLevels.BLUE
            }))
        })
    }

    updateTyreSummary() {
        const {tyres} = this
        const winterTyres = tyres.filter(tyre => tyre.season === "W")
        const summerTyres = tyres.filter(tyre => tyre.season === "S")
        const winterTyre = sortBy(winterTyres, "scoreProfile")[0]
        const summerTyre = sortBy(summerTyres, "scoreProfile")[0]
        const tyresWithWarnings = [...tyres.map(tyre => {
            if (tyre.hasAgeWarning) return tyre
            if (tyre.hasProfileWarning) return tyre
            return false
        })].filter(Boolean)

        const firstWarningTyreId = tyresWithWarnings[0]?.tyreId

        const warnings = [
            ...tyres.map(tyre => {
                if (tyre.hasAgeWarning) return tyre.warningText
                if (tyre.hasProfileWarning) return tyre.warningProfileText
                return ""
            }),
        ].filter(e => e !== "").slice(0, 1).join(", ")
        let snoozeItem = sessionStore.profile && sessionStore.profile.getSnoozeItem(this.vehicleId, "TYRES_FRONTEND")
        const isSnoozed = snoozeItem && snoozeItem.isSnoozed
        if (isSnoozed) {
            this.addNotification(new Notification({
                id: "TYRES_FRONTEND", message: `${warnings}`, type: "task", level: notificationLevels.DONE
            }))
        } else {
            if (warnings.length > 0) {
                this.addNotification(new Notification({
                    id: "TYRES_FRONTEND", message: `${warnings}`, type: "task", level: notificationLevels.RED
                }))

                // CREATE TYRE HISTORYMAINTENANCE
                const tyreItem = {
                    "vehicleMaintenanceHistoryId": "TYRES_MAINTENANCE_HISTORY_FRONTEND",
                    "vehicleId": this.vehicleId,
                    "task": "TYRES_FRONTEND",
                    "description": "Reifen",
                    "lastMileage": 0,
                    "lastDate": null,
                    "nextMileage": 0,
                    "nextDate": "",
                    "replacementProductId": null,
                    "isStandardMaintenance": true,
                    "hasAlert": true,
                    "icon": faExclamation,
                    "bgColor": "bg-red-500",
                    "textColor": "text-red-500 font-bold",
                    "href": `/konto/vehicle/${this.vehicleId}/tyres/?tyreId=${firstWarningTyreId}`,
                    "level": notificationLevels.RED,
                    "alertMessage": `Prüfen Sie Ihre Reifen`,
                    "conditions": {
                        "vehicleClass": [
                            "BIKE",
                            "VAN",
                            "CAR",
                            "TRUCK"
                        ],
                        "driveType": "*",
                        "customerType": "*"
                    },
                    "meetsConditions": true,
                    "needsData": false,
                    "nextDateFormatted": "",
                }

                this.maintenanceByKey[tyreItem.task] = tyreItem
                this.maintenanceHistory = Object.values(this.maintenanceByKey)
            }
        }
    }

    updateMaintenanceHistory() {
        this.notificationByKey = new Map()

        this.maintenanceHistory.forEach(historyItem => {
            let isHit = true

            const isCommercial = historyItem.conditions?.customerType === "commercial"
            const isProfileCommercial = !!sessionStore.profile?.companyName
            if (historyItem.conditions.driveType !== "*") isHit = isHit && historyItem.conditions.driveType === this.driveType
            if (historyItem.conditions.customerType !== "*") isHit = isHit && isCommercial === isProfileCommercial

            const myVehicleClass = this.vehicleClass?.id || "CAR"
            if (!historyItem.conditions.vehicleClass.includes(myVehicleClass) && !historyItem.conditions.vehicleClass.includes("*")) isHit = false
            // check historyItem.conditions.vehicleClass
            // console.log("HISTORY ITEM", historyItem.task, historyItem.conditions.driveType, historyItem.conditions.customerType, isHit)
            historyItem.meetsConditions = isHit
            let snoozeItem = sessionStore.profile && sessionStore.profile.getSnoozeItem(this.vehicleId, historyItem.task)

            historyItem.needsData = false
            let alternativeDate = false
            switch (historyItem.task) {
                case "HU2":
                    alternativeDate = this.nextMaintenanceAt
                    break;
                case "TÜV":
                    alternativeDate = this.nextGeneralInspectionAt
                    break;
                case "FS":
                    alternativeDate = this.nextMaintenanceAt
                    break;
                case "BF":
                    alternativeDate = this.nextMaintenanceAt
                    break;
                case "INS":
                    alternativeDate = this.nextMaintenanceAt
                    break;
                case "VBK":
                    alternativeDate = this.firstAidKitReplacementAt
                    break
                case "TF":
                    alternativeDate = this.tyreSealantReplacementAt
                    break
                default:
                    break
            }

            if (alternativeDate && !historyItem.nextDate) {
                historyItem.nextDate = alternativeDate
            }

            if (alternativeDate && moment(historyItem.nextDate).isBefore(moment(alternativeDate))) {
                historyItem.nextDate = alternativeDate
            }

            const $nextDate = moment(historyItem.nextDate)
            const isOverdue = $nextDate.isBefore(moment().add(14, "days"))
            const isDueSoon = $nextDate.isBefore(moment().add(3, "months"))

            historyItem.$nextDate = $nextDate
            historyItem.nextDateFormatted = $nextDate.format("DD.MM.YYYY")

            const nextDateFormatted = $nextDate.format("DD. MMM YYYY")
            let bgColor = isDueSoon ? "bg-yellow-500" : "bg-green-500"
            let textColor = isDueSoon ? "text-yellow-500 font-bold" : "text-gray-500"
            let level = notificationLevels.GREEN

            historyItem.snoozeItem = snoozeItem

            if (snoozeItem && snoozeItem.addedManually) {
                historyItem.addedManually = true
                historyItem.meetsConditions = true
            }

            if (snoozeItem && snoozeItem.expiresAt && moment(snoozeItem.expiresAt).isAfter(moment())) {
                bgColor = "bg-blue-500"
                textColor = "text-blue-500 font-bold"
                level = notificationLevels.DONE
                this.addNotification(new Notification({
                    id: historyItem.task, message: `${historyItem.task} geplant`, type: "task", level
                }))
            } else {
                if (isDueSoon && !isOverdue) {
                    level = notificationLevels.YELLOW
                    this.addNotification(new Notification({
                        id: historyItem.task, message: `${historyItem.task} bald fällig`, type: "task", level
                    }))
                }

                if (isOverdue) {
                    bgColor = "bg-red-500"
                    textColor = "text-red-500 font-bold"
                    level = notificationLevels.RED
                    this.addNotification(new Notification({
                        id: historyItem.task, message: `${historyItem.task} überfällig`, type: "task", level
                    }))
                }
            }

            const icon = isDueSoon ? faExclamation : faCheck

            historyItem.nextDateFormatted = nextDateFormatted
            historyItem.bgColor = bgColor
            historyItem.textColor = textColor
            historyItem.icon = icon
            historyItem.level = level


            if (!historyItem.nextDate) {
                // historyItem.hasAlert = true
                historyItem.alertTitle = "Bitte Daten aktualisieren"
                historyItem.alertLink = `/konto/vehicle/${this.vehicleId}/details/`
                historyItem.needsData = true
                historyItem.bgColor = "bg-yellow-500"
            } else {

                if (isDueSoon) {
                    // historyItem.hasAlert = true
                    historyItem.alertTitle = "Bald fällig"
                    historyItem.alertLink = `/konto/vehicle/${this.vehicleId}/details/`
                }

                if (isOverdue) {
                    historyItem.hasAlert = true
                    historyItem.alertTitle = "Überfällig"
                    historyItem.alertLink = `/konto/vehicle/${this.vehicleId}/details/`
                }
            }

            if (historyItem.hasAlert) {
                this.signals.push(historyItem)
            }

            this.maintenanceByKey[historyItem.task] = historyItem
        })


        this.updateOfferSummary()
        this.updateTyreSummary()
    }

    analyseData() {
        const requiredVehicleFields = ["classId", "manufacturerKey", "className"]
        const requiredFields = ["firstRegistration", "mileage", "gearBoxId", "configurationId"]
        const hasAllRequiredData = requiredFields.every(field => this[field])

        if (!this.classId) {
            if (Object.keys(locationStore.vehicleClasses).indexOf(this.configuration) !== -1) {
                this.classId = this.configuration
            } else if (this.configuration && this.configurationId) {
                this.classId = "CAR"
            }
            this.classId = this.classId || "CAR"
        }

        this.vehicleClass = locationStore.vehicleClasses[this.classId] || locationStore.vehicleClasses["CAR"]
        this.showPictogram = !!((this.classId !== "CAR" && this.classId !== "VAN") && this.vehicleClass)
        this.showReplacementImage = !this.showPictogram
        this.showReplacementImage = !this.vehicle || (this.vehicle.productionYearUntil && (this.vehicle.productionYearUntil < 2008))

        if (this.autoload && this.vehicle.referenceId !== this.vehicleReferenceId) {
            this.fetchVehicle()
        }
        // requiredFields.forEach(field => {
        // 	if (this[field]) console.log(this.vehicleId, "HIT", field, this[field])
        // 	if (!this[field]) console.log(this.vehicleId, "MISS", field, this[field])
        // })
        this.countDataMissing = requiredFields.filter(field => !this[field]).length
        this.countDataMax = requiredFields.length
        this.hasAllRequiredData = hasAllRequiredData

        let inspectionRequestHash = makeInspectionHash(this)
        if (this.insppectionRequestHash !== inspectionRequestHash) {
            // console.log("INSPECTION REQUEST HASH CHANGED", this.inspectionRequestHash, inspectionRequestHash)
        }
        this.inspectionRequestHash = inspectionRequestHash
        // console.log("INSPECTION REQUEST HASH", this.inspectionRequestHash)
        // console.log(this)
    }

    get mileageFormatted() {
        return mileageFormat.format(Number(this.mileage))
    }

    async setVehicle(vehicle) {
        this.vehicleReferenceId = vehicle && vehicle.referenceId
        this.isElectric = vehicle && vehicle.fuelType === "0004"
        this.showReplacementImage = !vehicle || (vehicle.productionYearUntil && (vehicle.productionYearUntil < 2008))
        this.imageName = vehicle && vehicle.imageName
        if (vehicle) this.updateVehicleDescription()
        return this.fetchMeta()
    }

    updateVehicleDescription() {
        const isSpecialClass = this.showPictogram && this.vehicleClass && this.vehicleClass !== "CAR"
        this.displayDescription = isSpecialClass ? this.vehicleClass.name : ""
        if (!this.vehicle) return
        const vehicle = this.vehicle

        this.displayName = this.licensePlate || `${this.vehicle.className} ${this.vehicle.version}`
        this.displayDescription = `${this.vehicle.className} ${this.vehicle.version} (${this.vehicle.horsepower} PS)`
        // REDUNDANCY TO SET VEHICLE
        this.isElectric = vehicle && vehicle.fuelType === "0004"
        this.showReplacementImage = !vehicle || (vehicle.productionYearUntil && (vehicle.productionYearUntil < 2008))
        this.imageName = vehicle && vehicle.imageName
    }

    async setGearBox(gearBox, gearBoxId) {
        // console.log("SET GEARBOX", gearBox, gearBoxId)
        this.gearBox = gearBox?.displayName
        this.gearBoxId = gearBoxId
        return this.fetchMeta()
    }

    async setConfiguration(configuration, configurationId) {
        console.log("SET CONFIGURATION", this.configurationId)
        this.configurationId = configurationId
        this.configuration = configuration ? configuration.includedOptions?.map(e => e.option).join(", ") : ""
        this.saveLocal()
        return this.fetchMeta()
    }

    async setConfigurationId(configurationId) {
        console.log("SET CONFIGURATION", this.configurationId)
        this.configurationId = Number(configurationId)
        let configuration = this.configurations?.find(e => `${e.id}` === `${configurationId}`)
        this.configuration = configuration ? configuration.includedOptions?.map(e => e.option).join(", ") : ""
        this.saveLocal()
        return this.fetchMeta()
    }

    get isLocal() {
        return !this.vehicleId || `${this.vehicleId}`.indexOf("local") > -1 || `${this.vehicleId}`.indexOf("0.") > -1
    }


    async putVehicleMaintenanceHistoryItem(historyItemId, key, item) {
        console.log("PUT VEHICLE MAINTENANCE HISTORY ITEM", item)
        const oldItem = this.maintenanceByKey[key]

        const updatedVehicleMaintenanceRes = await shop.fetch(`v1/customers/me/vehicles/${this.vehicleId}/histories/maintenances/${historyItemId}`, {
            method: 'PUT', headers: {
                'Authorization': sessionStore.authorization
            }, body: JSON.stringify(item)
        })

        console.log({updatedVehicleMaintenanceRes})

        const update = {...oldItem, ...item}
        console.log({update})
        this.maintenanceByKey[key] = update
        this.maintenanceByKey = {...this.maintenanceByKey}
        this.maintenanceHistory = Object.values(this.maintenanceByKey)
        this.saveLocal()
        this.updateMaintenanceHistory()
    }


    setMileage(mileage) {
        if (!mileage) return
        this.mileage = Number(mileage) || 0
    }

    setBody(body) {
        this.body = body
        this.bodyId = body?.id
    }

    setFirstRegistrationDate(date) {
        this.firstRegistration = date
        this._firstRegistrationFormatted = this.firstRegistration ? moment(this.firstRegistration).format("DD/MM/YYYY") : "Keine Daten"
        this.firstRegistrationDate = moment(this.firstRegistration)
    }

    get showIAMServices() {
        return true
        // return moment( this.firstRegistrationDate ).isBefore( moment().subtract( 3, "years" ) )
    }


    async put(update) {
        Object.assign(this, update)
        if (sessionStore.profile) {
            if (this.isLocal) return // don't save local profiles to API
            console.log("PUT VEHICLE", this.vehicleId, update)
            let response = await sessionStore.profile.putVehicle(this.vehicleId, update)
            return response
        }
        return false
    }

    async fetchVehicle() {
        let vehicle = await criteriaStore.findCar(this)
        this.setVehicleReferenceId(vehicle.referenceId)
        console.log("FETCH VEHICLE", this.displayName, vehicle)

        this.showReplacementImage = !vehicle || (vehicle.productionYearUntil && (vehicle.productionYearUntil < 2008))
        this.vehicle = vehicle

        if (this.vehicle) {
            this.updateVehicleDescription()

        }
        if (this.vehicleId) {
            this.tyres = await TyreProfileStore.get(this.vehicleId)
            this.updateTyreSummary()
        }


        return vehicle
    }


    async fetchWheelStorage() {
        if (!this.vehicleIdentificationNumber) return
        // api/wheel-storage?vin=WDD2462011J294337
        // TODO: use this.vehicleIdentificationNumber
        // const vin = "WDD2462011J294337" // this.vehicleIdentificationNumber
        const vin = this.vehicleIdentificationNumber
        // let wheels  = await shop.fetch(`/wheel-storage?vin=${vin}`)
        // 	.then(res => res.json())
        // this.wheels = wheels
    }

    async fetchMeta() {
        if (!this.vehicleReferenceId) {
            await this.fetchVehicle()
        }

        this.fetchWheelStorage()

        if (this.vehicleReferenceId) {
            let summary = await labourServiceStore.fetchVehicleMetadata(this.vehicleReferenceId)
            this.summary = summary

            if (!summary || summary.error) {
                console.warn(`No Vehicle Summary for ${this.vehicleReferenceId} - ${this.manufacturer}`)
                return
            }

            if (!this.gearBoxId) {
                this.gearBoxId = this.summary && this.summary.gearBoxes[0]?.id
            }

            let configurations = await labourServiceStore.fetchVehicleConfigurations(this.vehicleReferenceId, this.gearBoxId)
            // console.log("CONF", configurations?.length)
            if (!this.configurationId && configurations[0]) {
                if (configurations.length === 1) {
                    let baseConfiguration = configurations[0]
                    this.configurationId = baseConfiguration.id
                    console.log("SET BASE", this.configurationId, baseConfiguration)
                    this.configuration = baseConfiguration.includedOptions.map(o => o.option).join(", ") || "Basisaustattung"
                }
            }

            if (!configurations || configurations.error) {
                this.configurations = false
                console.warn(`No Vehicle Configurations for ${this.vehicleReferenceId} - ${this.manufacturer}`)
                return
            }
            this.configurations = configurations
        }

        this.saveLocal()
    }

    get hasInspectionRequestData() {
        return this.mileage && this.firstRegistration && this.vehicleReferenceId && this.gearBoxId
    }

    async fetchInspections() {
        if (!this.vehicleReferenceId) {
            console.log("NO VEHICLE REFERENCE ID")
            return
        }
        let registrationDate = moment(this.firstRegistration)
        if (!registrationDate.isValid()) {
            // source from vehicle
            if (this.vehicle) {
                if (this.vehicle.productionYearUntil) {
                    registrationDate = moment().year(this.vehicle.productionYearUntil)
                } else if (this.vehicle.productionYearFrom) {
                    registrationDate = moment().year(this.vehicle.productionYearFrom)
                }
            }
        }

        let inspectionData = await vehicleServiceStore.loadServices({
            vehicleId: this.vehicleReferenceId, mileage: this.mileage || 0,
            registrationDate: registrationDate.format("YYYY-MM-DD"),
            bodyId: this.bodyId,
            gearBox: this.gearBoxId, // nextServiceId   : nextServiceId,
            configuration: this.configurationId
        }).then(res => {
            if (!res) {
                this.gearBoxId = null
                this.configurationId = null
                this.configuration = ""
                return res
                // this.setConfiguration( false, false )
            }
            return res
        })
        this.inspectionData = inspectionData

        // console.log({inspectionData: this.inspectionData})
    }

    saveLocal() {
        const s = this.toJSON()
        locationStore.setItem(this.vehicleId, s)
        if (this.hasInspectionRequestData) {
            this.fetchInspections()
        }
    }

    async toggleArchive() {
        await sessionStore.profile.putVehicle(this.vehicleId, {isActive: !this.isActive})
        document.location.reload()
    }

    setVehicleReferenceId(vehicleReferenceId) {
        this.vehicleReferenceId = vehicleReferenceId
    }

    toJSON() {
        const obj = {...this}
        delete obj.summary
        delete obj.vehicle
        if (obj.vehicle) delete obj.vehicle.vehicleProfile
        return obj
    }
}

const parsePlate = slugifyLicensePlate
export {parsePlate}
