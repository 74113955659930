import React from "react"
import {useHistory} from "react-router"
import {observer} from "mobx-react"
import {Button, Dropdown, Modal, Card} from "semantic-ui-react"
// import cartStore from "store/cartStore"
// // import locationStore from "store/locationStore";
// import tireStore "store/tireStore"
import "./AddTiresToCart.css"
import Icon from "utils/Icon"
import {toast} from "react-toastify"
// import cmsStore from "store/cmsStore";
import {faMinus, faPlus, faShoppingCart} from "@fortawesome/pro-light-svg-icons"
import {ServiceCardPimcore} from "../BranchPage/ServiceCardPimcore"
import {Link} from "react-router-dom"
import {ArrowButton} from "../../component/Button/ArrowButton"


export function ServiceGroupByHash({
	hash,
	amount,
	mainProduct,
	services
}) {

	const addons = services || cmsStore.products.filter(service => {
		return service.categoryHash.indexOf(hash) >= 0
	})

	return <div>
		<Card.Group doubling itemsPerRow={3}>
			{addons
				.map(service => {
					return <ServiceCardPimcore
						baseAmount={amount}
						onClick={() => {
							toast.success("Montageoption ausgewählt", {position: "bottom-center"})
							cartStore.save()
						}}
						mainProduct={mainProduct}
						key={service.productID}
						service={service}/>
				})}
		</Card.Group>

	</div>
}

const AddTiresToCart = observer(function _AddTiresToCart({
	tire,
	justifyEnd,
	onChange,
	showDropdown = false,
	onClick = false
}) {
	const {isOnlineStore}         = locationStore
	const [amount, _setAmount]    = React.useState(4)
	const [loading, setLoading]   = React.useState(false)
	const [showModal, setModal]   = React.useState(false)
	const [selectedTire, setTire] = React.useState(false)
	const history                 = useHistory()

	const setAmount = (n) => {
		_setAmount(n)
		onChange && onChange(n)
	}

	if (!tire) return null

	return <div className={`AddTiresToCart-Container`}>
		<div className={`AddTiresToCart flex flex-col`}>

			{showModal && <Modal open>
				<div className="ModalHeader p-4 lg:p-6 lg:px-12 border-bottom md:text-2xl font-bold">Montage, Lieferung oder Abholung</div>
				<Modal.Content>

					<div>
						<div>
							<ServiceGroupByHash mainProduct={tire} hash={"H40-U101"} amount={amount}/>
							{/*<Card.Group doubling itemsPerRow={4}>*/}
							{/*    <ServiceCardPimcore mainProduct={tire} service={cmsStore.SRV_MONTAGE}/>*/}
							{/*    <ServiceCardPimcore mainProduct={tire} service={cmsStore.SRV_MONTAGEAB18}/>*/}
							{/*    <ServiceCardPimcore mainProduct={tire} service={cmsStore.SRV_ABHOLUNG}/>*/}
							{/*    <ServiceCardPimcore mainProduct={tire} service={cmsStore.SRV_SPEDITION}/>*/}
							{/*</Card.Group>*/}
						</div>
					</div>
				</Modal.Content>
				<Modal.Actions>
					<Button basic onClick={() => {
						setModal(false)
					}}>Weiter einkaufen</Button>
					<Button as={Link} to={"/cart/"} primary>Zum Checkout</Button>
				</Modal.Actions>
			</Modal>}


			<div className={`flex flex-col md:flex-row pb-1 md:px-4 w-100 items-center ${justifyEnd ? "justify-end-ns" : ""}`}>
				<div>
					{!showDropdown && <div className={`flex flex-row items-center `}>
						<div>
							<Button

								basic
								size={"tiny"}
								onClick={() => {
									setAmount(Math.max(0, amount - 1))
								}}
								icon={<Icon icon={faMinus}/>}/>
						</div>
						<div className="pa3 tc">{amount}</div>

						<div>
							<Button
								basic
								size={"tiny"}
								onClick={() => {
									setAmount(amount + 1)
								}}
								icon={<Icon icon={faPlus}/>}/>
						</div>
					</div>}
					{showDropdown && <Dropdown placeholder="Anzahl"

											   onChange={(event, data) => {
												   setAmount(data.value)
											   }}
											   value={amount}
											   selection
											   options={[{
												   value: 1,
												   text:  "1"
											   }, {
												   value: 2,
												   text:  "2"
											   }, {
												   value: 3,
												   text:  "3"
											   }, {
												   value: 4,
												   text:  "4"
											   }, {
												   value: 5,
												   text:  "5"
											   }, {
												   value: 6,
												   text:  "6"
											   }, {
												   value: 7,
												   text:  "7"
											   }, {
												   value: 8,
												   text:  "8"
											   }, {
												   value: 9,
												   text:  "9"
											   }, {
												   value: 10,
												   text:  "10"
											   }]}/>}
				</div>

				<div className="pl-2 w-full">
					<ArrowButton
						basic={false}
						fluid
						onClick={() => {
							setLoading(true)
							tireStore.loadTire({productID: tire.eanProductID || tire.productID})
								.then(tire => {
									// --> console.log( "found tire", tire )
									if (!tire) return console.error(`Tire not found ${tire.eanProductID}`)
									cartStore.checkVehicleCompatibility = null

									tire.quantity = amount || 4

									//tire.automaker = "Mercedes-Benz" // TODO: compat fix for API
									tire.vehicleReferenceId = null

									// --> console.log( "ADDING TIRE", tire, tire.quantity )
									if (!cartStore.isProductInCart(tire)) {
										cartStore.addPosition(tire, tire.quantity)
										cartStore.setExtras({
																vehicleReferenceId: null
															})
									}
									cartStore.addPosition(tire, tire.quantity)
									cartStore.calculate()

									// CHECK MONTAGE

									const {
											  SRV_MONTAGE,
											  SRV_MONTAGEAB18,
											  SRV_ABHOLUNG,
											  SRV_SPEDITION
										  }              = cmsStore
									const {diameter}     = tire
									const montageService = diameter > 17 ? SRV_MONTAGEAB18 : SRV_MONTAGE
									console.log({
													tire,
													montageService
												})

									setModal(true)

									toast.success(<div className="f6">
										<div>{tire.quantity} Reifen hinzugefügt</div>
										<div className={"f6 b"}>{tire.name}</div>

									</div>, {position: "bottom-center"})

									onClick && onClick()
									setLoading(false)
								})
						}}
						icon={faShoppingCart}
						primary>
						<span>hinzufügen</span>
					</ArrowButton>
				</div>
			</div>

		</div>
	</div>
})

export {AddTiresToCart}
