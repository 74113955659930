import { action, observable, makeObservable } from "mobx";
import config from "./store.config"
import queryString from "query-string"
import _ from "lodash-es"
import ProductModel, { searchBehaviour } from "model/Product"
import shop from "store/shop"
import Fuse from "fuse.js";
import * as clustersJSON from "../component/Search/clusters.example.json";

const DEFAULT_PAGESIZE = 64

const tireBrands = [
    { searchBehaviour: searchBehaviour.LINK_PRODUCT,  title: "BARUM", category: "Reifenmarken",categoryName: "Reifenmarken", text:"Winter, Sommer und Ganzjahresreifen", href: "/reifen/?manufacturer=BARUM" },
    { searchBehaviour: searchBehaviour.LINK_PRODUCT,  title: "YOKOHAMA", category: "Reifenmarken",categoryName: "Reifenmarken", text:"Winter, Sommer und Ganzjahresreifen", href: "/reifen/?manufacturer=YOKOHAMA" },
    { searchBehaviour: searchBehaviour.LINK_PRODUCT,  title: "HANKOOK", category: "Reifenmarken",categoryName: "Reifenmarken", text:"Winter, Sommer und Ganzjahresreifen", href: "/reifen/?manufacturer=HANKOOK" },
    { searchBehaviour: searchBehaviour.LINK_PRODUCT,  title: "PIRELLI", category: "Reifenmarken",categoryName: "Reifenmarken", text:"Winter, Sommer und Ganzjahresreifen", href: "/reifen/?manufacturer=PIRELLI" },
    { searchBehaviour: searchBehaviour.LINK_PRODUCT,  title: "DUNLOP", category: "Reifenmarken",categoryName: "Reifenmarken", text:"Winter, Sommer und Ganzjahresreifen", href: "/reifen/?manufacturer=DUNLOP" },
    { searchBehaviour: searchBehaviour.LINK_PRODUCT,  title: "GOODYEAR", category: "Reifenmarken",categoryName: "Reifenmarken", text:"Winter, Sommer und Ganzjahresreifen", href: "/reifen/?manufacturer=GOODYEAR" },
    { searchBehaviour: searchBehaviour.LINK_PRODUCT,  title: "CONTINENTAL", category: "Reifenmarken",categoryName: "Reifenmarken", text:"Winter, Sommer und Ganzjahresreifen", href: "/reifen/?manufacturer=CONTINENTAL" },
    { searchBehaviour: searchBehaviour.LINK_PRODUCT,  title: "BRIDGESTONE", category: "Reifenmarken",categoryName: "Reifenmarken", text:"Winter, Sommer und Ganzjahresreifen", href: "/reifen/?manufacturer=BRIDGESTONE" },
    { searchBehaviour: searchBehaviour.LINK_PRODUCT,  title: "FALKEN", category: "Reifenmarken",categoryName: "Reifenmarken", text:"Winter, Sommer und Ganzjahresreifen", href: "/reifen/?manufacturer=FALKEN" },
    { searchBehaviour: searchBehaviour.LINK_PRODUCT,  title: "FULDA", category: "Reifenmarken",categoryName: "Reifenmarken", text:"Winter, Sommer und Ganzjahresreifen", href: "/reifen/?manufacturer=FULDA" },
    { searchBehaviour: searchBehaviour.LINK_PRODUCT,  title: "TOYO", category: "Reifenmarken",categoryName: "Reifenmarken", text:"Winter, Sommer und Ganzjahresreifen", href: "/reifen/?manufacturer=TOYO" },
    { searchBehaviour: searchBehaviour.LINK_PRODUCT,  title: "PETLAS", category: "Reifenmarken",categoryName: "Reifenmarken", text:"Winter, Sommer und Ganzjahresreifen", href: "/reifen/?manufacturer=PETLAS" },
    { searchBehaviour: searchBehaviour.LINK_PRODUCT,  title: "KUMHO", category: "Reifenmarken",categoryName: "Reifenmarken", text:"Winter, Sommer und Ganzjahresreifen", href: "/reifen/?manufacturer=KUMHO" },
    { searchBehaviour: searchBehaviour.LINK_PRODUCT,  title: "NEXEN", category: "Reifenmarken",categoryName: "Reifenmarken", text:"Winter, Sommer und Ganzjahresreifen", href: "/reifen/?manufacturer=NEXEN" },
    { searchBehaviour: searchBehaviour.LINK_PRODUCT,  title: "MAXXIS", category: "Reifenmarken",categoryName: "Reifenmarken", text:"Winter, Sommer und Ganzjahresreifen", href: "/reifen/?manufacturer=MAXXIS" },
    { searchBehaviour: searchBehaviour.LINK_PRODUCT,  title: "GENERAL", category: "Reifenmarken",categoryName: "Reifenmarken", text:"Winter, Sommer und Ganzjahresreifen", href: "/reifen/?manufacturer=GENERAL" },
    { searchBehaviour: searchBehaviour.LINK_PRODUCT,  title: "GT RADIAL", category: "Reifenmarken",categoryName: "Reifenmarken", text:"Winter, Sommer und Ganzjahresreifen", href: "/reifen/?manufacturer=GT%20RADIAL" },
    { searchBehaviour: searchBehaviour.LINK_PRODUCT,  title: "SUPERIA", category: "Reifenmarken",categoryName: "Reifenmarken", text:"Winter, Sommer und Ganzjahresreifen", href: "/reifen/?manufacturer=SUPERIA" },
    { searchBehaviour: searchBehaviour.LINK_PRODUCT,  title: "NOKIAN", category: "Reifenmarken",categoryName: "Reifenmarken", text:"Winter, Sommer und Ganzjahresreifen", href: "/reifen/?manufacturer=NOKIAN" },
    { searchBehaviour: searchBehaviour.LINK_PRODUCT,  title: "UNIROYAL", category: "Reifenmarken",categoryName: "Reifenmarken", text:"Winter, Sommer und Ganzjahresreifen", href: "/reifen/?manufacturer=UNIROYAL" },
    { searchBehaviour: searchBehaviour.LINK_PRODUCT,  title: "KLEBER", category: "Reifenmarken",categoryName: "Reifenmarken", text:"Winter, Sommer und Ganzjahresreifen", href: "/reifen/?manufacturer=KLEBER" },
    { searchBehaviour: searchBehaviour.LINK_PRODUCT,  title: "TRISTAR", category: "Reifenmarken",categoryName: "Reifenmarken", text:"Winter, Sommer und Ganzjahresreifen", href: "/reifen/?manufacturer=TRISTAR" },
    { searchBehaviour: searchBehaviour.LINK_PRODUCT,  title: "FORTUNA", category: "Reifenmarken",categoryName: "Reifenmarken", text:"Winter, Sommer und Ganzjahresreifen", href: "/reifen/?manufacturer=FORTUNA" },
    { searchBehaviour: searchBehaviour.LINK_PRODUCT,  title: "FEDERAL", category: "Reifenmarken",categoryName: "Reifenmarken", text:"Winter, Sommer und Ganzjahresreifen", href: "/reifen/?manufacturer=FEDERAL" },
    { searchBehaviour: searchBehaviour.LINK_PRODUCT,  title: "APLUS", category: "Reifenmarken",categoryName: "Reifenmarken", text:"Winter, Sommer und Ganzjahresreifen", href: "/reifen/?manufacturer=APLUS" },
    { searchBehaviour: searchBehaviour.LINK_PRODUCT,  title: "LAUFENN", category: "Reifenmarken",categoryName: "Reifenmarken", text:"Winter, Sommer und Ganzjahresreifen", href: "/reifen/?manufacturer=LAUFENN" },
    { searchBehaviour: searchBehaviour.LINK_PRODUCT,  title: "BFGOODRICH", category: "Reifenmarken",categoryName: "Reifenmarken", text:"Winter, Sommer und Ganzjahresreifen", href: "/reifen/?manufacturer=BFGOODRICH" },
    { searchBehaviour: searchBehaviour.LINK_PRODUCT,  title: "MICHELIN", category: "Reifenmarken",categoryName: "Reifenmarken", text:"Winter, Sommer und Ganzjahresreifen", href: "/reifen/?manufacturer=MICHELIN" },
    { searchBehaviour: searchBehaviour.LINK_PRODUCT,  title: "VREDESTEIN", category: "Reifenmarken",categoryName: "Reifenmarken", text:"Winter, Sommer und Ganzjahresreifen", href: "/reifen/?manufacturer=VREDESTEIN" },
    { searchBehaviour: searchBehaviour.LINK_PRODUCT,  title: "APOLLO", category: "Reifenmarken",categoryName: "Reifenmarken", text:"Winter, Sommer und Ganzjahresreifen", href: "/reifen/?manufacturer=APOLLO" },
    { searchBehaviour: searchBehaviour.LINK_PRODUCT,  title: "MILESTONE", category: "Reifenmarken",categoryName: "Reifenmarken", text:"Winter, Sommer und Ganzjahresreifen", href: "/reifen/?manufacturer=MILESTONE" },
    { searchBehaviour: searchBehaviour.LINK_PRODUCT,  title: "NANKANG", category: "Reifenmarken",categoryName: "Reifenmarken", text:"Winter, Sommer und Ganzjahresreifen", href: "/reifen/?manufacturer=NANKANG" },
]

const clusters = clustersJSON.default.reduce( ( acc, cluster ) => acc.concat( cluster.groups.map( group => {
        return {
            title       : group.mainGroup.seo.title || cluster.name,
            category    : group.mainGroup.id,
            categoryName: group.mainGroup.name,
            href        : `/services/${group.mainGroup.id}/${group.mainGroup.name}`, // TODO: Specify a deep link that opens the corresponding cluster
            text        : group.mainGroup.seo.description || group.mainGroup.seo.title || cluster.name,
        }
    } ) )
    , [] )

const featured = [
    // { title: "TÜV/Hauptuntersuchung", text: "Hauptuntersuchung inkl. Abgas untersuchung nach §29 StVZO. Wir checken Ihr Fahrzeug vor der Prüfung durch und geben Ihnen ggf. eine Reparaturempfehlung. ", category: "H30", searchTags: ["TUV", "TÜV", "HU", "Hauptuntersuchung"], href: "/shop/services/H30/hauptuntersuchung-inkl-abgas-untersuchung-nach-29-stvzo-durchfuhren/WEBP00-0006/" },
    {
        title       : "Festpreis-Inspektion",
        text        : "Werkstatttermin für Ihr Auto. Auf Wunsch zum Festpreis.",
        category    : "Featured",
        categoryName: "Inspektion",
        searchTags  : ["Inspektion", "Untersuchung", "Festpreis"],
        href        : "/inspection/",
        searchBehaviour: searchBehaviour.LINK_PRODUCT,
        priceText   : ""
    },
]

export default class SearchStore {
    error = false;
    result = false;
    loading = false;

    wheelPackagesById = new Map();
    loadingDetail = false;

    pagination = false;

    services = [];
    servicesByGroup = {};
    products = [];
    manufacturers = [];

    searches = new Map();

    isReady = false;
    itemsSearcher
    categorySearcher


    constructor() {
        makeObservable(this, {
            error: observable,
            result: observable,
            loading: observable,
            wheelPackagesById: observable,
            loadingDetail: observable,
            pagination: observable,
            services: observable,
            servicesByGroup: observable,
            products: observable,
            manufacturers: observable,
            searches: observable,
            isReady: observable,
            fetchServices: action,
            searchWheelPackage: action,
            searchWheelPackageBothSeasons: action
        });
    }


    setupSearch( activeVehicleClassId, products, categories ) {
        const productSearchData = products
            .filter( p => p.mainCategory ) // Discard products without category
            .filter( p => p.vehicleType === activeVehicleClassId )
            .map( p => {
                return {
                    ...p,
                    title       : p.name,
                    category    : p.mainCategory || "none",
                    categoryName: p.mainCategoryName || "Allgemein",
                    text        : p.descriptionText || p.text || `${p.name} für ${p.brandName}`,
                    href        : `/services/${p.mainCategory}/${p.slug}/${p.workPositionNumber}/`,
                    searchTags  : p.searchTags,
                }
            } )
        categories.map( cat => {
            return {
                title   : cat.name,
                category: "Kategorie",
                text    : cat.seo && cat.seo.description,
                href    : `/services/${cat.id}/${cat.slug}/`,
            }
        } )
        
        const fuseOptions = {
            // isCaseSensitive: false,
            includeScore: true,
            // shouldSort: true,
            includeMatches: true,
            // findAllMatches: false,
            minMatchCharLength: 3,
            // location: 0,
            threshold: 0.5,
            // distance: 100,
            // useExtendedSearch: false,
            // ignoreLocation: false,
            // ignoreFieldNorm: false,
            keys: [
                {
                    name  : 'searchTags',
                    weight: 7
                },
                {
                    name  : 'title',
                    weight: 5
                },
                {
                    name  : 'mainCategoryName',
                    weight: 1
                },
                {
                    name  : 'subCategoryName',
                    weight: 1
                },
                {
                    name  : 'text',
                    weight: 1
                },
            ],
        }
        this.itemsSearcher = new Fuse( [...featured, ...clusters, ...tireBrands, ...productSearchData,], fuseOptions )
        this.categorySearcher = new Fuse( categories, fuseOptions )
        this.isReady = true
    }

    fetchServices = ( { productid, store = locationStore.activeStoreId, brand = locationStore.activeManufacturerKey } ) => {
        if ( !productid ) console.error( "NO PRODUCT ID GIVEN: Fetch Services", )
        // let url = `services?store=${store}&productid=${productid}&brand=Mercedes-Benz`
        this.loading = true
        // let url = `/api/products/wheelpackages?VehicleSeries=170&Automaker=Mercedes&Season=winter&store=ORTH_NAGEL_HALLE`
        return fetch( `${config.API_BASE}/services?${queryString.stringify( { productid, store, brand } )}` )
            .then( response => response.json() )
            .then( this.handleServiceResult )
            .catch( this.handleError )
    };

    /**
     * @param result
     * @returns {*}
     */
    handleServiceResult = result => {
        // --> console.log( `search::handleServiceResult`, result )
        this.services = result.services || []
        this.products = result.products || []
        let serviceById = new Map()
        let servicesByGroup = _.groupBy( this.services.filter( s => s.category ), "category" )
        
        this.services.forEach( service => {
            serviceById.set( service.productID, service )
        } )
        
        if ( result.products ) {
            // merge cart data
            this.products = this.products.map( product => {
                let inCart = cartStore.productById[ product.productID ]
                if ( inCart ) {
                    product.quantity = inCart.quantity
                }
                return product
            } )
        }
        
        
        this.serviceById = serviceById
        this.servicesByGroup = servicesByGroup
        this.loading = false
        
        return { ...result, servicesByGroup, serviceById }
    }

    /**
     *
     * @param automaker
     * @param season
     * @param vehicleSeries
     * @param alternativeSeries
     * @param Page
     * @param rimDiameter
     * @param hasTPMS
     * @param PageSize
     * @param store
     * @returns {Promise<any>}
     */
    searchWheelPackage = ( { automaker, season, vehicleSeries, alternativeSeries, Page = 1, rimDiameter, hasTPMS, PageSize = DEFAULT_PAGESIZE, store = locationStore.activeStoreId } ) => {
        this.lastSearch = { automaker, season, vehicleSeries }
        this.loading = true
        // hasTPMS is not in the query. TPMS addition is hacked in frontend.
        const query = {
            automaker,
            season,
            rimDiameter,
            Page,
            PageSize,
            vehicleSeries,
            store
        }
        
        if ( !query.rimDiameter ) delete query.rimDiameter
        // --> console.log( `search::wheelPackage`, { query } )
        // let url = `/api/products/wheelpackages?VehicleSeries=X253&Automaker=Mercedes-Benz&Season=winter&store=ORTH_NAGEL_HALLE
        return shop.cachedFetch( `${config.API_BASE}/products/wheelpackages?${queryString.stringify( query )}` )
            .then( res => {
                res.hasTPMS = hasTPMS
                // // --> console.log( { res, vehicleSeries, alternativeSeries } )
                if ( res.results.length === 0 && alternativeSeries ) {
                    const query = {
                        automaker,
                        season,
                        rimDiameter,
                        Page,
                        PageSize,
                        vehicleSeries: alternativeSeries,
                        store
                    }
                    // // --> console.log( "RETRY W/ TYPE", { query } )
                    return shop.cachedFetch( `${config.API_BASE}/products/wheelpackages?${queryString.stringify( query )}` )
                        .then( res => {
                            // --> console.log( "ADD TPMS TO RES", {hasTPMS} )
                            res.hasTPMS = hasTPMS
                            return res
                        } )
                }
                return res
            } )
            .then( this.handleSearchResult )
            .catch( this.handleError )
    };

    /**
     *
     * @param automaker
     * @param vehicleSeries
     * @param Page
     * @param PageSize
     * @param store
     * @returns {Q.Promise<any> | Promise<postcss.Result> | Promise<any>}
     */
    searchWheelPackageBothSeasons = ( { automaker, hasTPMS, vehicleSeries, Page = 1, PageSize = DEFAULT_PAGESIZE, store = locationStore.activeStoreId } ) => {
        this.loading = true
        this.lastSearch = { automaker, vehicleSeries }
        // --> console.log( `search::wheelPackageBothSeasons`, { automaker, vehicleSeries, store } )
        // let url = `/api/products/wheelpackages?VehicleSeries=X253&Automaker=Mercedes-Benz&Season=winter&store=ORTH_NAGEL_HALLE
        return Promise.all( [
            shop.cachedFetch( `${config.API_BASE}/products/wheelpackages?${queryString.stringify( {
                automaker,
                season: "summer",
                vehicleSeries,
                Page,
                PageSize,
                store
            } )}` )
            ,
            shop.cachedFetch( `${config.API_BASE}/products/wheelpackages?${queryString.stringify( {
                automaker,
                season: "winter",
                vehicleSeries,
                Page,
                PageSize,
                store
            } )}` )
            ,
        ] )
            .then( ( responses ) => {
                let firstResponse = responses[ 0 ]
                let merged = [...responses[ 0 ].results, ...responses[ 1 ].results]
                firstResponse.results = merged
                firstResponse.bothSeasons = true
                firstResponse.hasTPMS = hasTPMS
                return firstResponse
            } )
            .then( this.handleSearchResult )
            .catch( this.handleError )
    };

    /**
     *
     * @param id
     * @returns {Promise<any>}
     */
    fetchWheelPackage = ( id, { hasTPMS = false } = {} ) => {
        this.loadingDetail = true
        console.info( `search::requesting WheelPackage ${id}` )
        // let url = `/api/products/wheelpackages/${id}`
        return shop.cachedFetch( `${config.API_BASE}/products/wheelpackages/${id}` )
            .then( res => {
                res.hasTPMS = hasTPMS
                // --> console.log( "WPK ID", {res, hasTPMS, id} )
                return new ProductModel( res )
            } )
            .then( this.handleFetchRequest )
            .catch( this.handleError )
    }

    /**
     *
     * @param response
     * @returns {number }
     */
    handleSearchResult = response => {
        // --> console.log( `search::response`, response )
        
        let products = response.results.map( product => {
            product.hasTPMS = !!response.hasTPMS
            return new ProductModel( product )
        } )
        let sortedResult = products.sort( sortByPrice )
        this.result = sortedResult
        if ( response.bothSeasons ) {
            this.pagination = false
        } else {
            this.pagination = toPagination( response )
        }
        this.loading = false
        return sortedResult
    }

    handleFetchRequest = res => {
        // --> console.log( `search::fetch::result`, res.productID, res )
        let productModel = new ProductModel( res )
        this.wheelPackagesById.set( res.productID, productModel )
        this.loadingDetail = false
        return res
    }

    handleError = error => {
        console.error( "searchStore::", error )
        this.error = error
        this.loading = false
        return false
    }
}

const sortByPrice = ( a, b ) => {
    if ( a.priceGrossInEur < b.priceGrossInEur ) return -1
    if ( a.priceGrossInEur > b.priceGrossInEur ) return 1
    return 0
}

const toPagination = ( response ) => {
    const { currentPage, pageCount, pageSize, rowCount } = response
    return { currentPage, pageCount, pageSize, rowCount }
}


