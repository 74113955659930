import moment from "moment/moment";

export class TyreProfileModel {
    axle = 1
    position = "R"
    manufacturer = ""
    modelName = ""
    season = ""
    width = 500
    height = 500
    diameter = ""
    loadCapacityIndex = ""
    speedRating = ""
    profileDepth = 500
    constructionDate = ""
    groupId = ""
    tyreId = ""
    year = "2020"
    hasAgeWarning = false
    warningText = ""

    constructor(data, tyres) {
        Object.assign(this, data)
        this.tyres = tyres
        if(!this.constructionDate){
            this.constructionDateWarning = "Kein Datum angegeben"
        }

        this.constructionDate = this.constructionDate || "----"
        this.year = moment(this.constructionDate.slice(-2), "YY").format("YYYY")
        this.age = moment().diff(moment(this.year, "YYYY"), "years")
        this.warningText =  this.constructionDateWarning ||  (this.age >= 8 ? "Das Gummi ist nun hart und Bremswerte können nicht mehr eingehalten werden. Erneuern Sie Ihre Reifen." : "Alles gut!")
        this.hasAgeWarning = this.constructionDateWarning || (this.age >= 8)
        this.warningProfileText =  this.profileDepth < 3 ? "Das Profil ist zu gering. Erneuern Sie Ihre Reifen." : "Alles gut!"
        this.hasProfileWarning = this.profileDepth < 3 && !this.constructionDateWarning

        this.scoreAge = 10
        this.profileColor = "bg-green-500"
        this.ageColor = "bg-green-500"
        this.scoreProfile = 10

        this.scoreAge = (Math.max(10 - (this.age * 0.8), 0))

        if (this.scoreAge >= 2) {
            this.scoreAgeColor = "bg-green-500"
        }
        if (this.scoreAge >= 8) {
            this.scoreAgeColor = "bg-green-500"
        }
        if (this.scoreAge >= 10) {
            this.scoreAgeColor = "bg-yellow-500"
        }
        if (this.scoreAge >= 12) {
            this.scoreAgeColor = "bg-red-500"
        }
        if (this.scoreAge >= 14) {
            this.ageColor = "bg-red-500"
        }

        if (this.profileDepth < 8) {
            this.scoreProfile = 8
            this.profileColor = "bg-green-500"
        }
        if (this.profileDepth < 4) {
            this.scoreProfile = 6
            this.profileColor = "bg-yellow-500"
        }
        if (this.profileDepth <= 3) {
            this.scoreProfile = 3
            this.profileColor = "bg-red-500"
        }
        if (this.profileDepth < 2) {
            this.scoreProfile = 2
            this.profileColor = "bg-red-500"
        }
    }
}