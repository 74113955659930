export default class VehicleMeta {
    bodiesForLabourTimes = []
    bodiesForMaintenance = []
    gearBoxes = []
    hasLabourTimesServices = false
    hasMaintenanceServices = false
    
    constructor( meta ) {
        Object.assign( this, meta )
        this.defaultBodyId = this.bodiesForLabourTimes && this.bodiesForLabourTimes[ 0 ] && this.bodiesForLabourTimes[ 0 ].id
        this.bodyId = this.bodyId || this.defaultBodyId
    }
    
}
