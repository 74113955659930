const seasonNames = {
    g     : "Ganzjahres",
    G     : "Ganzjahres",
    w     : "Winter",
    s     : "Sommer",
    W     : "Winter",
    S     : "Sommer",
    a     : "Ganzjahres",
    A     : "Ganzjahres",
    summer: "Sommer",
    winter: "Winter",
    yearround: "Ganzjahr"
}

const seasonQueryKeys = {
    g     : "yearround",
    w     : "winter",
    s     : "summer",
    G     : "yearround",
    W     : "winter",
    S     : "summer",
    a     : "yearround",
    A     : "yearround",
    summer: "summer",
    winter: "winter"
}


export {seasonQueryKeys}
export default seasonNames
