import _, {every, minBy} from "lodash-es"
import {action, computed, observable, toJS, makeObservable} from "mobx"
import {toast} from "react-toastify"
//CART V2
import store from "store"
import cart from "store/cart"
import moment from "moment"

// import locationStore from "store/locationStore";
// import searchStore from "store/searchStore"
// import sessionStore from "store/sessionStore"
import config from "store/store.config"
import Track from "utils/Track"
// import cmsStore from "store/cmsStore"
import Order from "model/Order"
import {getPriceSignal, getPriceTitle} from "../component/Price/PriceSignalContainer"
import {DEFAULT_AVAILABLE_DAYS, ServiceProductModel} from "../model/ServiceProductModel"
import {MAX_CUSTOMER_REMARK_LENGTH} from "../page/CartPage/CartPage"

const ONLINE_STORE_KEY = "NACARMOS"

const today = moment().endOf("day")

export default class CartStore {
	order               = null
	extras              = {}
	store               = false
	loading             = false
	automaker           = null
	wheelPackage        = false
	userID              = "4711" // pre-defined
	originalOrderNumber = null
	products            = []
	productById         = {}
	productByCategory   = {}
	bill                = {}
	billByOrder         = new Map()
	discountCodes       = []

	allowInvoiceEmail      = true
	primaryProductPrice    = 0
	primaryProductDiscount = 0

	billingAddress  = false
	deliveryAddress = false

	ready = false

	items    = []
	itemById = {}

	status = false

	typeKeyNumber             = ""
	manufacturerKeyNumber     = ""
	checkVehicleCompatibility = null // set in Configuration page

	selectedMontage = false
	selectedPflege  = false

	appointmentsAvailable = null
	orderLocation         = null
	orderURI              = null

	contextByKey = new Map()

	total         = 0
	totalDiscount = 0
	totalRetail   = 0

	onlyServices     = false
	hasService       = false
	hasMobility      = false
	mobilityProducts = []

	constructor() {
		makeObservable(this, {
			order:                            observable,
			allowInvoiceEmail:                observable,
			extras:                           observable,
			store:                            observable,
			loading:                          observable,
			automaker:                        observable,
			originalOrderNumber:              observable,
			wheelPackage:                     observable,
			userID:                           observable,
			products:                         observable,
			productById:                      observable,
			productByCategory:                observable,
			bill:                             observable,
			billByOrder:                      observable,
			discountCodes:                    observable,
			primaryProductPrice:              observable,
			primaryProductDiscount:           observable,
			billingAddress:                   observable,
			deliveryAddress:                  observable,
			ready:                            observable,
			items:                            observable,
			itemById:                         observable,
			status:                           observable,
			typeKeyNumber:                    observable,
			manufacturerKeyNumber:            observable,
			checkVehicleCompatibility:        observable,
			selectedMontage:                  observable,
			selectedPflege:                   observable,
			appointmentsAvailable:            observable,
			orderLocation:                    observable,
			orderURI:                         observable,
			contextByKey:                     observable,
			total:                            observable,
			totalDiscount:                    observable,
			totalRetail:                      observable,
			onlyServices:                     observable,
			hasService:                       observable,
			hasMobility:                      observable,
			mobilityProducts:                 observable,
			hasContent:                       computed,
			availableDays:                    computed,
			activeVIN:                        computed,
			activateDiscountCode:             action,
			needsVinCheck:                    computed,
			canCheckout:                      computed,
			getMinDeliveryDate:               computed,
			validFitting:                     computed,
			hasFixedDeliveryDates:            computed,
			addCustomServiceProduct:          action,
			sort:                             action,
			addPosition:                      action,
			togglePosition:                   action,
			removePosition:                   action,
			emptyCart:                        action,
			submitOrder:                      action,
			handleCartResponse:               action,
			hasUndeterminedPriceFlag:         computed,
			primaryPriceFlag:                 computed,
			priceFlags:                       computed,
			saveCartAnonym:                   action,
			postReservation:                  action,
			includeFreeMobility:              computed,
			cartHash:                         computed,
			fetchOrder:                       action.bound,
			handleOrder:                      action,
			setStoreLocation:                 action.bound,
			setAutomaker:                     action.bound,
			setUserID:                        action.bound,
			hasShippingService:               computed,
			primaryProduct:                   computed,
			setExtras:                        action,
			setPrimaryProduct:                action.bound,
			resetPrimaryProduct:              action,
			reset:                            action,
			resetAssociatedProducts:          action,
			addAssociatedProductToProduct:    action,
			addAssociatedProduct:             action,
			toggleAssociatedProduct:          action.bound,
			setAmount:                        action,
			setAssociatedProduct:             action.bound,
			changeProductAmount:              action.bound,
			clear:                            action,
			removeProduct:                    action.bound,
			isProductInCart:                  action.bound,
			serialize:                        action.bound,
			setInvoiceEmail:                  action.bound,
			recommendedRetailPriceGrossInEur: computed
		})
	}

	get hasContent() {
		return this.products.length > 0
	}

	get availableDays() {
		let availableDaysObj = {}

		DEFAULT_AVAILABLE_DAYS.forEach(dayNumber => {
			availableDaysObj[dayNumber] = true
		})

		this.products.forEach(product => {
			if (product.availableDays) {
				DEFAULT_AVAILABLE_DAYS.forEach(dayNumber => {
					let isAvailable = product.availableDays.includes(dayNumber)
					if (!isAvailable) {
						delete availableDaysObj[dayNumber]
						// availableDaysObj[ dayNumber ] = false
					}
				})
			}
		})

		return Object.keys(availableDaysObj).map(Number)
	}

	get activeVIN() {
		return this.getVIN()
	}

	get needsVinCheck() {
		const needsVinCheck = this.products.filter(product => {
			let needsCheck = product.associatedProducts.filter(p => p.needsVinCheck)
			return product.needsVinCheck || needsCheck.length > 0
		})
		return needsVinCheck.length > 0
	}

	get canCheckout() {
		return this.order
	}

	get getMinDeliveryDate() {

		const deliveryDaysByProductType = {
			"PRD": 3,
			"WPK": 2,
			"SRV": 1,
			"INS": 3
		}

		let minDeliveryDays = this.primaryProduct && this.primaryProduct.deliveryPeriodInDaysMin || deliveryDaysByProductType[this.primaryProduct.type] || 3
		if (!minDeliveryDays) {
			minDeliveryDays = 2
		}

		if (locationStore.activeStoreId === "NAGEL_SOHN_BORGHOLZHAUSEN") {
			const hasServiceFromU19 = this.products.map(e => e.subCategoryId).some(id => id === "U19")
			if (hasServiceFromU19) {
				// ADD 4 Delivery Days to Borgholzhausen b/c of their local logistics challenges
				minDeliveryDays = minDeliveryDays + 4
			}
		}
		this.minDeliveryDays = minDeliveryDays
		return moment().endOf("day").add(minDeliveryDays, "days").toISOString()
	}

	get validFitting() {
		return !this.checkVehicleCompatibility || (this.typeKeyNumber.length >= 8 && this.manufacturerKeyNumber >= 4)
	}

	get hasFixedDeliveryDates() {
		return this.primaryProduct.fixedDeliveryDates && this.primaryProduct.fixedDeliveryDates.length > 0
	}

	get isReady() {
		return this.products.length > 0
	}

	get hasUndeterminedPriceFlag() {
		return !!this.priceFlags.filter(f => f.priceFlag === "undetermined").length
	}

	get primaryPriceFlag() {
		let priceFlags  = this.priceFlags
		let primaryFlag = minBy(priceFlags, "priceSignal")
		return primaryFlag
	}

	get priceFlags() {
		return _.flatten(this.products.map(product => {
			const priceFlagMeta = {
				priceFlag:   product.priceFlag,
				priceTitle:  getPriceTitle(product.priceFlag),
				priceSignal: getPriceSignal(product.priceFlag)
			}
			return priceFlagMeta
		}))
	}

	get includeFreeMobility() {
		const hasProductsWithPrice = this.products.some(e => e.mainCategory !== "H20" && e.price > 0)
		const isBoxenstoppOnly     = every(this.products, (p => p.mainCategory === "H55"))
		return !isBoxenstoppOnly && hasProductsWithPrice
	}

	get cartHash() {
		return this.products
			.slice()
			.sort((a, b) => {
				return a.productID > b.productID ? 1 : -1
			})
			.map(p => `${p.productID}-${p.quantity}`).join(":")
	}

	get hasShippingService() {
		if (!this.primaryProduct) return false
		if (!this.primaryProduct.associatedProducts) return false
		return this.primaryProduct.associatedProducts.filter(s => s.serviceKey && s.serviceKey.includes("SPEDITION")).length > 0
	}

	get primaryProduct() {
		return this.products.length > 0 && this.products[0]
	}

	get hasShoppingCartID() {
		return this.shoppingCartID && this.shoppingCartID !== "undefined"
	}

	get isShippable() {
		let type        = this.primaryProduct && this.primaryProduct.productID.substr(0, 3)
		let isShippable = type === "WPK" || type === "TYR"
		return isShippable
	}

	get recommendedRetailPriceGrossInEur() {
		let recommendedRetailPriceGrossInEur = 0
		this.products.forEach(product => {
			if (product.recommendedRetailPriceGrossInEur) {
				recommendedRetailPriceGrossInEur += product.recommendedRetailPriceGrossInEur * product.quantity
			}

			let addons = _.reduce(product.associatedProducts, (sum, associated) => {
				const inc = (associated.price * associated.quantity)
				return sum + Number(inc) || 0
			}, 0)
			recommendedRetailPriceGrossInEur += Number(addons) || 0
		})
		return recommendedRetailPriceGrossInEur.toFixed(2)
	}

	setInvoiceEmail(isAllowed) {
		this.allowInvoiceEmail = !!isAllowed
	}

	getVIN() {
		let VIN = this.primaryProduct.VIN
		if (!VIN) {
			this.products.forEach(product => {
				if (product.VIN) VIN = product.VIN
			})
		}

		if (!VIN) {
			VIN = locationStore.activeVehicleProfile && locationStore.activeVehicleProfile.vehicleIdentificationNumber
		}

		return VIN
	}

	activateDiscountCode({couponCode}) {
		this.discountCodes.push(couponCode)
		// console.log( { code }, "activated" )
		this.save()
	}

	// // DON'T USE. WON'T WORK
	// putOrder( url, order ) {
	//   return fetch( url, {
	//     method:  "PUT",
	//     body:    JSON.stringify( order ),
	//     headers: {
	//       "Content-Type":  "application/json; charset=utf-8",
	//       "Authorization": sessionStore.authorization
	//     },
	//   } )
	//     .then( response => {
	//       this.loading = false
	//       if ( response.status >= 400 ) {
	//         this.resetOrder()
	//         toast.error( "Übertragungsfehler" )
	//         return false
	//       }
	//       this.fetchOrder( url )
	//       return response
	//     } )
	//     .then( this.handleOrder )
	//     .catch( err => {
	//       this.loading = false
	//       toast.error( "Fehler beim Übertragen des Warenkorbs" )
	//     } )
	// }

	filterDates(date) {
		// SIDEEFFECT IN GETTER USED D: Hope this is temporary
		let minDeliveryDate = this.getMinDeliveryDate

		const minDeliveryDays = this.minDeliveryDays || this.primaryProduct.deliveryPeriodInDaysMin || 2
		const minDay          = moment(today).add(minDeliveryDays - 1, "days")
		return moment(date).isAfter(minDay)
	}


	postTaskCompletions(){
		if(!sessionStore.profile) return
		if(!locationStore.activeVehicleProfile) return
		if(locationStore.activeVehicleProfile.isLocal) return
		const vehicleId = locationStore.activeVehicleProfile.vehicleId
		const taskItems = this.products.map(p => p.taskItem || p.metafield.taskItem)
		return Promise.all(taskItems.map(taskKey => {
			sessionStore.profile.setSnoozeItem(vehicleId, taskKey, {expiresAt: moment().add(30, "days").toISOString()})
		}))
	}

	addCustomServiceProduct(remark, mainCategoryName = "Reparaturwunsch", comment, productIDAddon = `${Math.random()
		.toString(32)}`, meta) {

		if (!cmsStore.onlyAppointmentProduct) {
			toast.warn(`Dienstleistung nicht gefunden. Bitte wenden Sie sich an unseren Service-Chat.`, {position: "top-center"})
			return false
		}
		const service = new ServiceProductModel(cmsStore.onlyAppointmentProduct)
		if (!service) {
			toast.warn(`Dienstleistung nicht gefunden. Bitte wenden Sie sich an unseren Service-Chat.`, {position: "top-center"})
			return false
		}
		// this.reset()
		const productID = `${service.productIDOriginal}-${productIDAddon}`
			service.mainCategoryName = mainCategoryName
			service.name             = remark
			service.productIDAddon   = productIDAddon
			service.productID        = productID
			service.customPriceName  = "noch offen"
			service.priceFlag        = "undetermined"
			service.priceText        = "noch offen"
			service.metafield        = {
				customerRemark:   comment || remark,
				customPriceName:  "noch offen",
				mainCategoryName: "Reparaturwunsch", ...meta
			}
			this.addPosition(service, 1)
		return service
	}

	sort() {

		const sortWeights = {
			"SRV": 1
		}

		this.products   = this.products.sort((a, b) => {
			let s1 = sortWeights[a.type] || 0
			let s2 = sortWeights[b.type] || 0
			if (s1 === s2) {
				if (a._timeAdded && b._timeAdded) {
					if (a._timeAdded === b._timeAdded) return a.productID > b.productID ? 1 : -1
					return a._timeAdded > b._timeAdded ? 1 : -1
				}
				return `${a.id}`.slice(3) > `${b.id}`.slice(3) ? -1 : 1
			}
			return s1 >= s2 ? -1 : 1
		})
		let productById = {}
		this.products.forEach(p => {
			productById[p.productID] = p
			if (p.associatedProducts) {
				p.associatedProducts.forEach(service => {
					productById[service.productID] = service
				})
			}
		})

		this.productById = productById
	}

	addAppointment({
		name,
		description,
		data
	} = {}) {
		const service = cmsStore.onlyAppointmentProduct
		if (!service) {
			toast.warn(`Servicetermin nicht gefunden. Bitte wenden Sie sich an den Service-Chat.`)
			return
		}

		// this.reset()
		service.name             = name
		service.description      = description
		service.descriptionTitle = description
		service.metafield        = {
			description,
			name
		}
		service.customData       = `${name} – ${description} – Fahrzeug: ${locationStore.activeVehicle.referenceId}`
		this.addPosition(service, 1)
	}


	_postCheckout = async (order) => {
		// SAVE taskItems to snooze
		const profile = sessionStore.profile
		const vehicleProfile = locationStore.activeVehicleProfile

	}

	addPosition(product, quantity = 1, comment) {
		console.log("ADD POSITION", product.metafield, product.productID, quantity, product._timeAdded)
		product.metafield = product.metafield || {}

		if (comment) {
			product.metafield.customerRemark = comment.substring(0, MAX_CUSTOMER_REMARK_LENGTH)
		}

		// COPY TASK ITEM -> This product will snooze the task on checkout
		if(product.taskItem){
			product.metafield.taskItem = product.taskItem
		}

		product.quantity                    = Number(quantity) || 1
		product._timeAdded                  = product._timeAdded || new Date().getTime() + Math.random().toString(32)
		product.type                        = product.productID?.substr(0, 3)
		this.productById[product.productID] = product
		this.productById                    = {...this.productById}
		this.products                       = Object.values(this.productById).filter(p => !p.isAssociatedProduct)
		this.sort()
		this.save()
		this.sync()
		return product
	}

	togglePosition(product, primaryProduct) {
		if (!product) return console.error("INVALID PRODUCT TOGGLE", {product})
		if (this.includes(product.productID)) {
			this.removePosition(product)
		}
		else {
			if (primaryProduct) this.addAssociatedProductToProduct(primaryProduct, product)
			if (!primaryProduct) this.addPosition(product)
		}
	}

	removePosition(product) {
		delete this.productById[product.productID]
		this.products = Object.values(this.productById).filter(p => !p.isAssociatedProduct)
		this.products = this.products.map(p => {
			if (p.associatedProducts) {
				p.associatedProducts = p.associatedProducts.filter(associated => associated.productID !== product.productID)
			}
			return p
		})
		this.sort()
		this.save()
		this.sync()
		this.hydrate()
	}

	async hydrate() {
		// console.log( "HYDRATE @", this.lastSyncMarker )
		this.cart           = cart
		this._cart          = cart
		this.shoppingCartID = cart.shoppingCartID || false
		if (this.shoppingCartID === "-1" || this.shoppingCartID === -1) {
			this.shoppingCartID = false
		}

		this.products       = store.get("cart") || []
		this.productById    = {}
		this.lastSyncMarker = store.get("NCG_CART_SYNC_MARKER")
		this.order          = store.get("order")
		this.sort()

		if (this.order) {
			this.orderLocation = this.order.orderLocation
			this.orderURI      = this.order.orderURI
		}

		this.bill = this.bill || {}

		if (this.orderLocation) {
			this.fetchOrder(this.orderLocation)
				.then(res => {
					// console.log( "order", res )
				})
		}

		if (this.products.length > 0 && this.products[0]) {
			this.setPrimaryProduct(this.products[0], this.products[0].quantity)
		}

		this.products.forEach(p => {
			if (p) {
				this.productById[p.productID] = p

				_.forEach(p.associatedProducts, product => {
					product.isAssociatedProduct         = p.productID
					this.productById[product.productID] = product
					this.assignCategoryProduct(product)
				})
			}
		})

		this.products = Object.values(this.productById).filter(p => !p.isAssociatedProduct)
		// console.log( "CARTSTORE READY", this.primaryProduct )
		this.calculate()
		await cart.hydrate()
		this.ready = true
	}

	emptyCart = () => {
		this.products = []
	}

	setPaymentData({
		transaction,
		type
	}) {
		this.bill.payment      = transaction
		this.bill.payment.type = type
		this.bill.products     = this.products
		this.bill.status       = "eSend"
		this.billByOrder.set(transaction.orderNumber, this.bill)
		// store.set( "bill", this.bill )
		Track.purchase({
						   bill:     this.bill,
						   products: this.products
					   })
		// console.log( "stored bill", this.bill )
		return this.bill
	}

	submitOrder = ({storeId} = {}) => {
		this.loading = true
		this.status  = "eLoading"
		let wheel    = this.products[0]
		if (!wheel) return false
		let {
				automaker,
				productType
			} = wheel
		if (productType === "tire") automaker = "ALLE"

		const activeVehicleProfile = locationStore.activeVehicleProfile

		automaker = automaker || locationStore.activeManufacturerKey
		storeId   = storeId || this.store || locationStore.activeStoreId

		if (!storeId) {
			console.error("NO STORE: Can't save cart")
		}

		let ok = true

		// validate
		if (this.checkVehicleCompatibility) {
			ok = ok && this.typeKeyNumber
		}

		if (!ok) {
			console.warn("TypeKeyNumber is missing from cart")
		}

		let billingAddress = this.billingAddress
		if (!billingAddress) {
			billingAddress = sessionStore.profile && sessionStore.profile.billingAddress
		}

		if (sessionStore.profile && sessionStore.profile.sourceOfRegistration === "ldap") {
			billingAddress = billingAddress || {
				city:        locationStore.activeStore.city,
				companyName: locationStore.activeStore.name,
				country:     "Deutschland",
				countryCode: "DE",
				housenumber: locationStore.activeStore.streetNumber,
				firstName:   "",
				lastName:    "",
				street:      locationStore.activeStore.street,
				title:       "Firma",
				zipCode:     locationStore.activeStore.postalCode
			}
		}

		if (!sessionStore.authorization) {
			console.error("No Session available")
		}

		// if ( this.orderLocation ) {
		//   return this.putOrder( this.orderURI, order )
		// }
		this.sync()

		return this.saveCart()
			.then(() => {
				const vehicle          = sessionStore.profile.vehicleByReferenceId[locationStore.activeVehicleReferenceId]
				// TODO: CHECK ACTIVE STORE
				const shortVINAddendum = checkoutStore.data.formData.shortVIN ? `(VIN: ${checkoutStore.data.formData.shortVIN})` : ""
				const isPrimaryAtStore = locationStore.isActiveStorePrimaryBrand(locationStore.activeManufacturerKey)
				let order              = new Order({
													   "store":                     storeId,
													   "automaker":                 automaker,
													   "shoppingCartID":            `${this.shoppingCartID}`,
													   "checkVehicleCompatibility": this.checkVehicleCompatibility,
													   "manufacturerKeyNumber":     this.manufacturerKeyNumber,
													   "typeKeyNumber":             this.typeKeyNumber,
													   billingAddress:              toJS(billingAddress),
													   deliveryAddress:             this.deliveryAddress ? toJS(this.deliveryAddress) : toJS(billingAddress),
													   "phoneNumber":               checkoutStore.data.formData.phone,
													   "message":                   checkoutStore.data.formData.comment + " " + shortVINAddendum,
													   "vehicle":                   {
														   manufacturerKeyNumber:       vehicle && vehicle.hsn && `${vehicle.hsn}`.substr(0, 4),
														   typeKeyNumber:               vehicle && vehicle.tsn,
														   vehicleReferenceId:          vehicle && vehicle.referenceId,
														   mileage:                     activeVehicleProfile.mileage,
														   firstRegistration:           activeVehicleProfile.firstRegistration ? activeVehicleProfile.firstRegistration : null,
														   vehicleIdentificationNumber: this.getVIN() || (vehicle && vehicle.vehicleIdentificationNumber),
														   licensePlate:                checkoutStore.data.formData.carId || checkoutStore.data.formData.licensePlate || (vehicle && vehicle.licensePlate) ||  checkoutStore.data.formData.shortVIN,
														   versionKeyNumber:            vehicle && vehicle.vsn,
														   manufacturer:                isPrimaryAtStore ? vehicle && vehicle.manufacturerName : "NACARMOS",
														   manufacturer2:               isPrimaryAtStore ? vehicle && vehicle.manufacturerName : "NACARMOS"
													   }
												   }).data

				// console.log( "order", order )

				return this.postOrder(order)
					.then(this.handleOrder)
					.then((serverOrder) => {
						this.bill.order = {
							...serverOrder,
							total: this.total
						}
						// store.set( "bill", this.bill )
						this.status     = "eReady"
						return this.order
					})
			})
			.catch(err => {

			})
	}

	fetch() {
		return cart.fetch(!sessionStore.profile)
			.then(data => {
				// console.log( "CART LOADED", data )
				if (data) this.mergePriceFromOrder(data)
				return data
			})
	}

	getAppointments = ({
		storeKey,
		vehicleReferenceId,
		favoredCheckinDate,
		onBehalfOf
	} = {}) => {
		if (this.products.length <= 0) return Promise.reject("No Products in Cart")
		if (this._appointmentPromise) return this._appointmentPromise

		// SET VEHICLE REFERENCE ID TO FIRST PRODUCT
		let wheel                = this.products[0]
		wheel.vehicleReferenceId = vehicleReferenceId

		this.store = storeKey || this.store
		return this._appointmentPromise = this.saveCart()
			.then(this.submitOrder)
			.then(async res => {
				let appointments         = await appointmentStore.setOrder(this.order, {
					favoredCheckinDate,
					onBehalfOf
				})
				this._appointmentPromise = false
				return {
					appointments,
					order: this.order
				}
			})
	}

	//SAVE WITH NEW ID
	postCart() {
		this.shoppingCartID = false
		cart.shoppingCartID = false
		return this.saveCart()
	}

	handleCartResponse(res) {
		res.shoppingCart.products.forEach(cartProduct => {
			let product = this.productById|[cartProduct.productID]
			if (product) {
				product.price           = cartProduct.price
				product.priceGrossInEur = cartProduct.price
			}
		})
		store.set("shoppingCartID", this.shoppingCartID)
		console.log(`received cart ${res.shoppingCartID}`)
		this.calculate()
	}

	saveCart() {
		this.loading       = true
		let primaryProduct = this.primaryProduct
		let {
				automaker,
				productType
			}              = primaryProduct || {}

		// MANUAL FIX automaker for tires
		if (productType === "tire") automaker = "ALLE"
		if (!automaker) automaker = locationStore.activeManufacturerKey

		const storeId = locationStore.activeStoreId || ONLINE_STORE_KEY

		if (!storeId) {
			console.error("NO STORE ID: Cannot save cart")
		}

		if (!primaryProduct) {
			// TODO: API CRASHES W/ Empty CART
			return null
		}

		return cart.save({
							 isAnonym:      !sessionStore.profile,
							 products:      this.products,
							 store:         storeId,
							 automaker,
							 discountCodes: this.discountCodes
						 })
			.then(res => {
				this.loading        = false
				this.shoppingCartID = `${res.shoppingCartID}`
				cart.shoppingCartID = this.shoppingCartID
				// GET REDUCED PRICING FROM CART
				this.handleCartResponse(res)
				return res
			})
			.catch(err => {
				console.error("CART ERROR", {err})
				this.loading = false
				return false
			})
	}

	saveCartAnonym() {
		this.loading       = true
		let primaryProduct = this.primaryProduct
		let {
				automaker,
				productType
			}              = primaryProduct

		// MANUAL FIX automaker for tires
		if (productType === "tire") automaker = "ALLE"
		const storeId = locationStore.activeStoreId || ONLINE_STORE_KEY

		if (!storeId) {
			console.error("NO STORE ID: Cannot save cart")
		}

		// throw new Error( "cart.postAnonym is not implemented" )
		let order = cart.makeOrder({
									   products: this.products,
									   store:    storeId,
									   automaker
								   })

		return fetch(`${config.API_BASE}ping-cart/anonymous`, {
			method:  "POST",
			mode:    "cors",
			body:    JSON.stringify(order),
			headers: {
				"Content-Type":   "application/json; charset=utf-8",
				"IdentityUserId": sessionStore.identityUserId
			}
		}).then(res => res.json())
			.then(res => {
				console.log("cart:anonym", {res})
				this.loading        = false
				this.shoppingCartID = `${res.shoppingCartID}`
				cart.shoppingCartID = this.shoppingCartID
				if(cart.shoppingCartID < 0)  console.error("INVALID SHOPPING CART ID", cart.shoppingCartID, this.products)
				console.log(`received anonymous cart ${res.shoppingCartID}`)
				this.handleCartResponse(res)
				return res
			})
			.catch(err => {
				console.error("CART ERRROR", {err})
				this.loading = false
				return false
			})
	}

	/**
	 * {
            "shoppingCartID": 6,
            "name":"Hans Lustig",
            "email":"peter.lustig@email.com",
            "store":"NAGEL_SOHN_VERSMOLD",
            "subscribeNewsletter": true,
            "phoneNumber": "0810238",
            }
	 * @param data
	 * @returns {Promise<*>}
	 */
	async postReservation(data) {
		let serverCart      = await this.saveCart()
		this.shoppingCartID = serverCart.shoppingCartID
		data.shoppingCartID = serverCart.shoppingCartID
		let reservation     = await fetch(`${config.API_BASE}ping-cart/reserve`, {
			method:  "POST",
			mode:    "cors",
			body:    JSON.stringify(data),
			headers: {
				"Content-Type":   "application/json; charset=utf-8",
				"IdentityUserId": sessionStore.identityUserId

			}
		}).then(res => res.json())

		this.reset()
		console.log({reservation})
		return {
			reservation,
			products: this.products.slice(0)
		}
	}

	postOrder(order) {

		if(this.shoppingCartID < 0)  console.error("INVALID SHOPPING CART ID", cart.shoppingCartID, this.products)

		// HOTFIX: .form is cirular
		if (order.billingAddress) {
			delete order.billingAddress.form
		}
		// HOTFIX: .form is cirular
		if (order.deliveryAddress) {
			delete order.deliveryAddress.form
		}

		// REMOVE false from vehicle data => Backend crashes
		const cleanVehicle = {...order.vehicle}
		Object.keys(cleanVehicle).map((key) => {
			if (!cleanVehicle[key]) delete cleanVehicle[key]
		})
		order.vehicle = cleanVehicle

		// attach employeeName to order
		if(sessionStore.originalUserProfile){
			order.employeeName = sessionStore.originalUserProfile.preferredEmployeeName
		}

		try {
			console.log("POST ORDER", {order})
			return fetch(`${config.API_BASE}/orders`, {
				method:  "POST",
				body:    JSON.stringify(order),
				headers: {
					"Content-Type":   "application/json; charset=utf-8",
					"IdentityUserId": sessionStore.identityUserId,
					"Authorization":  sessionStore.authorization
				}
			})
				.then(response => {
					this.loading = false

					if (response.status >= 400) {
						return false
					}

					console.log(`response`, response)
					const orderLocation = response.headers.get("Location")
					console.log("orderLocation", orderLocation)
					let loc            = `${config.URL_BASE}${orderLocation}`
					this.orderLocation = orderLocation
					this.orderURI      = loc
					store.set("orderLocation", orderLocation)
					store.set("orderURI", loc)
					// appointmentStore.hydrate( order )
					return this.fetchOrder(loc)
				})
		} catch (err) {
			console.error("POST CART ERROR", err)
		}

	}

	fetchOrder(location) {
		return fetch(location, {
			headers: {
				"Authorization":  sessionStore.authorization,
				"IdentityUserId": sessionStore.identityUserId
			}
		})
			.then(res => res.json())
			.then(this.handleOrder)
			.catch(err => {
				this.resetOrder()
				console.error(`no order`, err)
			})
	}

	resetOrder() {
		this.orderLocation = null
		this.orderURI      = null
		store.remove("orderLocation")
		store.remove("orderURI")
		store.remove("cart")
		store.remove("order")
		this.order = false
		this.hydrate()
	}

	handleOrder = (order) => {
		console.log(`received order`, order)
		if (order) {
			this.order = order
			store.set("order", order)
			// appointmentStore.setOrder( order )
		}
		return order
	}

	mergePriceFromOrder(cart) {
		console.log("mergePriceFromOrder")
		let recalculate = false
		cart.products.forEach(position => {
			let productInCart = this.productById[position.productID]
			if (productInCart) {
				console.log("MERGE PRICE PRIMARY", productInCart.priceGrossInEur, position.price)
				if (productInCart.priceGrossInEur !== position.price) {
					recalculate = true
					console.error("PRICE DISCREPANCY IN ", {
						position,
						productInCart
					})
					toast.info("Preise im Warenkorb haben sich geändert.")
				}
				productInCart.price           = position.price
				productInCart.priceGrossInEur = position.price
				productInCart.totalPrice      = position.totalPrice
			}

			position.associatedProducts.forEach(position => {
				let productInCart = this.productById[position.productID]
				if (productInCart) {
					console.log("MERGE PRICE ASSOCIATED", productInCart.priceGrossInEur, position.price)
					productInCart.price           = position.price
					productInCart.priceGrossInEur = position.price
					productInCart.totalPrice      = position.totalPrice
				}
			})

		})
		if (recalculate) {
			this.calculate()
		}
	}

	setStoreLocation(store) {
		// OBSOLETE -> Always source store from locationStore
		console.log("SET STORE-CART LOCATION", {store})
		this.store               = store
		this.cart.shoppingCartID = false
		this.shoppingCartID      = false
		this.save()
	}

	setAutomaker(maker) {
		this.automaker = maker
	}

	setOriginalOrderNumber(orderNumber) {
		this.originalOrderNumber = orderNumber
	}

	setUserID(id) {
		this.userID = id
	}

	setExtras(extras) {
		Object.assign(this.extras, extras)
	}

	addFromId = async (id, count = 1) => {
		const product = await searchStore.fetchWheelPackage(id)
		this.addPosition(product, count)
	}

	setPrimaryProduct(product, n, extras = false) {

		// HOTFIX 24.03.2021
		product.associatedProducts = product.associatedProducts || []

		if (!product) {
			console.warn("Invalid Product set")
			return
		}

		if (extras) {
			Object.assign(this.extras, extras)
		}

		product.type = product.productID?.substr(0, 3)
		console.log("setPrimaryProduct", product, n, `PRODUCT TYPE ${product.type}`)
		product.productType = product.productID.substr(0, 3)
		if (n) product.quantity = n
		if (product.quantity === undefined) product.quantity = 1
		const amount      = product.quantity
		this.wheelPackage = product

		if (this.primaryProduct && product.productID === this.primaryProduct.productID) {
			// console.info( "Setting the same productID twice will not reset the cart" )
			return
		}

		let productConfiguration = {
			...product,
			amount,
			associatedProducts: product.associatedProducts || []
		}

		this.productByCategory              = {}
		this.productById                    = {}
		this.products                       = []
		this.selectedMontage                = false
		this.selectedPflege                 = false
		this.fittingKeyOverride             = false
		this.productById[product.productID] = productConfiguration
		this.products.push(productConfiguration)

		this.calculate()
	}

	resetPrimaryProduct(product, n) {
		if (!product) return
		if (n) product.quantity = n
		if (product.quantity === undefined) product.quantity = 1

		const amount             = product.quantity
		this.wheelPackage        = product
		let productConfiguration = {
			...product,
			amount,
			associatedProducts: []
		}

		this.productByCategory              = {}
		this.productById                    = {}
		this.products                       = []
		this.selectedMontage                = false
		this.selectedPflege                 = false
		this.fittingKeyOverride             = false
		this.productById[product.productID] = productConfiguration
		this.products.push(productConfiguration)
		console.log("STORE", this.products.length, this.products)
		store.set("cart", this.products)
	}

	containsProductFromCategory(category) {
		return this.products[0] && this.products[0].associatedProducts.filter(p => p.category === category).length
	}

	includes(productID) {
		return !!this.productById[productID]
	}

	includesCustomAppointment(productIDAddon) {
		if (!cmsStore.onlyAppointmentProduct) return false
		return this.includes(`${cmsStore.onlyAppointmentProduct.productID}-${productIDAddon}`)
	}

	reset() {
		this.shoppingCartID = false
		cart.shoppingCartID = false
		this.products       = []
		this.productById    = {}
		delete this.shoppingCartID
		paymentStore.reset()
		this.save()
	}

	resetAssociatedProducts(product) {
		product.associatedProducts = []
	}

	/**
	 *
	 * @param product
	 * @param amount: number
	 */
	addAssociatedProductToProduct(mainProduct, associatedProduct, amount = 1, toggle = false) {
		const primaryProduct = this.productById[mainProduct.productID]
		if (!primaryProduct) {
			console.warn("PRODUCT NOT IN CART", {mainProduct})
			return associatedProduct
		}
		associatedProduct.productType                 = associatedProduct.productID.substr(0, 3)
		associatedProduct.quantity                    = amount
		associatedProduct.isAssociatedProduct         = primaryProduct.productID
		this.productById[associatedProduct.productID] = associatedProduct
		let found                                     = primaryProduct.associatedProducts.filter(e => associatedProduct.productID === e.productID)
		if (found.length === 0) {
			// add-/
			primaryProduct.associatedProducts.push(associatedProduct)
			this.productById[associatedProduct.productID] = associatedProduct
			this.assignCategoryProduct(associatedProduct)
		}
		else {
			if (toggle) {
				this.removeProduct(associatedProduct)
				return associatedProduct
			}
			primaryProduct.associatedProducts.forEach(p => {
				if (p.productID === associatedProduct.productID) {
					p.quantity = amount
					return associatedProduct
				}
				else {
					return p
				}
			})
		}

		this.calculate()
		return associatedProduct
	}

	/**
	 *
	 * @param product
	 * @param amount: number
	 */
	addAssociatedProduct(product, amount = 1) {
		return this.addAssociatedProductToProduct(this.primaryProduct, product, amount)
	}

	/**
	 *
	 * @param productID primaryProduct
	 * @param associatedProduct
	 * @param amount
	 * @returns {boolean}
	 */
	toggleAssociatedProduct({productID}, associatedProduct, amount = 1) {
		console.log(`adding product cat`, associatedProduct, associatedProduct && associatedProduct.category)
		const primaryProduct = this.productById[productID]
		if (!primaryProduct) {
			console.warn("cart::add::associated", "fail")
			return false
		}
		if (!associatedProduct) {
			console.warn("cart::add::associated", "missing")
			return false
		}

		associatedProduct.isAssociatedProduct = productID
		let subProduct                        = _.find(primaryProduct.associatedProducts, p => p.productID === associatedProduct.productID)
		if (subProduct) {
			primaryProduct.associatedProducts             = primaryProduct.associatedProducts.filter(p => p.productID !== associatedProduct.productID)
			this.productById[associatedProduct.productID] = false
			const category                                = subProduct.category

			if (category) {
				this.productByCategory[category] = false
				this.assignCategoryProduct(subProduct, true)
			}

		}
		else {
			// add
			associatedProduct.quantity = amount
			associatedProduct.quantity = amount
			primaryProduct.associatedProducts.push(associatedProduct)
			this.productById[associatedProduct.productID] = associatedProduct
			this.assignCategoryProduct(associatedProduct)
		}

		this.save()
	}

	assignCategoryProduct(product, deleteEntry = false) {
		if (deleteEntry) {
			delete this.productByCategory[product.category]
			if (product.category === "MONTAGE") this.selectedMontage = false
			if (product.category === "PFLEGE") this.selectedPflege = false
		}
		else {
			this.productByCategory[product.category] = product
			if (product.category === "MONTAGE") this.selectedMontage = product
			if (product.category === "PFLEGE") this.selectedPflege = product
		}

	}

	setAmount = (n) => {
		this.primaryProduct.quantity = n
		this.products[0].quantity    = n
		let primary                  = this.productById[this.primaryProduct.productID]
		primary.quantity             = n
		this.save()
	}

	save() {
		this.calculate()
		store.set("cart", this.products)
		return this.saveCart()
	}

	setAssociatedProduct(product, associatedProduct) {
		const {productID} = product
		console.log(`adding product cat`, {
			product,
			associatedProduct
		})
		Track.addProduct(product)
		const productConfiguration = this.productById[productID]

		if (!productConfiguration) {
			console.warn("cart::add::associated", "fail")
			return false
		}

		let subProduct = _.find(productConfiguration.associatedProducts, p => p.productID === associatedProduct.productID)
		if (subProduct) {
			// overwrite
			console.log(`overwrite`, associatedProduct.productID)
			subProduct.quantity = associatedProduct.quantity
		}
		else {
			// add
			console.log(`add`, associatedProduct.productID)
			productConfiguration.associatedProducts.push(associatedProduct)
		}
		this.productById[associatedProduct.productID] = associatedProduct
		this.productById[productID]                   = productConfiguration
		this.products                                 = [productConfiguration] // TODO: triggers observers as well as clean code fetishists
		console.log("STORE", this.products.length, this.products)
		this.calculate()
	}

	changeProductAmount(productID, amount) {
		const productConfiguration = this.products[0]
		if (!productConfiguration) {
			console.warn("cart::add::associated", "fail")
			return false
		}

		let subProduct = _.find(productConfiguration.associatedProducts, p => p.productID === productID)
		if (subProduct) {
			// overwrite
			subProduct.quantity = Math.max(0, subProduct.quantity + amount)
		}
		else {
			console.error(`product: ${productID} not in cart`)
		}

		this.products = [productConfiguration]
		console.log("STORE", this.products.length, this.products)
		store.set("cart", this.products)
		this.calculate()
	}

	clear() {
		this.products          = []
		this.productById       = {}
		this.productByCategory = {}
		this.calculate()
	}

	removeProduct(productID) {
		let product = this.productById[productID]
		if (product) product.quantity = 0
		this.products = this.products.filter(p => productID !== p.productID)
		this.products = this.products.map(p => ({
			...p,
			associatedProducts: p.associatedProducts.filter(a => productID !== a.productID)
		}))

		this.productById[productID] = false
		console.log("STORE", this.products.length, this.products)
		store.set("cart", this.products)
		this.calculate()
	}

	removeCustomProduct(productIDAddon) {
		if (!cmsStore.onlyAppointmentProduct) return false
		this.removeProduct(`${cmsStore.onlyAppointmentProduct.productID}-${productIDAddon}`)
	}

	isProductInCart({productID}) {
		return !!this.productById[productID]
	}

	serialize() {
		return this.products.map(p => ({
			productID:          p.productID,
			amount:             p.quantity,
			associatedProducts: p.associatedProducts.map(a => ({
				productID: a.productID,
				amount:    a.quantity
			}))
		}))
	}

	calculate() {
		this.sort()
		let recommendedRetailPriceGrossInEur = 0
		let cartTotal                        = 0
		let primaryProductPrice              = 0
		let primaryProductDiscount           = 0
		let onlyServices                     = true
		let hasService                       = false
		let hasMobility                      = false
		const mobilityProducts               = []
		// add primary product price when it has an amount set
		this.products.forEach(product => {
			let amount             = product.quantity
			const productPrice     = product.priceGrossInEur || product.price || 0
			primaryProductPrice += productPrice * amount
			cartTotal += productPrice * amount
			recommendedRetailPriceGrossInEur += product.recommendedRetailPriceGrossInEur * amount
			let addonPrice         = 0
			let addonRetailPrice   = 0
			primaryProductDiscount = recommendedRetailPriceGrossInEur - primaryProductPrice

			let type = product.productID?.substr(0, 3)
			if (type === "SRV" || type === "PRD") {
				hasService = true
			}

			// CST products are marked by a customType to differentiate what specifically they are
			if (type === "CST" && product.customType === "service") {
				hasService = true
			}

			if (type === "CST" && product.customType !== "service") {
				onlyServices = false
			}

			if (type !== "SRV" && type !== "PRD" && type !== "CST") {
				onlyServices = false
			}

			if (product.mainCategory === "H20") {
				hasMobility = true
				mobilityProducts.push(product)
			}

			product.associatedProducts && product.associatedProducts.forEach(associatedProduct => {
				addonPrice       = Number(associatedProduct.price * (associatedProduct.quantity)) || 0
				addonRetailPrice = Number(product.recommendedRetailPriceGrossInEur * (product.quantity)) || 0
				cartTotal += Number(addonPrice) || 0
				let type         = associatedProduct.productID.substr(0, 3)
				if (type === "SRV" || type === "PRD") {
					hasService = true
				}

				if (associatedProduct.mainCategory === "H20" || associatedProduct.subCategoryId === "U200") {
					hasMobility = true
					mobilityProducts.push(associatedProduct)
				}
			})

			recommendedRetailPriceGrossInEur += Number(addonRetailPrice) || 0
		})

		this.primaryProductPrice    = primaryProductPrice
		this.primaryProductDiscount = primaryProductDiscount
		this.total                  = cartTotal.toFixed(2)
		this.totalRetail            = recommendedRetailPriceGrossInEur.toFixed(2)
		this.totalDiscount          = primaryProductDiscount
		this.onlyServices           = onlyServices
		this.hasService             = hasService
		this.hasMobility            = hasMobility
		this.mobilityProducts       = mobilityProducts
		store.set("cart", this.products)
		this.startSync()

	}

	hasProductType(type) {
		let isShippable = Object.values(this.productById).filter(e => e.type === type).length > 0
		return isShippable
	}

	sync() {
		this.lastSyncMarker = Date.now()
		store.set("NCG_CART_SYNC_MARKER", this.lastSyncMarker)
	}

	startSync() {
		// if ( this._syncOn ) return
		//
		// window.setInterval( () => {
		//     this.testSync()
		// }, 500 )
		// this._syncOn = true
	}

	testSync() {
		// let marker = store.get( "NCG_CART_SYNC_MARKER" )
		// if ( marker !== this.lastSyncMarker ) {
		//     this.hydrate()
		// }
		// this.lastSyncMarker = marker
	}
}
