import { action, observable, makeObservable } from "mobx";
import config from "./store.config"
import queryString from "query-string"
import _ from "lodash-es";
import shop from "store/shop"
import ProductModel from "../model/Product";

/**
 *
 * @param rims
 * @returns {Array}
 */
const getUniqTyreDimensionsFromRims = rims => _.uniqBy( _.flatten( rims.map( rim => _.flatten( rim.styles.map( style => style.tyreDimensions.map( dimension => ({hash: `${dimension.width}-${dimension.height}-${dimension.minLoadIndex}-${dimension.minSpeedIndex}-${dimension.season}`, ...dimension}) ) ) ) ) ).sort( ( a, b ) => a.width > b.width ? 1 : -1 ), a => a.hash )


class Rim {

    constructor( data ) {
        Object.assign( this, data )


        this.cheapestPrice = this.priceGross


        if ( this.styles ) {
            this.cheapestPrice = this.styles[0].priceGross

            this.diameters = _.uniq(this.styles
                .map( style => style.diameter)
                .sort( ( a, b ) => a > b ? 1 : -1 ))

            this.styles.forEach( style => {
                style.price = style.priceGross
                style.priceRims = style.price * 4
                style.priceTyres = style.wheelPackagePriceGrossInEur - (style.price * 4)
                style.priceWheelPackage = style.wheelPackagePriceGrossInEur
                if ( this.cheapest > style.priceWheelPackage ) {
                    this.cheapest = style.priceWheelPackage
                }
            } )
        }


        if ( this.priceGross ) {
            this.price = this.priceGross
            this.priceRims = this.price * 4
            this.priceTyres = this.wheelPackagePriceGrossInEur - (this.price * 4)
            this.priceWheelPackage = this.wheelPackagePriceGrossInEur
        }

        this.title = `${data.manufacturer} ${data.design}`
        this.previewId = data.perspectivePictureUrl.replace( /.$/, "f" )  //  TODO: Backend
    }
}

export default class RimStore {
    loading = false;
    rimById = new Map();
    rimByTypeKey = new Map();
    rimDetailsById = new Map();
    rims = [];

    selection = {};

    constructor() {
        makeObservable(this, {
            loading: observable,
            rimById: observable,
            rimByTypeKey: observable,
            rimDetailsById: observable,
            rims: observable,
            selection: observable,
            select: action,
            cheapest: action,
            getRim: action,
            getRims: action
        });
    }

    select({rim, style, sensor}) {
        this.selection = {rim, style, sensor}
    }

    parseRimIds = ( text ) => {
        const textIds = String( text )
        return textIds.split( "," )
    }

    async cheapest(
        rims,
        quality = "premium",
        category = "car",
        storeId = locationStore.activeStoreId
    ) {
        const dimensions = getUniqTyreDimensionsFromRims( rims )
        try {
            const data = await shop.cachedFetch( `${config.API_BASE}/products/v2/tyres/cheapest`, {
                headers: {
                    'Accept'      : 'application/json',
                    'Content-Type': 'application/json'
                },
                method : "POST",
                body   : JSON.stringify( {
                    dimensions,
                    "quality" : "premium",
                    "store"   : storeId,
                    "category": category
                } )
            } )
            const rimStyleById = {}

            const createHash = dimension => `${dimension.width}-${dimension.height}-${dimension.minSpeedIndex}-${dimension.season}`
            rims.forEach( rim => {
                rim.styles.forEach( rimStyle => {
                    const hashes = rimStyle.tyreDimensions.map( dimension => {
                        // diameter: "17"
                        // height: "50"
                        // minLoadIndex: "88"
                        // minSpeedIndex: "H"
                        // season: "W"
                        // width: "225"
                        const dimensionHash = createHash( dimension )
                        return dimensionHash
                    } )
                    rimStyleById[rimStyle.productID] = rimStyle
                    rimStyle.hashes = hashes
                } )
            } )
            const tyrePriceByRimStyleId = {}
            const tyreDefaultByRimStyleId = {}
            data.forEach( result => {
                let {dimension} = result
                const tyre = new ProductModel(result.tyre)
                const hash = createHash( dimension )
                rims.forEach( rim => {
                    rim.styles.forEach( rimStyle => {
                        if ( rimStyle.hashes.includes( hash ) ) {
                            tyrePriceByRimStyleId[rimStyle.productID] = Math.min( tyrePriceByRimStyleId[rimStyle.productID] || Infinity, tyre ? tyre.price : Infinity )
                            tyreDefaultByRimStyleId[rimStyle.productID] = tyre
                        }
                    } )
                } )
            } )
            return {tyrePriceByRimStyleId, tyreDefaultByRimStyleId}
        } catch ( err ) {
            console.error( `RimStore`, err )
            return {tyrePriceByRimStyleId:{}, tyreDefaultByRimStyleId:{}}
        }
    }

    getRim({productID, season = "winter", vehicleReferenceId}) {
        if ( !vehicleReferenceId ) throw Error( "NO REF ID" )
        return shop.cachedFetch( `${config.API_BASE}/rims/${productID}/${vehicleReferenceId}?season=${season}` )
            .then( data => {
                this.loading = false
                const rim = new Rim( data )
                this.rimDetailsById.set( productID, rim )
                return rim
            } ).catch( err => {
                this.loading = false
                console.error("Error fetching rim", err)
                return err
            } )
    }

    getDiameterForVehicle( {vehicle} ) {
        return shop.cachedFetch( `${config.API_BASE}/rims/diameters/${vehicle}` )
            .then( data => {
                // // --> console.log( "DIAMETER", `${config.API_BASE}/rims/diameters/${vehicle}`, data )
                return data
            } )
            .catch( err => {
                console.error( "NO DIAMETER", err )
                this.loading = false
                return []
            } )
    }

    getRims({vehicle, season = "winter", page = 1, pageSize = 64, diameter = ""}) {
        this.loading = true
        const params = {
            vehicle,
            season,
            page,
            diameter,
            pageSize
        }
        if ( !diameter ) delete params.diameter
        return shop.cachedFetch( `${config.API_BASE}/rims?${queryString.stringify( params )}` )
            .then( data => {
                this.loading = false
                this.rims = data.results.map( data => {
                    const rim = new Rim( data )
                    this.rimByTypeKey.set( rim.typeKey, rim )
                    this.rimById.set( rim.styles[0].productID, rim )
                    return rim
                } ).filter( rim => rim )
                return this.rims
                // this.rimById.set( rimId, data )
            } ).catch( err => {
                this.loading = false
                console.error( err )
                return err
            } )
    }
}

