import { action, observable, makeObservable } from "mobx";

export default class PortalStore {
  showCart = false;
  showSidebar = false;

  toggleCart = () => {
    this.showCart = !this.showCart
    this.showSidebar = false
  };

  toggleSidebar = () => {
    this.showCart = false
    this.showSidebar = !this.showSidebar
  };

  constructor() {
    makeObservable(this, {
      showCart: observable,
      showSidebar: observable,
      toggleCart: action,
      toggleSidebar: action
    });
  }
}
