import {slugifyLicensePlate} from "../../utils/slugifyLicensePlate"

export class OrderHistoryModel {
	primaryStore = ""

	constructor(data) {
		Object.assign(this, data)
		this.licensePlate = slugifyLicensePlate(this.customerVehicle.licensePlate)
		this.vehicleIdentificationNumber = this.customerVehicle?.vehicleIdentificationNumber
	}
}
