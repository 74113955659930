import {action, makeObservable, observable} from "mobx"
import _ from "lodash-es"
import axios from "axios"
import config from "./store.config"
import {VehicleProfile} from "./VehicleProfile"
// import criteriaStore from "./criteriaStore";
import {toast} from "react-toastify"
import {WHOAMI_URL} from "./sessionStore"
import moment from "moment";


export class UserProfile {

    addresses = []
    deliveryAddress = {}
    primaryStoreKey = null
    greetingTitle = ""

    tyreInventoryByVehicleId = {}

    vehicles = []
    tyres = []
    tyresByGroup = new Map()

    settings = {}
    vehicleByVIN = {}
    vehicleByReferenceId = {}
    primaryVehicle = false

    vehicleByVehicleId = {}
    isLDAP = false

    promptForPrimaryStoreReference = null
    customerNumber = null
    primaryStoreReference = null

    constructor(data) {
        makeObservable(this, {
            addresses: observable,
            deliveryAddress: observable,
            tyreInventoryByVehicleId: observable,
            vehicles: observable,
            tyres: observable,
            tyresByGroup: observable,
            settings: observable,
            vehicleByVIN: observable,
            vehicleByReferenceId: observable,
            primaryVehicle: observable,
            vehicleByVehicleId: observable,
            isLDAP: observable,
            promptForPrimaryStoreReference: observable,
            customerNumber: observable,
            primaryStoreReference: observable,
            snoozeList: observable,
            parseStoreReferences: action,
            putStoreReference: action,
            putDetails: action,
            setVehicles: action,
            requestInvoice: action,
            putSetting: action,
            fetchStoredTiresVIN: action,
            postVehicle: action,
            putVehicle: action,
            patchVehicle: action,
            deleteVehicle: action,
            archiveVehicle: action,
            saveAddress: action,
            postAddress: action,
            putAddress: action,
            deleteAddress: action,
            put: action,
            setSnoozeItem: action
        })

        this.setState(data)
        this.deliveryAddress = this.addresses[0]
        this.billingAddress = _.first(this.addresses && this.addresses
            .filter(a => a.type === "Default")) || this.addresses[0]
        // this.fetchStoredTires()

        this.customerNumber = ""
        this.parseStoreReferences(this.storeReferences)

        this.vehicles = this.vehicles || []
        this.setVehicles(this.vehicles)


        this.parseSettings(data.clientSettings)

        if (this.settings.ldapDefaultStore && !sessionStore?.storeFilterKey) {
            sessionStore?.setStoreFilterKey(this.settings.ldapDefaultStore)
            // console.log( "DEFAULT STORE KEY", sessionStore?.storeFilterKey )
        }
        if (!this.settings.ldapDefaultStore && !sessionStore?.storeFilterKey && this.isLDAP) {
            sessionStore?.setStoreFilterKey(this.primaryStoreKey)
        }

        this.sourceOfRegistration = `${this.sourceOfRegistration}`.toLowerCase()
        this.isLDAP = this.sourceOfRegistration === "ldap"

        this.employeeFullName = `${this.firstName} ${this.lastName}`.trim()
        this.preferredEmployeeName = this.firstName ? `${this.firstName} ${this.lastName}` : this.companyName ? `${this.companyName}` : `${this.email}`
        this.customerDisplayTitle = this.companyName ? `${this.companyName}` : this.firstName ? `${this.firstName} ${this.lastName}` : `${this.email}`
        this.isCompany = !!(this.companyName && this.companyName.length > 0)
    }


    snoozeList = new Map()

    async setSnoozeItem(vehicleId, itemId, data) {
        this.snoozeList.set(`${vehicleId}-${itemId}`, data)
        console.log("SET SNOOZE", {vehicleId, itemId, data}, this.snoozeList)
        await this.putSetting({snoozeList: Array.from(this.snoozeList.entries())})

        // update the maintenance history
        let vehicle = this.vehicleByVehicleId[vehicleId]
        if (vehicle) {
            vehicle.updateMaintenanceHistory()
        }
    }

    parseSettings(clientSettings) {
        try {
            this.settings = JSON.parse(clientSettings || "{}")
            this.snoozeList = new Map(this.settings.snoozeList || [])

        } catch (err) {
            this.settings = {}
        }

        this.vehicles.map(v => v.updateMaintenanceHistory())
    }

    getSnoozeItem(vehicleId, itemId) {
        let snoozeItem = this.snoozeList.get(`${vehicleId}-${itemId}`)
        if (!snoozeItem) return false
        snoozeItem.isSnoozed = snoozeItem.expiresAt && moment(snoozeItem.expiresAt).isAfter(moment())
        return snoozeItem
    }

    parseStoreReferences(storeReferences = []) {
        this.primaryStoreReference = false
        this.storeReferences = storeReferences || this.storeReferences || []
        this.promptForPrimaryStoreReference = this.storeReferences.length > 0

        this.storeReferences.forEach(storeReference => {
            if (storeReference.isPrimary) {
                this.customerNumber = storeReference.storeCustomerNumber
                this.primaryStoreReference = storeReference
                this.primaryStoreKey = this.primaryStoreKey || storeReference.storeKey
                this.promptForPrimaryStoreReference = false
            }
        })
        this.storeReferences = _.uniqBy(this.storeReferences, "storeCustomerNumber")
    }

    async putStoreReference(storeReferenceId, data) {
        try {
            let response = await axios(`${config.URL_BASE}api/v1/customers/me/storereferences/${storeReferenceId}`, {
                method: "PUT",
                data,
                headers: {
                    "Authorization": sessionStore.authorization,
                    "IdentityUserId": sessionStore.identityUserId,
                    "Content-Type": "application/json"
                }

            }).catch(error => {
                console.error("Error putting storereferences", error)
                return {}
            })

            this.promptForPrimaryStoreReference = false
            this.parseStoreReferences(response.data)
            // console.log( { response } )
            return response
        } catch (err) {
            console.error("Tyre API failed", err)
            return []
        }
    }


    setVehicles(vehicles) {
        // console.log( "SET VEHICLES", { vehicles }, vehicles.length )
        this.vehicles = vehicles || this.vehicles || []
        this.vehicleByVIN = {}
        this.vehicleByReferenceId = {}
        this.vehicleByVehicleId = {}
        this.vehicleByHash = {}
        this.vehicles
            .forEach(vehicle => {
                //console.log( "ADD VEHICLE", vehicle.vehicleId, vehicle.vehicleReferenceId, vehicle )
                this.vehicleByVIN[vehicle.vehicleIdentificationNumber] = vehicle
                this.vehicleByReferenceId[vehicle.referenceId || vehicle.vehicleReferenceId] = vehicle
                this.vehicleByVehicleId[vehicle.vehicleId] = vehicle
                this.vehicleByHash[vehicle.hash] = vehicle
            })

        let activeVehicles = this.vehicles.filter(v => v.isActive)
        this.primaryVehicle = activeVehicles[0]
    }

    async requestInvoice({
                             vehicleId,
                             invoiceId
                         }) {
        try {
            let response = await axios(`${config.URL_BASE}api/v1/customers/me/vehicles/${vehicleId}/history/invoices/${invoiceId}`, {
                method: "GET",
                headers: {
                    "Authorization": sessionStore.authorization,
                    "IdentityUserId": sessionStore.identityUserId,
                    "Content-Type": "application/json"
                }
            })
                .then(res => {
                    if (!res.status === 200) throw new Error(res.error)
                    return res.data
                })
                .catch(error => {
                    toast.error("Fehler bei der Anfrage.")
                })
        } catch (err) {
            console.error("ERROR", err)
        }
    }

    async putSetting(setting) {
        let newSettings = {}
        try {
            newSettings = Object.assign(this.settings, setting)
            const data = {
                clientSettings: JSON.stringify(this.settings)
            }
            this.settings = newSettings
            const serverSettings = await sessionStore.putClientSettings(data)
            // this.settings = serverSettings
        } catch (err) {
            console.warn("Error setting settings", err)
            return this.settings
        }
    }

    async fetchStoredTiresVIN({
                                  vin,
                                  vehicleId
                              }) {
        try {
            let response = await axios(`${config.URL_BASE}api/v1/customers/tyres/${vin}`, {
                method: "GET",
                headers: {
                    "Authorization": sessionStore.authorization,
                    "IdentityUserId": sessionStore.identityUserId,
                    "Content-Type": "application/json"
                }

            }).catch(error => {
                return {}
            })

            const byVehicle = {}
            response.data.forEach(entry => {
                const {
                    tyres,
                    wheelpackages
                } = entry
                // console.log( { tyres, wheelpackages, entry } )
                byVehicle[vehicleId] = entry
            })

            this.tyreInventoryByVehicleId = byVehicle
            return response
        } catch (err) {
            console.error("Tyre API failed", err)
            return []
        }
    }

    setState(data) {
        Object.assign(this, data)
        this.vehicles = this.vehicles.map((v, index) => new VehicleProfile(v, index))
        if (this.settings.defaultVehicleId) {
            this.vehicles.forEach(v => {
                if (v.vehicleId === this.settings.defaultVehicleId) {
                    v.isDefault = true
                }
            })
        }
    }

    async putDetails(details) {
        this.loading = true
        const response = await sessionStore.putDetails(details)
        console.log("PROFILE", response.data)
        delete response.data.vehicles
        Object.assign(this, response.data) // TODO: extract data, avoid dangerous assign
        this.loading = false
        return response
    }

    postVehicle(vehicle) {
        // console.log( "POST VEHICLE", { vehicle } )
        let postBody = {
            vehicleReferenceId: vehicle.referenceId,
            manufacturerKeyNumber: vehicle.hsn,
            typeKeyNumber: vehicle.tsn,
            manufacturer: vehicle.manufacturerName,
            model: vehicle.className,
            vehicleIdentificationNumber: vehicle.vehicleIdentificationNumber, // vehicleIdentificationNumber: vehicle.vehicleIdentificationNumber,
            licensePlate: vehicle.licensePlate,
            color: vehicle.color,
            colorCode: vehicle.colorCode
        }

        return fetch(`${config.URL_BASE}api/v1/customers/me/vehicles`, {
            method: "POST",
            mode: "cors",

            headers: {
                "Authorization": sessionStore.authorization,
                "IdentityUserId": sessionStore.identityUserId,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(postBody)
        })
            .then(res => res.json())
            .then(async data => {
                const vehicle = new VehicleProfile(data)
                // console.log( "vehicle", vehicle )
                let v = await criteriaStore.findCar(vehicle)
                v = v || {}
                v.data = vehicle
                criteriaStore.carByVehicleId.set(vehicle.vehicleId, v)
                sessionStore.vehicleById.set(vehicle.vehicleId, vehicle)
                this.vehicles.push(vehicle)
                return vehicle
            })
    }

    async putVehicle(id, vehicleUpdate) {
        console.log("PUT VEHICLE", id, vehicleUpdate)
        const isLocal = id.includes("local")
        if (vehicleUpdate.mileage) {
            vehicleUpdate.mileage = Number(vehicleUpdate.mileage)
            console.log("MILEAGE", vehicleUpdate.mileage)
        }
        try {
            let oldVehicleProfile = sessionStore.vehicleById.get(id)
            console.log("putVehicle", {
                id,
                vehicleUpdate,
                oldVehicleProfile
            })

            if (isLocal) return vehicleUpdate

            let res = await fetch(`${config.URL_BASE}api/v1/customers/me/vehicles/${id}`, {
                method: "PUT",
                mode: "cors",
                headers: {
                    "Authorization": sessionStore.authorization,
                    "IdentityUserId": sessionStore.identityUserId,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(vehicleUpdate)
            })
                .then(res => {
                    // console.log( res )
                    if (res.status === 401) {
                        // toast.error(`Kein Zugriff:Bitte melden Sie sich erneut an.`)
                        return false
                    }
                    if (!res.ok) {
                        throw new Error(res)
                    }
                    return res
                })
                .then(res => res.json())
                .then(async data => {
                    let vehicleProfile = oldVehicleProfile || new VehicleProfile(data)
                    Object.assign(vehicleProfile, data)
                    vehicleProfile.updateMaintenanceHistory()
                    sessionStore.vehicleById.set(vehicleProfile.vehicleId, vehicleProfile)
                    this.setVehicles([...sessionStore.vehicleById.values()])
                    return vehicleProfile
                })
            return res
        } catch (err) {

            console.error(err)
            // toast.error(`Fehler beim Schreiben auf das Fahrzeugprofil: Fragen Sie einen Servicemitarbeiter. Ihr Fahrzeug wird von uns direkt in der Warenwirtschaft gepflegt.  `)
            return false
        }
    }

    async patchVehicle(id, patch) {
        const vehicle = this.vehicleByVehicleId[id]
        if (!vehicle) throw new Error(`vehicle with id ${id} not found`)
        return this.putVehicle(vehicle.vehicleId, {...patch})
    }

    deleteVehicle(id, vehicle) {
        return fetch(`${config.URL_BASE}api/v1/customers/me/vehicles/${id}`, {
            method: "DELETE",
            mode: "cors",

            headers: {
                "Authorization": sessionStore.authorization,
                "IdentityUserId": sessionStore.identityUserId,
                "Content-Type": "application/json"
            }
        })
            .then(res => {
                if (res.status === 200) {
                    this.vehicles = this.vehicles
                        .filter(v => v.vehicleId !== id)
                }
            })
    }

    archiveVehicle(id, vehicle = {}, isActive = false) {
        // let firstRegistration = moment.utc( "1900-01-01", "YYYY-MM-DD" ).toISOString()
        return this.putVehicle(id, {isActive})
            .then(vehicleProfile => {
                this.vehicles = this.vehicles.map(v => {
                    if (v.vehicleId === id) {
                        // console.log( "UPDATE", v )
                        return vehicleProfile
                    }
                    return v
                })
                return vehicleProfile
            })
    }

    saveAddress(address) {
        if (!address.type) {
            address.type = "DELIVERY"
        }

        if (address.addressId) {
            return this.putAddress(address.addressId, address)
        } else {
            return this.postAddress(address)
        }

    }

    postAddress(address) {

        return fetch(`${config.URL_BASE}api/v1/customers/me/addresses`, {
            method: "POST",
            mode: "cors",

            headers: {
                "Authorization": sessionStore.authorization,
                "IdentityUserId": sessionStore.identityUserId,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(address)
        })
            .then(res => {
                if (res.status === 200) {
                    return res.json()
                }
                return false
            })
            .then(data => {
                this.addresses.push(data)
            })
    }

    putAddress(id, address) {

        return fetch(`${config.URL_BASE}api/v1/customers/me/addresses/${id}`, {
            method: "PUT",
            mode: "cors",

            headers: {
                "Authorization": sessionStore.authorization,
                "IdentityUserId": sessionStore.identityUserId,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(address)
        })
            .then(async res => {
                if (res.status === 200) {
                    // console.log( "PUT ADDRESS", { address } )
                    const json = await res.json()
                    sessionStore.profile.addresses = sessionStore.profile.addresses.map(add => {
                        if (add.addressId === id) return json
                        return add
                    })
                    console.log({json})
                    sessionStore.whoami()
                }
                if (res.status !== 200) {
                    toast.error("Fehler beim Speichern der Adresse. Bitte versuchen Sie es erneut. ")
                }
            })
    }

    deleteAddress(id, vehicle) {

        this.addresses = this.addresses.filter(a => a.addressId !== id)

        return fetch(`${config.URL_BASE}api/v1/customers/me/addresses/${id}`, {
            method: "DELETE",
            mode: "cors",

            headers: {
                "Authorization": sessionStore.authorization,
                "IdentityUserId": sessionStore.identityUserId,
                "Content-Type": "application/json"
            }
        })
    }

    put(profile) {
        //extendObservable( this, profile )
        Object.assign(this, profile) // TEST
        return fetch(WHOAMI_URL, {
            method: "PUT",
            mode: "cors",

            headers: {
                "Authorization": sessionStore.authorization,
                "IdentityUserId": sessionStore.identityUserId,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(profile)
        })
    }
}
