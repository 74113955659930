import {Availability} from "component/BadgeAvailability"
import ProductCarousel from "component/Product/ProductCarousel"
import ProductOverviewRow from "component/Product/ProductOverviewRow"

import friction from "icons/friction.svg"
import raindrops from "icons/raindrops.svg"
import volumeUp from "icons/volume-up.svg"
import React, {Component} from "react"
import {Link} from "react-router-dom"
import {Button, Container, Grid, Header, Icon, Image, Modal} from "semantic-ui-react"

import SVGIcon from "component/SVGIcon"

export default class Product extends Component {

  render() {
    const { product } = this.props
    if ( !product ) return null
    const boxenstop = tireStore.isBoxenstop( product )
    return (
      <div className="pv5-ns ">
        <Container>
          <Grid stackable doubling>
            <Grid.Row>
              <Grid.Column width={11}>
                <div className="pb3">
                  {boxenstop && <div className="absolute left-0 right-0 mw6 z-5 center">
                    <Image alt="Winter Sale" src={"/shop/img/overlay-winter-sale.png"}/>
                  </div>}
                  <ProductCarousel images={product.images}/>
                </div>

              </Grid.Column>
              <Grid.Column width={5}>
                {/*<Button basic size="tiny" as={Link} to="#technische-daten"><Icon name="chevron right"/> Alle Details</Button>*/}
                {/*<h1 className="b">{product.tyreModelName}</h1>*/}

                <div className="flex flex-col  pb4 ">
                  <Attribute label="Felge" value={<Image alt={"Marke"} src={`/shop/img/brands/${product.automaker && product.automaker.toLowerCase()}.png`}/>}/>
                  <Attribute label="Reifen" value={<div>
                    <Image alt={"Marke"} src={`/shop/img/brands/${product.tyreManufacturer.toLowerCase()}.png`}/>
                    <div className="">{product.tyreModelName}</div>
                  </div>}/>

                  {/*<Attribute label={} value=""/>*/}
                  <Attribute label="Verfügbarkeit" value={<Availability product={product}/>}/>

                  <Attribute label="Bewertungen" value={
                    <div>
                      <Button basic size="tiny" as={Link} to="#bewertungen"><Icon name="chevron right"/> Alle Bewertungen</Button>
                    </div>
                  }/>
                  {/*<Attribute label="Reifendrucksensor" value={product.hasTPMS ? "Ja" : "Nein"}/>*/}
                  {/*<Attribute label="Saison" value={product.season === "summer" ? "Sommer" : "Winter"}/>*/}
                  {/*<Attribute label="EU-Label" value={<div>*/}
                  {/*<span className="pr3 dib"><SVGIcon src={friction}/>{product.tyreFuelEfficiency}</span>*/}
                  {/*<span className="pr3 dib"><SVGIcon src={raindrops}/>{product.tyreWetGrip}</span>*/}
                  {/*<span className=" dib"><SVGIcon src={volumeUp}/>{`${product.tyreRollingNoiseIndB} dB`}</span>*/}
                  {/*</div>}/>*/}
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>


          <div className="flex justify-center pt3 pb3">
            <ProductOverviewRow product={product}/>
          </div>

        </Container>

        {/*<div className="tc pv4 dn db-ns">*/}
        {/*<Button onClick={() => onSelectProduct( product )} to={`/konfiguration/${product.productID}`} primary>Konfigurieren</Button>*/}
        {/*</div>*/}

        {/*REIFENLABEL*/}
        <div className="bg-silver">
          <Container>
            <div className="pv5">
              <Header as="h2">EU-Reifenlabel</Header>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <div className="mw6 center ph1 ph4-ns tc"><Header as="h3">Vorderachse</Header>
                      <Modal closeIcon={<Icon inverted name="close"/>} basic size="fullscreen"
                             trigger={<Image alt={"Reifenlabel"} centered className="pointer" src={product.rearAxle[ 0 ].tyreLabelUrl}/>}>
                        <Modal.Content image>
                          <Image alt={"Reifenlabel"} centered src={product.rearAxle[ 0 ].tyreLabelUrl}/>
                        </Modal.Content>
                      </Modal>
                    </div>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <div className="mw6 center ph1 ph4-ns tc"><Header as="h3">Hinterachse</Header>
                      <Modal closeIcon={<Icon inverted name="close"/>} basic size="fullscreen"
                             trigger={<Image alt={"Reifenlabel"} centered className="pointer" src={product.frontAxle[ 0 ].tyreLabelUrl}/>}>
                        <Modal.Content image>
                          <Image alt={"Reifenlabel"} centered src={product.frontAxle[ 0 ].tyreLabelUrl}/>
                        </Modal.Content>
                      </Modal>
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={16}>
                    <p> Ab dem 01.11.2012 gilt die Europäische Reifen-Kennzeichnungs-Verordnung EU/1222/2009, kurz EU-Reifenlabel genannt. Diese Verordnung gilt für alle neu
                      produzierten
                      Reifen ab KW27/2012.</p>

                    <p> Das EU-Reifenlabel gibt Auskunft über die Kriterien Kraftstoffeffizienz, Nasshaftung und externes Rollgeräusch, und es ist bindend für alle PKW-, Van-
                      und
                      Truck-Reifen (Klassen C1, C2 und C3) innerhalb der EU-Mitgliedstaaten.</p>
                    <p>

                      Ziel des EU-Reifenlabels ist mehr Sicherheit, Umweltschutz und Wirtschaftlichkeit im Straßenverkehr durch die Förderung von kraftstoffsparenden, sicheren
                      und
                      leisen Reifen.
                    </p>

                    <p> Neben dem EU-Reifenlabel sollten Sie aber weiterhin die einschlägigen Reifentests als wichtigen Faktor in Ihre Kaufentscheidung miteinbeziehen, da das
                      neue
                      EU-Reifenlabel nur drei wichtige Kriterien testet. So wird z.B. bei Sommerreifen das Fahrverhalten auf trockener Fahrbahn ebenso wenig bewertet wie der
                      Schneegriff bei Winterreifen.</p>

                    <h3> Die drei Kriterien und Bewertungsklassen im Überblick</h3>

                    <h4>Kraftstoffeffizienz</h4>

                    <p> Die Einstufung der Kraftstoffeffizienz erfolgt in sieben Klassen: von A (größte Effizienz) – G (geringste Effizienz), wobei Klasse D nicht belegt ist.
                      Eine
                      Verbesserung von einer Klasse zur nächst effizienteren Klasse bedeutet ungefähr eine Einsparung von 0,1 l Kraftstoff auf 100 km.
                    </p>

                    <h4>Nasshaftung</h4>

                    <p> Die Nasshaftung ist in die Klassen A (kürzester Bremsweg) – G (längster Bremsweg) unterteilt, wobei die Klassen D und G nicht belegt sind. Der
                      Bremswegunterschied
                      von einer Klasse zur nächst besseren Klasse liegt bei nasser Fahrbahn bei einer Ausgangsgeschwindigkeit von 80 km/h etwa zwischen 1 und 2 Fahrzeuglängen
                      (3-6 m).
                    </p>

                    <h4>
                      Externes Rollgeräusch
                    </h4>

                    <p> Die Geräuschemission eines Reifens wirkt sich auf die Gesamtlautstärke des Fahrzeugs aus und beeinflusst nicht nur den eigenen Fahrkomfort, sondern auch
                      die
                      Geräuschbelastung der Umwelt. Im EU-Reifenlabel wird das externe Rollgeräusch in 3 Kategorien aufgeteilt, in Dezibel (dB) gemessen und mit den
                      europäischen
                      Geräuschemissions-Grenzwerten für externe Reifenrollgeräusche verglichen. Dabei gilt: Je weniger schwarze Streifen desto niedriger ist das externe
                      Rollgeräusch
                      gemessen am EU-Grenzwert.</p>
                  </Grid.Column>
                </Grid.Row>

              </Grid>

            </div>

          </Container>
        </div>


        <div className=" pt6 pb5">

          <Container>

            <div className="pb6">
              <Grid stackable>
                <Grid.Row>
                  <Grid.Column width={4}>
                    <Header as="h2">Das Rad</Header>
                  </Grid.Column>
                  <Grid.Column width={12}>
                    <div className="f4 lh-copy" dangerouslySetInnerHTML={{ __html: product.description }}/>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>

            <Grid stackable>
              <Grid.Row>
                <Grid.Column width={4}>
                  <Header as="h2">Der Reifen</Header>
                </Grid.Column>
                <Grid.Column width={12}>
                  <div className="f4 lh-copy pre-line" dangerouslySetInnerHTML={{ __html: product.tyreDescription }}/>
                </Grid.Column>
              </Grid.Row>
            </Grid>

          </Container>
        </div>

        <div className="bg-silver">
          <Container className="">
            <div className="pv5">

              <Grid stackable name="technische-daten">
                <Grid.Row>
                  <Grid.Column width={4}>
                    <Header as="h2">Vorderachse</Header>
                  </Grid.Column>
                  <Grid.Column width={12}>
                    <Axle data={product.frontAxle[ 0 ]}/>
                  </Grid.Column>
                </Grid.Row>
              </Grid>

              <Grid stackable>
                <Grid.Row>
                  <Grid.Column width={4}>
                    <Header as="h2">Hinterachse</Header>
                  </Grid.Column>
                  <Grid.Column width={12}>
                    <Axle data={product.rearAxle[ 0 ]}/>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
          </Container>
        </div>


        <Container className="pv5">
          <h2>Bewertungen</h2>
        </Container>

        <Container className="pv5">
          <Header as="h2">Bestellinformationen</Header>
          <p> Alle Preisangaben sind online Satz- oder Paketpreise und verstehen sich inklusive der jeweils gültigen MwSt. Alle Preisangaben sind Abholpreise an einer unserer
            Filialen.
          </p>
          <p> Dienstleistungen erfordern eine Terminabstimmung im Live-Terminplaner, trotz guter Planung kann es besonders zu Saisonzeiten ggf. zu Wartezeiten kommen.
          </p>
          <p> Auch die Abholung kann nur nach erfolgreicher Bezahlung und Terminvereinbarung erfolgen.
          </p>
          <p> Ein Artikel, der mit „Auf Lager“ gekennzeichnet ist, ist innerhalb eines Werktages nach verbuchtem Zahlungseingang abholbereit. Wenn der Artikel nicht „Auf Lager“
            gekennzeichnet ist, beträgt die Bereitstellungszeit 3 Werktage ab verbuchtem Zahlungseingang.
          </p>
          <p> Die tatsächliche Bereitstellungszeit kann in Einzelfällen, insbesondere zu Saisonzeiten, abweichen.
          </p>
        </Container>
      </div>
    )
  }
}

const Axle = ( { data } ) => (
  <div>
    <Attribute label="Felgengröße">{data.rimWidth} x {data.rimDiameter} {data.rimOffset}</Attribute>
    <Attribute label="Felgenteilenummer" value={data.rimManufacturerNumber}/>
    <Attribute label="Reifengröße">{data.tyreWidth}/{data.tyreHeight} {data.tyreDiameter}</Attribute>
    <Attribute label="Speed" value={<span>{data.maxKmPerHour} km/h</span>}/>
    <Attribute label="Komplettradteilenummer" value={data.manufacturerNumber}/>
    <Attribute label="EU-Label" value={<div>
      <span className="pr3 dib"><SVGIcon src={friction}/>{data.tyreFuelEfficiency}</span>
      <span className="pr3 dib"><SVGIcon src={raindrops}/>{data.tyreWetGrip}</span>
      <span className=" dib"><SVGIcon src={volumeUp}/>{`${data.tyreRollingNoiseIndB} dB`}</span>
    </div>}/>
    <Attribute label="Reifen EAN">{data.tyreEAN}</Attribute>
  </div>
)

const TyreFeedback = ( { content } ) => (
  <div className="TyreFeedback">
    <div className="black-60 pb1">{content.title}</div>
    <div dangerouslySetInnerHTML={{ __html: content.description }}/>
  </div>
)

const Attribute = ( { label, value, children } ) => (
  <dl className="Attribute pb3 pb4-ns">
    <dt className="f4 b black-70  pb2">{label}</dt>
    <dd><span className="black-70 lh-copy">{value || children}</span></dd>
  </dl>
)
