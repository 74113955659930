import { Button, Card } from "semantic-ui-react"
import currency from "utils/currency"
import React from "react"
import { Link } from "react-router-dom"
// // import locationStore from "store/locationStore";
// import cartStore from "store/cartStore"
import { faCheckCircle, faShoppingCart, faTimesCircle } from "@fortawesome/free-solid-svg-icons"
import { faArrowFromLeft } from "@fortawesome/pro-light-svg-icons"
import Icon from "utils/Icon"
import { getPriceSignal, PriceSignal } from "component/Price/PriceSignalContainer"
import { observer } from "mobx-react"


export const ServiceCardPimcore = observer( ( {
                                                service,
                                                mainProduct,
                                                categoryIndicatorLabel,
                                                baseAmount = 1,
                                                categoryIndicatorLink,
                                                onClick
                                              } ) => {
  const productTo = `/services/${service.mainCategory}/${service.slug}/${service.workPositionNumber}/`
  const active = cartStore.includes( service.productID )
  
  return <Card>
    {categoryIndicatorLabel && (<div className="dn db-l subcategory-indicator absolute top--2 left--1 pl1 w-100">
        {categoryIndicatorLink && (<Link className="black-60 f6 fw4 pl2 truncate" to={categoryIndicatorLabel}>
            <Icon icon={faArrowFromLeft} className="mh1" /> {categoryIndicatorLabel}
          </Link>)}
        {!categoryIndicatorLink && (<div className="black-60 f6 fw4 pl2 truncate">
            <Icon icon={faArrowFromLeft} className="mh1" /> {categoryIndicatorLabel}
          </div>)}
      </div>)}
    <Card.Content>
      <div className="flex flex-col justify-between h-100 ">
        <div className="flex flex-col justify-between">
          <div className={"flex flex-row justify-between"}>
            <div className="f5 f4-ns b black-70 pb1 overflow-hidden ellipsis">
              {service.name}
            </div>
            
            {service.sale_percent && <div className="">
              <div className={"pv1 ph3 f7 bg-red white"}>-{service.sale_percent}%</div>
            </div>}
          </div>
        </div>
        
        <div className="bottom">
          <div>
            <div className="pt3 f7 black-60 ttu">
              für
            </div>
            <div className="fw6 black-70 ">
              {service.brandName}
            </div>
          </div>
          <div className="flex flex-row justify-between items-end">
            <div className="pt3 f7 black-60 ttu">
              
              {service.brand === "NACARMOS" ? <div>
                {/*{(service.priceMin) && <div>*/}
                {/*    <div>Preis</div>*/}
                {/*    <div className={"f5 f4-ns b"}>{currency( service.priceMin )} – {currency( service.priceMax )}</div>*/}
                {/*</div>}*/}
              </div> : <div>
                {!service.srp && <div className="">Preis</div>}
                {service.useDynamicBaseAmount && baseAmount != 1 && <div>
                  {baseAmount} Stück
                </div>}
                {service.srp && <React.Fragment>
                  <div className="">UVP <span className="red strike">{currency( service.srp )}</span></div>
                </React.Fragment>}
                <span className="f5 f4-ns fw6 black-70 ">
                            {currency( baseAmount * (service.priceGrossInEur || service.price || service.priceGross) )}
                        </span>
              </div>}
            </div>
            
            <PriceSignal level={getPriceSignal( service.priceFlag )} />
          
          </div>
          
          
          {/*DETAILS ZEIGEN*/}
          <div className="flex flex-row justify-between flex-wrap pt3">
            <React.Fragment>
              <div className="w-100  pb1 tc">
                <Button
                  size={"small"}
                  basic
                  fluid
                  as={Link}
                  to={locationStore.getPathname( productTo )}
                >
                  Details
                </Button>
              </div>
              
              {/*<div className="w-100  pb1 tc">*/}
              {/*    <Button size={"small"} basic fluid as={QueryLink} to={{search: {...query, details: service.productID}}}> Details </Button>*/}
              {/*</div>*/}
              
              {/*    CTA - Expresstermin*/}
              <div className="w-100">
                
                {locationStore.isOnlineStore && <Button size={"small"}
                                                        fluid
                                                        primary
                                                        as={Link} to={`?showStoreFinder=true&referrer=${productTo}`}>Filialpreis
                  anzeigen</Button>}
                {!locationStore.isOnlineStore && <Button
                  size={"small"}
                  fluid
                  basic={active}
                  primary={!active}
                  className={"hide-child"}
                  onClick={() => {
                    if ( mainProduct ) {
                      cartStore.addAssociatedProductToProduct( mainProduct, service, baseAmount )
                    }
                    else {
                      cartStore.toggleAssociatedProduct( mainProduct, service )
                    }
                    
                    onClick && onClick( service )
                  }}>
                  <div className=" flex flex-row items-center justify-center ">
                    {!active && <div>In den Warenkorb</div>}
                    {active && <div>
                      <div className="animate fadeInLeft relative w-100 ">
                        <div className={"hide-on-hover"}><Icon icon={faCheckCircle} /> <Icon icon={faShoppingCart} />
                        </div>
                        <div className="child absolute w-100 left-0 right-0 top-0 bottom-0">
                          <Icon icon={faTimesCircle} /></div>
                      </div>
                    
                    </div>}
                  </div>
                </Button>}
              </div>
            </React.Fragment>
          </div>
        </div>
      </div>
    </Card.Content>
  </Card>
} )