import { observer } from "mobx-react"
import React from "react"
import { NavLink } from "react-router-dom"

// import locationStore from "store/locationStore"

class HomeLink extends React.Component {
  
  
  render() {
    const { activeStoreId, activeManufacturerKey, activeVehicleReferenceId } = locationStore
    const { children, className = "", ...rest } = this.props
    const brandComponent = activeManufacturerKey ? `/${activeManufacturerKey}` : ""
    const vehicleComponent = activeVehicleReferenceId ? `/${activeVehicleReferenceId}` : ""
    // let url = activeStoreId ? `/standort/${activeStoreId}${brandComponent}${vehicleComponent}/` : "/"
    let url = activeStoreId ? `/werkstatt/` : "/werkstatt/"
    return <NavLink className={`HomeLink ${className}`} to={url} {...rest}>{children}</NavLink>
  }
}

export default observer( HomeLink )
