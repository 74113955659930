import {action, computed, observable, makeObservable} from "mobx"
import config from "./store.config"
import Manufacturer from "./Manufacturer"
import _ from "lodash-es"
import queryString from "query-string"
import fuelName from "../utils/fuelName"

class VehicleModel {
    constructor(data) {
        Object.assign(this, data)
        this.showPlaceholderImage = true
    }
}

export default class CriteriaStore {
    manufacturers = []
    manufacturerByKey = new Map()
    manufacturersByRanking = new Map()
    primaryManufacturers = []
    secondaryManufacturers = []

    carModelByKey = {}
    loading = false
    carById = new Map()
    carByVehicleId = new Map()
    carByVIN = new Map()
    carByHSNTSN = new Map()
    carsByClass = new Map()
    searchIndexByClass = new Map()
    
    brandMapping = []
    brandMappingById = {}
    
    _getCarPromise = {}

    constructor() {
        makeObservable(this, {
            manufacturers: observable,
            manufacturerByKey: observable,
            manufacturersByRanking: observable,
            primaryManufacturers: observable,
            secondaryManufacturers: observable,
            carModelByKey: observable,
            loading: observable,
            carById: observable,
            carByVehicleId: observable,
            carByVIN: observable,
            carByHSNTSN: observable,
            carsByClass: observable,
            searchIndexByClass: observable,
            brandMapping: observable,
            brandMappingById: observable,
            getCar: action,
            getBrandDetails: action,
            getCarByVin: action,
            findCar: action,
            getCarClasses: action,
            getCarsByClass: action,
            getCarByCode: action,
            fetchManufacturer: action,
            loadManufacturers: action
        })
        
        this.getBrandMapping()
    }
    
    async getBrandMapping(){
        // const mapping = await fetch(`${process.env.REACT_APP_PIMCORE_URL}/api/brand-mapping/`,
        const mapping = await fetch(`/shop/brand-mapping.json`,
          {
              mode: "cors",
              headers: {
                  "Content-Type": "application/json"
              },
          })
          .then( res => res.json())
        this.brandMapping = mapping
        this.brandMappingById = {}
        
        mapping.forEach( brandMap => {
            this.brandMappingById[brandMap.to] = brandMap
        })
    }

    getVehicleByProfile(vehicleProfile) {
        if (!vehicleProfile) return null
        if (!vehicleProfile.manufacturerKeyNumber) return this.carByVehicleId.get(vehicleProfile.vehicleId)
        if (vehicleProfile.typeKeyNumber) return this.carByVehicleId.get(vehicleProfile.vehicleId)
        const vehicleHash = `${vehicleProfile.manufacturerKeyNumber}-${vehicleProfile.typeKeyNumber}`.toUpperCase()
        let vehicle = this.carByHSNTSN.get(vehicleHash) || this.carByVehicleId.get(vehicleProfile.vehicleId)
        return vehicle
    }

    getCar(carId) {
        if (!carId) return false
        if (this._getCarPromise[`${carId}`]) return this._getCarPromise[`${carId}`]
        let promise = fetch(`${config.API_BASE}/criterias/vehicles/models/${carId}`)
            .then(res => res.json())
            .then(data => {
                this.carById.set(carId, data)
                return data
            })
            .catch(err => {
                return {
                    error: "No Car"
                }
            })
        this._getCarPromise[`${carId}`] = promise
        return promise
    }

    getBrandDetails(manufacturerKey) {
        let key = manufacturerKey.toLowerCase()
        return fetch(`/content/brand/${key}.txt`)
            .then(res => res.text())
            .then(res => {
                return res
            })
    }

    async getCarByVin(vin) {
        function isValidVIN(vin) {
            const cleanVIN = vin.replace(/[^a-z0-9]/gi, '')
            if (!cleanVIN) return false
            if (cleanVIN.length !== 17) return false
            return true
        }

        if (!vin) return false
        if (!isValidVIN(vin)) return false
        return fetch(`${config.API_BASE}/criterias/vehicles/models/vin?vin=${vin}`)
            .then(res => res.json())
            .then(data => {
                if (data && data.referenceId) {
                    data.vehicleIdentificationNumber = vin
                    this.carById.set(data.referenceId, data)
                    data.vehicleId && this.carByVehicleId.set(data.vehicleId, data)
                    this.carByVIN.set(vin, data)
                    return data
                }
                return false
            })
            .catch(err => {
                // // --> console.log( { err } )
                return false
            })
    }


    guessManufacturer = (manufacturerKeyOrName) => {
        if (!manufacturerKeyOrName) return false
        if (typeof manufacturerKeyOrName !== "string") return false
        const uppercased = manufacturerKeyOrName.toUpperCase()
        const manufacturer = this.manufacturerByKey[uppercased]
        if (!manufacturer) {
            //try to get by displayName
            const byDisplayName = this.manufacturers.find(manufacturer => manufacturer.displayName.toUpperCase() === uppercased)
            return byDisplayName || false
        }
        return manufacturer
    }

    /**
     * Find a car by  vehicleIdentificationNumber or  vehicleReferenceId
     * @param data
     * @param useCache
     * @param vehicleProfile
     * @returns {Promise<*>}
     */
    findCar = async (data, {useCache = true} = {}, vehicleProfile) => {
        let {
            vehicleId,
            vehicleIdentificationNumber,
            typeKeyNumber,
            versionKeyNumber,
            manufacturerKeyNumber,
            vehicleReferenceId
        } = data
        // console.log({ vehicleId, vehicleIdentificationNumber, typeKeyNumber, versionKeyNumber, manufacturerKeyNumber, vehicleReferenceId })
        if (!vehicleIdentificationNumber && !vehicleReferenceId) return false

        const handleVehicle = (vehicleSource) => {
            const vehicle = new VehicleModel(vehicleSource)
            data.vehicleReferenceId = data.vehicleReferenceId || vehicle.referenceId
            Object.assign(vehicle, data)
            const vehicleHash = `${vehicle.hsn}-${vehicle.tsn}`.toUpperCase()
            if (typeof data.setVehicle === "function") {
                data.setVehicleReferenceId(vehicle.referenceId)
            }
            if (vehicle) {
                vehicle.data = data
                vehicle.src = data
                vehicleReferenceId && this.carById.set(vehicleReferenceId, vehicle)
                vehicleReferenceId && data.vehicleId && this.carByVehicleId.set(vehicleReferenceId, vehicle)
                vehicleIdentificationNumber && this.carByVIN.set(vehicleIdentificationNumber, vehicle)
                vehicleId && this.carByVehicleId.set(vehicleId, vehicle)
                this.carByHSNTSN.set(vehicleHash, vehicle)
            }
        }

        // vehicleId = data.vehicleId = data.vehicleId || `temp-id-${Math.random().toString(32)}`
        // // --> console.log( "FIND CAR", {vehicleId, vehicleIdentificationNumber, vehicleReferenceId}, data )

        let cache = vehicleReferenceId && this.carById.get(vehicleReferenceId)
        if (cache && useCache) {
            handleVehicle(cache)
            return cache
        }
        let vehicle


        // FIRST REFERENCE ID
        if (!vehicle && vehicleReferenceId) {
            vehicle = await this.getCar(vehicleReferenceId)
            console.log("vehicle:vehicleReferenceId", vehicleReferenceId, vehicle)
        }

        // SECOND TSN/HSN/VSN
        if (!vehicle && manufacturerKeyNumber && typeKeyNumber) {
            vehicle = await this.getCarByCode({
                hsn: manufacturerKeyNumber,
                tsn: typeKeyNumber,
                vsn: versionKeyNumber
            },)

            console.log("vehicle:hsn", vehicleReferenceId, vehicle)
        }

        // THIRD VIN
        if (!vehicle && vehicleIdentificationNumber) {
            vehicle = await this.getCarByVin(vehicleIdentificationNumber)
        }

        if (!vehicle) {
            console.warn("NOT FOUND", data)
        }
        handleVehicle(vehicle, vehicleProfile)

        if (vehicleProfile) {
            vehicleProfile.setVehicle(vehicle)
        }
        return vehicle || false
    }


    getCarClasses({manufacturer} = {}) {
        if (!manufacturer) return new Error("No manufacturer set")

        return fetch(`${config.API_BASE}/criterias/vehicles/classes/manufacturer?manufacturer=${manufacturer}&excluderedundant=false`)
            .then(res => res.json())
            .then(data => {
                // // --> console.log( "carClasses", { data } )
                let byName = new Map()
                data.forEach(carClass => {
                    byName.set(carClass.name, carClass)
                })
                return {
                    byName,
                    carClasses: data
                }
            })
    }

    getCarsByClass({
                       manufacturer,
                       vehicleClass
                   }) {
        return fetch(`${config.API_BASE}/criterias/vehicles/models/vehicleClass?vehicleclass=${vehicleClass}&manufacturer=${manufacturer}&excludeRedundant=false`)
            .then(res => res.json())
            .then(data => {
                let group = []
                let index = []
                if (data) {
                    data.forEach(car => {
                        const yearString = `(${car.productionYearFrom}${car.productionYearUntil ? " - " + car.productionYearUntil : ""})`
                        car.searchString = car.version + " " + car.horsepower + " PS " + yearString
                        car.title = car.version.slice(0, 10) + " " + car.horsepower + " PS " + `(${car.productionYearFrom})`
                        car.fuelName = `${fuelName[car.fuelType] || car.fuelType}`
                        this.carById.set(car.referenceId, car)
                        let search = [`${car.version} ${car.horsepower} PS`, car.referenceId]
                        index.push(search)
                        group.push(car)
                    })
                    this.carsByClass.set(vehicleClass, group)
                    this.searchIndexByClass.set(vehicleClass, index)
                } else {
                    return
                }
                return data
            })
    }

    getCarByCode({
                     hsn,
                     tsn,
                     vsn = null
                 }) {
        const params = {
            hsn,
            tsn,
            vsn
        }
        if (params.vsn === "" || vsn === null) delete params.vsn
        const queryParams = queryString.stringify(params)
        return fetch(`${config.API_BASE}/criterias/vehicles/models/vehicleCode?${queryParams}`)
            .then(res => res.json())
            .then(results => results[0])
            .then(data => {
                // // --> console.log( "car", { data } )
                if (data) {
                    this.carById.set(data.referenceId, data)
                } else {
                    return
                }
                return data
            })
    }

    async fetchManufacturer(manufacturerKey) {
        let manufacturer = this.manufacturerByKey.get(`${manufacturerKey}`.toUpperCase())
        if (manufacturer) {
            let res = await manufacturer.prepare()
            this.manufacturerByKey = this.manufacturerByKey.set(manufacturerKey, res)
            return res
        }
    }

    loadManufacturers() {
        if (this._promiseManufacturers) return this._promiseManufacturers
        const promise = fetch(`${config.API_BASE}/criterias/vehicles/manufacturers`)
            .then(res => {
                if (res.status !== 200) {
                    console.error("ERROR IN MANUFACTURERS")

                }
                return res
            })
            .then(response => response.json())
            .then(data => {
                data.push({
                    name: "TRIUMPH MOTORRÄDER",
                    ranking: 0
                })

                data.push({
                    name: "SCANIA",
                    ranking: 0
                })

                data.push({
                    name: "DFSK",
                    ranking: 0
                })

                data.push({
                    name: "FUSO",
                    ranking: 0
                })


                return data
            })
            .then(result => {
                const manufacturersByRanking = new Map()
                result = result.sort((a, b) => {
                    if (a.name > b.name) return 1
                    if (a.name < b.name) return -1
                    return 0
                })
                let primaryManufacturers = []
                let secondaryManufacturers = []

                let allBrand = new Manufacturer({
                    name: "NACARMOS",
                    displayName: "Alle Marken"
                })
                this.manufacturerByKey.set(allBrand.name, allBrand)
                this.manufacturerByKey.set("NACARMOS", allBrand)
                let manufacturers = result.map(data => {
                    let manufacturer = new Manufacturer(data)
                    let group = manufacturersByRanking.get(`${manufacturer.ranking}`) || []
                    if (manufacturer.ranking > 0) {
                        primaryManufacturers.push(manufacturer)
                    } else {
                        secondaryManufacturers.push(manufacturer)
                    }
                    group.push(manufacturer)
                    manufacturersByRanking.set(`${manufacturer.ranking}`, group)
                    return manufacturer
                })

                this.manufacturers = _.uniqBy(manufacturers, "key")

                this.manufacturers.forEach(man => {
                    this.manufacturerByKey.set(man.name, man)
                })
                this.manufacturersByRanking = manufacturersByRanking

                this.primaryManufacturers = primaryManufacturers
                this.secondaryManufacturers = secondaryManufacturers
                this._promiseManufacturers = false
                return this.manufacturers
            })

        this._promiseManufacturers = promise
        return promise
    }

    filterManufacturer(brands) {
        let uppercasedBrands = brands.map((brand) => {
            return brand.toUpperCase()
        })

        const hit = this.manufacturers.filter(manufacturer => {
            return uppercasedBrands.includes(manufacturer.name)
        })

        return hit
    }

    filterManufacturerExclude(brands) {
        let uppercasedBrands = brands.map((brand) => {
            return brand.toUpperCase()
        })

        const hit = this.manufacturers.filter(manufacturer => {
            return !uppercasedBrands.includes(manufacturer.name)
        })

        return hit
    }
}
