const COMPLETEABLE_OFFER_STATUS = ['reminder', 'sent']
const CUSTOMER_VIEWABLE_STATUS = ['sent', 'reminder', 'ordered']

const offerStatusMap = {
    new: {
        label: 'Noch nicht versendet',
        color: 'bg-neutral-500'
    },
    sent: {
        label: 'Versendet',
        color: 'bg-amber-500'
    },
    reminder: {
        label: 'E-Mail-Erinnerung',
        color: 'bg-red-500'
    },
    done: {
        label: 'Archiviert',
        color: 'bg-green-500'
    },
    ordered: {
        label: 'Bestellt',
        color: 'bg-green-500'
    },
}

export {
    offerStatusMap,
    COMPLETEABLE_OFFER_STATUS,
    CUSTOMER_VIEWABLE_STATUS
}

export default function OfferStatus({ status }) {
    const selectedStatus = offerStatusMap[status]

    return <p
        className={`rounded text-xs text-white uppercase font-bold inline-block p-2 leading-none ${selectedStatus?.color}`}>
        {selectedStatus?.label}
    </p>
}
