import { action, observable, makeObservable } from "mobx";
import config from "./store.config"
import _ from "lodash-es";
// import locationStore from "./locationStore";

export default class Manufacturer {

    key = false
    index = [];
    models = [];
    classIndex = [];
    byClass = new Map();
    byClassEntries = [];
    carClasses = [];

    carOptionsByClass = new Map();
    
    displayName = "OK"

    constructor( data ) {
        makeObservable(this, {
            index: observable,
            models: observable,
            classIndex: observable,
            byClass: observable,
            byClassEntries: observable,
            carClasses: observable,
            carOptionsByClass: observable,
            prepare: action,
            getCarClasses: action,
            getIndex: action,
            getClassIndex: action,
            getClass: action,
            getModels: action
        });

        if ( !data || !data.name ) {
            console.error( "NAME NOT SET", data )
        }


        const {name, ranking, displayName} = data
        this.id = name
        this.key = name
        this.name = name
        this.displayName = displayName || name
        this.ranking = ranking
        // Object.assign( this, data )
        this.img = `/img/stock/manufacturer-2018/${this.key}.png`
    }

    async prepare() {
        if ( this._isPreparing ) return this
        this._isPreparing = true
        await this.getIndex()
        await this.getCarClasses( {manufacturer: this.key} )
        return this
    }

    getCarClasses({manufacturer}) {
        return fetch( `${config.API_BASE}/criterias/vehicles/classes/manufacturer?manufacturer=${manufacturer}&excludeRedundant=false` )
            .then( res => res.json() )
            .then( data => {
                // // --> console.log( "carClasses", { data } )
                this.carClasses = data.map( carGroup => {
                    carGroup.primaryGroup = carGroup.groups && carGroup.groups[0]
                    carGroup.isPerformance = carGroup.isPerformance || carGroup.name.indexOf( "AMG" ) >= 0 // TODO get value for other cars
                    return carGroup
                } )
                return this.carClasses
            } )
    }

    async getIndex() {
        return true
        // return fetch( `${config.API_BASE}/criterias/vehicles/models/index/manufacturer?manufacturer=${this.name}&excludeRedundant=false` )
        //     .then( res => res.json() )
        //     .then( index => {
        //         this.index = index
        //         let byClass = new Map()
        //         index.forEach( data => {
        //             let [id, category, img, isLegacy, hasRims] = data
        //             let car = {id, category, img, isLegacy, hasRims}
        //             let group = byClass.get( category ) || []
        //             group.push( car )
        //             byClass.set( category, group )
        //         } )
        //         this.byClass = byClass
        //         this.byClassEntries = [...this.byClass.entries()]
        //         // // // --> console.log( "index", this.id, index, this.byClass )
        //         // // --> console.log( { index }, `received index: ${index.length} manufacturers` )
        //     } )
    }

    getClassIndex() {
        return fetch( `${config.API_BASE}/criterias/vehicles/classes/manufacturer?manufacturer=${this.name}&excludeRedundant=false` )
            .then( res => res.json() )
            .then( classIndex => {
                this.classIndex = classIndex
                // // --> console.log( "classIndex", this.id, classIndex )
            } )
    }

    getClass(id) {
        return fetch( `${config.API_BASE}/criterias/vehicles/models/vehicleClass?vehicleclass=${id}&manufacturer=${this.name}&excludeRedundant=false` )
            .then( res => res.json() )
            .then( byClass => {
                this.byClass.set( id, byClass )
                //         this.byClass = byClass
                //         this.byClassEntries = [...this.byClass.entries()]
                return byClass
            } )
    }

    getModels() {
        return fetch( `${config.API_BASE}/criterias/vehicles/models/compact/manufacturer?manufacturer=${this.name}&excludeRedundant=false` )
            .then( res => res.json() )
            .then( models => {
                this.models = models
                return models
            } )

    }
}
