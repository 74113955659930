import React from "react"

const ImageWithFallback = ( { src, fallbackSrc=null, fallbackElement = null, ...other } ) => {
    const [isHidden, hide] = React.useState( false )
    const Fallback = fallbackElement
    let element
    let times = 0
    const changeSrc = newSrc => {
        if ( newSrc === null ) hide( true )
        // console.log( `Image Not Found: ${src}` )
        times++
        if ( times > 2 ) return
        element.src = newSrc
    }
    if ( !src ) src = fallbackSrc
    if ( !src && !fallbackSrc ) return Fallback
    if ( isHidden && Fallback ) return Fallback
    if ( isHidden ) return null
    
    return (
        <img src={src}
             alt="Reifenabbildung"
             onError={() => changeSrc( fallbackSrc )}
             ref={el => element = el}
             {...other} />
    )
}

export default ImageWithFallback
