import Product, {searchBehaviour} from "model/Product"
// // import locationStore from "store/locationStore";
import {slugify} from "../utils/slugify";
import currency from "../utils/currency";

export function stripHtml(html) {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
}

export const DEFAULT_AVAILABLE_DAYS = [1, 2, 3, 4, 5, 6]


export class ServiceProductModel extends Product {

    // removes maintenance tasks on order
    taskItem = ""

    useDynamicBaseAmount = false
    mainCategory = false
    quantity = 0
    mainCategoryName = ""
    availableDaysByStore = {}
    priceByStore = {}
    priceFlagByStore = {}
    isAvailable = true
    SRPByStore = {}
    isSearchable = true
    availableDays = DEFAULT_AVAILABLE_DAYS

    replacementProductId = null
    forceReplacement = false

    constructor(data) {
        data.pricing = data.pricing || []
        super(data)
        Object.assign(this, data)
        this._type = "ServiceProductModel"
        this.searchTags = this.tags
        this.productIDOriginal = this.productID
        this.productIDAddon = false
        this.priceByStore = {}
        this.priceMin = Infinity
        this.priceMax = 0

        // responds to tires in cart (4 tires = 4 of this service)
        this.useDynamicBaseAmount = this.workPositionNumber === "WEBP40-0018" || this.workPositionNumber === "WEBP40-0019"

        this.srp = false
        if (this.pricing.length === 0) {
            this.isAvailable = false
        }

        if (!Array.isArray(this.pricing)) {
            console.warn("NO PRICING FOR SERVICE PRODUCT", this)
            this.pricing = []
        }

        this.pricing.forEach(storePriceData => {
            this.priceMin = Math.min(this.priceMin, storePriceData.price)
            this.priceMax = Math.max(this.priceMax, storePriceData.price)
            this.availableDaysByStore[storePriceData.location] = storePriceData.availableDays
            this.priceByStore[storePriceData.location] = storePriceData.price
            this.priceFlagByStore[storePriceData.location] = storePriceData.priceFlag
            this.SRPByStore[storePriceData.location] = storePriceData.srp || storePriceData.originalPrice || false
        })

        if (this.priceMin === Infinity) {
            this.priceMin = false
        }

        this.updateLocation(locationStore.activeStoreId)


        this.availabilityColor = "bg-green-500"
        this.availabilityText = "Verfügbar"
        if (!this.isAvailable) {
            this.availabilityColor = "bg-red-500"
            this.availabilityText = "Nicht verfügbar"
            if (this.isAvailableSomewhere) {
                this.availabilityColor = "bg-blue-500"
                this.availabilityText = "An einem anderen Standort verfügbar"
            }
        }


        this.brandName = this.brand
        if (this.brand === "NACARMOS") {
            this.brandName = "Alle Marken"
        }

        this.tag = {}
        this.tags = []
        this.categories.forEach(({mainCategory, subCategory}) => {
            if (mainCategory) {
                this.mainCategory = mainCategory.id
                this.mainCategoryName = mainCategory.name
                this.tag[mainCategory.id] = mainCategory.name
                this.tags.push(mainCategory.id)
            }

            if (subCategory) {
                this.subCategory[subCategory.id] = subCategory.name
                this.tag[subCategory.id] = subCategory.name
                this.tags.push(subCategory.id)
                this.subCategoryName = subCategory.name
                this.subCategoryId = subCategory.id
            }

        })

        this.vehicleSeriesTag = ""
        if (this.vehicleSeries) {
            this.vehicleSeriesTag = this.vehicleSeries.join("-")
        }


        if (!this.mainCategory) {
            this.mainCategory = "Unbekannt"
            this.mainCategoryName = "Unbekannt"
            this.tag["Unbekannt"] = "Unbekannt"
            this.tags.push("Unbekannt")
        }

        if (!this.subCategoryId) {
            this.subCategory = "Unbekannt"
            this.subCategoryName = "Unbekannt"
            this.subCategoryId = "Unbekannt"
        }

        // BOXENSTOPP
        if (this.mainCategory === "H55" && locationStore.activeStore && locationStore.activeStore.campaigns && locationStore.activeStore.campaigns.length > 0) {
            this.fixedDeliveryDates = locationStore.activeStore.campaigns.map(e => e.from)
        }

        this.categoryHash = this.tags.join("-")
        this.description = this.text || ""
        this.text = this.descriptionText = stripHtml(this.description)
        this.categoryName = this.mainCategoryName
        this.title = this.name
        this.slug = slugify(this.name)
        this.href = `/services/${this.mainCategory}/${this.slug}/${this.workPositionNumber}/`

        this.searchBehaviour = searchBehaviour.TO_CART // add the product to cart
        this.isSearchable = !!this.mainCategory
    }

    updateLocation(activeStoreId) {
        this.priceGrossInEur = this.priceByStore[activeStoreId]
        this.price = this.priceByStore[activeStoreId]
        this.priceTotal = this.priceByStore[activeStoreId]
        this.priceFlag = this.priceFlagByStore[activeStoreId]
        this.availableDays = this.availableDaysByStore[activeStoreId] || DEFAULT_AVAILABLE_DAYS
        this.srp = this.SRPByStore[activeStoreId]
        this.recommendedRetailPriceGrossInEur = this.srp
        this.hasDiscount = this.price < this.srp
        if (this.srp) {
            this.sale_percent = Math.abs(Math.round((1 - (this.price / this.srp)) * 100))
            this.sale_absolute = Math.abs(this.srp - this.price)
        }

        this.priceText = currency(this.price, 2, this.priceFlag === "free" ? "kostenlos" : "noch offen")
    }
}
