import React from "react"
import ImageWithFallback from "../ImageWithFallback"

/*
 How to get further image sizes with Image-Magick (let's say 256x256px):
 # create temporary folder
 mkdir /tmp/256x256px
 # copy all the large images to temporary folder
 cp *1080x600px*.png /tmp/256x256px/*
 # go to temporary folder
 cd /tmp/256x256px
 # scale down with fixed aspect ratio, then extend to the size we want
 magick mogrify *.png -resize '256x256' -gravity center -background Transparent -extent '256x256' *.png
 # replace the px size each filename with new size
 npx renamer --find 1080x600px --replace 256x256px *
 # cp/mv the resulting files back
 cp *.png /path/to/original/images/*
 */

const brandMapping = {
    "MERCEDES-BENZ": "mercedes",
    "JAGUAR": "jaguar",
    "TRIUMPH MOTORRÄDER": "triumph",
    "LAND ROVER": "landrover",
    "MG": "mg",
    "BMW": "bmw",
    "MASERATI": "maserati",
    "VOLVO": "volvo",
    "AUDI": "audi",
    "SMART": "smart",
    "VOLKSWAGEN": "vw",
    "FORD": "ford",
    "OPEL": "opel",
    "MAN": "man",
    "HYUNDAI": "hyundai",
    "SCANIA": "scania",
    "AD_AUTODIENST": "ad_autodienst",
    "QUALITY": "ad_autodienst",
    "DFSK": "DFSK",
    "FUSO": "fuso",
    "PLACEHOLDER": "placeholder"
}

const fixedLogos = {
    "ATE": "/shop/img/brands/ate.png",
    "BOSCH": "/shop/img/brands/bosch.png",
    "CONTINENTAL": "/shop/img/brands/continental.png",
    "MANN-FILTER": "/shop/img/brands/MANNFILTERLOGO.png",
    "FEBI BILSTEIN": "/shop/img/brands/febi-bilstein.png",
    "MANN": "/shop/img/brands/mann-hummel.png",
    "NGK": "/shop/img/brands/ngk.png",
    "TRW": "/shop/img/brands/trw.png"
}

const knownFileSizes = {
    "xl-wide": "1080x600px",
    "md-sq": "256x256px"
}

const knownColors = {
    "": "",
    "petrol": "-petrol",
    "offwhite": "-offwhite"
}

const build = (brand, color = "", size = "xl-wide") => {
    if (!brand) {
        return <div>BRAND: {brand}</div>
    }
    let brandUC = brand.toUpperCase()
    if (!(size in knownFileSizes)) {
        console.warn(`unknown size '${size}`)
        size = "xl-wide"
    }

    if (!(brandUC in brandMapping)) {
        brandUC = "PLACEHOLDER"
    }

    if (!(color in knownColors)) {
        console.warn(`unknown color '${color}`)
        color = ""
    }

    if (fixedLogos[brand]) {
        return fixedLogos[brand]
    }

    if (brandUC === "PLACEHOLDER") return false
    return `/shop/img/nagel-marken/nagel-markenlogos-${knownFileSizes[size]}-${brandMapping[brandUC]}${knownColors[color]}.png`
}


const BrandLogo = ({
                       title,
                       width = null,
                       height = null,
                       inverted = false,
                       brand,
                       color = "",
                       size = "xl-wide",
                       className = "flex flex-row items-center justify-center"
                   }) => {
    const src = build(brand, color, size)
    if (brand === "NACARMOS") return <div>
        <img width={154 / 3} className={"filter invert grayscale opacity-50"} src="/shop/img/ncg-sign.png" alt=""/>
    </div>
    if (!src) {
        return <div className={`BrandLogo ${className}`} title={title || brand}>
            <div className={`text-center flex flex-col items-center justify-center font-bold ${inverted ? "filter-inverted" : ""}`}
                 style={{
                     width,
                     height: height || (width/2),
                 }}>{brand}</div>
        </div>
    }
    return <div className={`BrandLogo ${className}`} title={title || brand}>
        <ImageWithFallback fallbackElement={<div className={"f3-ns f4 black-50 b"}>{brand}</div>}
                           alt={brand}
                           className={`${inverted ? "filter-inverted" : ""}`}
                           width={width}
                           height={height}
                           src={src}/>
    </div>
}


export default BrandLogo
