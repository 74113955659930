import { action, computed, observable, makeObservable } from "mobx"
import config from "store/store.config"
import ProductModel from "model/Product"
// import cartStore from "store/cartStore"
import moment from "moment"
import queryString from "query-string"
// import locationStore from "store/locationStore"
import labourServiceStore from "page/LabourTimeServicePage/labourServiceStore"
import shop from "store/shop"
import ServiceRecommendationModel from "./ServiceRecommendationModel"

const queryState = queryString.parse( document.location.search )

class ServicePosition {
    constructor( service ) {
        Object.assign( this, service )
        this.variants = this.variants
            .map( variant => {
                return new ServiceVariant( variant, service )
            } )
    }
}

class ServiceVariant extends ProductModel {
    constructor( subService, service ) {
        super(subService)
       Object.assign( this, subService )
        this.brand = findBrand( subService )
        this.remark = service.remark
        this.service = service
        this.active = false
        this.defaultActive = false
        this.oil = findOil( subService )
        this.isSpecialPrice = subService.priceFlag === "specialprice"
        // ALLE MAINTENANCE SERVICES SIND STANDARD AKTIV
        if ( service.isMaintenanceService ) {
            this.active = true
            this.defaultActive = true
        }
    }
}

class VehicleServiceStore {
    loading = false
    
    registrationDate = null
    mileage = 0
    
    status = "uninitialized"
    serviceById = {}
    error = false
    
    qualityLevels = ["Recommended", "Quality", "Economy"]
    QualityTranslations = {
        ALL        : "Alle Qualitätsklassen",
        Recommended: "Das Original",
        Quality    : "Alternative",
        Economy    : "Economy"
    }
    
    constructor() {
        makeObservable( this, {
            loading                   : observable,
            error                     : observable,
            noResult                  : computed,
            setVehicleConfiguration   : action,
            parseConfigurations       : action,
            setVehicleReferenceId     : action,
            addToCart                 : action,
            loadServicesFromContext   : action,
            reset                     : action,
            loadGearbox               : action,
            loadServices              : action
        } )
    }
    
    get noResult() {
        return !this.loading && this.serviceRecommendations && this.serviceRecommendations.length === 0
    }
    
    qualityName = ( quality ) => this.QualityTranslations[ quality ] || quality
    
    async setVehicleConfiguration( configurationId ) {
        this.activeConfigurationId = Number( configurationId )
        this.activeConfiguration = this.vehicleConfigurationById[ Number( configurationId ) ]
        this.activeConfigurationOptions = this.activeConfiguration?.includedOptions || []
        
        if ( !this.activeConfiguration ) {
            console.log( `Reset configuration ${configurationId}` )
            return false
        }
        // GET OPTION WITH PLUS PAKET
        let optionUpsellKey = "mit Plus-Paket"
        let upsellConfiguration = false
        let description = ""
        let isPrimary = false
        
        // TODO: Upsell mit helpText
        const hasUpsell = this.activeConfigurationOptions && this.activeConfigurationOptions.some( opt => opt.option === optionUpsellKey )
        if ( !hasUpsell ) {
            // WITH UPSELL
            upsellConfiguration = this.matchConfiguration( [...this.activeConfigurationOptions.map( e => e.option ), optionUpsellKey] )
            description = "Plus-Paket hinzufügen"
            isPrimary = true
        } else {
            // WITHOUT UPSELL
            upsellConfiguration = this.matchConfiguration( [...this.activeConfigurationOptions.map( e => e.option ).filter( e => e !== optionUpsellKey )] )
            description = "mit Plus-Paket"
            isPrimary = false
        }
        
        if ( upsellConfiguration ) {
            upsellConfiguration.description = description
            upsellConfiguration.primary = isPrimary
        }
        
        this.upsellConfiguration = upsellConfiguration
    }
    
    matchConfiguration( includedOptions = [] ) {
        if ( !this.vehicleConfigurationByHash ) {
            return false
        }
        const includedOptionsUnique = [...new Set( includedOptions )]
        let possibleConfiguration = this.vehicleConfigurationByHash[ includedOptionsUnique.sort().join( "," ) ]
        return possibleConfiguration
    }
    
    toggleQuality = ( quality ) => {
        throw (new Error( "Obsolete Function" ))
    }
    
    setMileage( km ) {
        this.mileage = `${Number( km )}`
        if ( this.mileage === "NaN" ) {
            this.mileage = "0"
        }
    }
    
    parseConfigurations( vehicleConfigurations ) {
        if ( !vehicleConfigurations || !vehicleConfigurations.length ) return
        this.configurations = { vehicleConfigurations }
        this.vehicleConfigurationById = {}
        this.vehicleConfigurationKeysById = {}
        this.vehicleConfigurationByHash = {}
        this.vehicleConfigurationKeys = {}
        this.configurations.vehicleConfigurations.forEach( config => {
            this.vehicleConfigurationById[ Number( config.id ) ] = config
            const selectedKeys = {}
            config.includedOptions.forEach( opt => {
                const option = opt.option
                selectedKeys[ option ] = true
                this.vehicleConfigurationKeys[ option ] = true
            } )
            this.vehicleConfigurationKeysById[ `${config.id}` ] = selectedKeys
            const keyHash = Object.keys( selectedKeys ).sort().join( "," )
            this.vehicleConfigurationByHash[ keyHash ] = config
        } )
        
        if ( this.activeConfigurationId && !this.activeConfiguration ) {
            this.setVehicleConfiguration( this.activeConfigurationId )
            const vehicleId = locationStore.activeVehicleReferenceId
            // this.loadServicesFromContext( { vehicleId } )
        }
    }
    
    selectOption( { selectedKeys } ) {
        this.selectedKeys = selectedKeys
    }
    
    async setGearboxId( gearBoxId ) {
        const id = `${gearBoxId}`
        this.activeGearBoxId = id
        this.gearBox = this.gearBoxById[ id ]
        if ( locationStore.activeVehicleReferenceId ) {
            let vehicleConfigurations = await labourServiceStore.fetchVehicleConfigurations( locationStore.activeVehicleReferenceId, this.activeGearBoxId )
            this.parseConfigurations( vehicleConfigurations )
        }
        if ( !this.gearBox ) {
            !this.errorOnce && this.loadDefaultGearBox( locationStore.activeVehicleReferenceId )
            this.errorOnce = true
        }
    }
    
    setRegistrationDate( date ) {
        this.registrationDate = moment( date ).toISOString()
    }
    
    loadDefaultGearBox = async ( vehicleReferenceId ) => {
        if ( !vehicleReferenceId ) {
            return null
        }
        this.isInvalid = false
        let res = await labourServiceStore.fetchVehicleMetadata( vehicleReferenceId )
        if ( !res || !res.gearBoxes ) {
            this.isInvalid = true
            return
        }
        this.gearBoxes = res.gearBoxes || []
        this.gearBoxById = {}
        this.gearBoxes.forEach( gearBox => {
            this.gearBoxById[ `${gearBox.id}` ] = gearBox
        } )
        
        if ( this.gearBoxById[ this.activeGearBoxId ] ) {
            // gearbox found. Nothing to do.
        } else {
            if ( res.gearBoxes[ 0 ] ) {
                this.setGearboxId( res.gearBoxes[ 0 ].id )
            }
        }
        
    }
    
    setVehicleReferenceId = async ( vehicleReferenceId ) => {
        if ( (locationStore.activeVehicleReferenceId !== vehicleReferenceId) ) {
            locationStore.setActiveVehicleReferenceId( vehicleReferenceId )
        }
        
        // load summary
        this.setVehicleConfiguration( undefined )
    }
    
    addToCart( { subService }, isAssociatedProduct = false, primaryProduct = false ) {
        console.log( "ADD ASSOC", { subService, isAssociatedProduct, primaryProduct } )
        const productData = {
            ...subService,
            productID                       : subService.id,
            category                        : `Inspektion`,
            name                            : subService.itemMountPosition || subService.name,
            price                           : subService.priceGross,
            priceGross                      : subService.priceGross,
            recommendedRetailPriceGrossInEur: subService.priceGross
        }
        
        const product = new ProductModel( productData )
        // --> console.log( {subService, productData, isAssociatedProduct} )
        
        if ( isAssociatedProduct ) {
            product.category = "Inspektion"
            // return cartStore.addAssociatedProduct( product, 1 )
            return cartStore.addAssociatedProductToProduct( primaryProduct, product, 1 )
        }
        
        const position = cartStore.addPosition(
            product,
            1
        )
        
        console.log( { position } )
        return position
    }
    
    loadServicesFromContext( { vehicleId } ) {
        this.loading = true
        return this.loadServices( {
            vehicleId,
            mileage         : this.mileage || "20000",
            registrationDate: this.registrationDate,
            bodyId          : this.bodyId,
            gearBox         : this.activeGearBoxId,
            nextServiceId   : this.nextServiceId,
            configuration   : this.activeConfigurationId
        } )
    }
    
    reset() {
        this.serviceById = {}
        this.services = []
        this.maintenanceServices = []
        this.additionalServices = []
        this.status = "loading"
        this.loading = true
    }
    
    
    async loadGearbox( { vehicleId } ) {
        if ( this.lastVehicleId !== vehicleId ) {
            this.reset()
        }
        this.loading = true
        await this.loadDefaultGearBox( vehicleId )
        if ( !this.gearBox ) return
        this.defaultGearbox = this.gearBox
        this.defaultGearboxId = this.gearBox.id
        this.gearBox = this.defaultGearboxId
        
        // --> console.log( this.gearBox, this.defaultGearbox, this.gearBoxes )
        
        // this.setGearbox(this.defaultGearbox.id)
        this.defaultServices = []
        this.loading = false
    }
    
    // https://ncggtwebserver.versatio.de/api/v1/products/services?MileAge=50000&GearBox=65&Configuration=106178&Store=NAGEL_SOHN_VERSMOLD&NextServiceId=-13020171&RegistrationDate=2009-08-12T17:56:39.965Z&Vehicle=98040153314374768
    //?vehicle=98040153314374768&MileAge=50000&GearBox=65&Configuration=106178&Store=NAGEL_SOHN_VERSMOLD&LastServiceId=-13016184&RegistrationDate=2009-08-12T17:56:39.965Z
    /**
     * @param vehicleId
     * @param mileage
     * @param registrationDate
     * @param bodyId
     * @param gearBox
     * @param configuration
     * @param nextServiceId
     * @param store
     * @param brand
     * @param version
     * @returns {Promise<void>}
     */
    loadServices = async ( {
                               vehicleId,
                               mileage = "0",
                               registrationDate = "",
                               gearBox,
                               nextServiceId,
                               configuration,
                               store = locationStore.activeStoreId,
                               brand,
                               bodyId,
                               version = 1
                           } = {} ) => {
        let error = false
        this.loading = true
        this.serviceById = {}
        this.services = []
        this.maintenanceServices = []
        this.additionalServices = []
        this.serviceRecommendations = []
        this.lastVehicleId = vehicleId
        if ( !vehicleId ) error = true
        
        if ( !mileage || mileage === "false" ) error = true
        if ( !moment( registrationDate ).isValid() ){
            console.warn("Can't load services without registrationDate", registrationDate)
            error = true
        }
        
        if ( error ) {
            this.loading = false
            return false
        }
        
        const opts = {
            vehicle: vehicleId,
            store,
            bodyId,
            mileAge: mileage,
            mileage: mileage,
            registrationDate,
            gearBox,
            configuration,
            nextServiceId,
            brand,
            version
        }
        
        if ( !gearBox ) {
            delete opts.gearBox
        }
        try {
            const json = await shop.cachedFetch( `${config.API_BASE}/v1/products/services/maintenance?${queryString.stringify( opts )}` )
            this.additionalServicesTotalPrice = 0
            this.priceMinByQuality = {}
            this.countByQuality = {}
            
            let maintenanceServices = []
            let additionalServices = []
            this.priceMinByQuality = {}
            
            this.services = (json.services || []).map( service => new ServicePosition( service ) )
            this.services.forEach( ( service, index ) => {
                this.serviceById[ service.id ] = service
                service.variants && service.variants
                    .forEach( subService => {
                        this.countByQuality[ subService.type ] = this.countByQuality[ subService.type ] + 1 || 1
                        if ( service.isMaintenanceService ) {
                            maintenanceServices.push( subService )
                            this.priceMinByQuality[ subService.type ] = Math.min( this.priceMinByQuality[ subService.type ] || Infinity, subService.priceGross )
                        }
                        if ( service.isAdditionalService ) {
                            additionalServices.push( subService )
                            this.additionalServicesTotalPrice += subService.priceGross
                        }
                        this.serviceById[ subService.id ] = subService
                    } )
            } )
            
            this.serviceRecommendations = json.serviceRecommendations && json.serviceRecommendations.map( recommendation => {
                return new ServiceRecommendationModel( this, recommendation )
            } ) || []
            
            this.options = json.options
            let conf = json.configurations || { vehicleConfigurations: [] }
            this.parseConfigurations( conf.vehicleConfigurations )
            this.maintenanceServices = maintenanceServices
            this.additionalServices = additionalServices
            this.status = "loaded"
            this.loading = false
            return {
                maintenanceServices,
                additionalServices,
                serviceRecommendations: this.serviceRecommendations,
                priceMinByQuality: this.priceMinByQuality
            }
        } catch (loadServicesException) {
            console.error( "Could not load /api/v1/products/services/maintenance", loadServicesException )
            this.status = "failed"
            this.serviceRecommendations = []
            // this.gearBoxes = []
            this.options = []
            this.configurations = []
            this.loading = false
            return false
        }
    }
}


function findOil( subService ) {
    let oil = false
    subService.partGroups.forEach( partGroup => {
        partGroup.parts.forEach( part => {
            if ( part.partNumber === "NC1" || part.partNumber === "NC100" ) {
                oil = part
            }
        } )
    } )
    return oil
}

function findBrand( subService ) {
    try {
        if ( subService.partGroups && subService.partGroups.length > 0 && subService.partGroups[ 0 ].parts.length > 0 && subService.partGroups[ 0 ].parts[ 0 ].manufacturer ) {
            return subService.partGroups[ 0 ].parts[ 0 ].manufacturer
        }
        
    } catch (err) {
        return ""
    }
    return ""
}

let vehicleServiceStore = new VehicleServiceStore()

// TODO
global.vehicleServiceStore = vehicleServiceStore

export default vehicleServiceStore

