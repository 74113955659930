import {Button} from "semantic-ui-react"
import Icon from "../../utils/Icon"
import {faArrowRight} from "@fortawesome/pro-light-svg-icons"
import React from "react"
import {faWarning} from "@fortawesome/pro-solid-svg-icons";

export const ArrowButton = ({children, hasAlert=false, icon=faArrowRight, primary=true, basic=true, ...props}) => {
	if(hasAlert) return <ArrowButtonAlert className={"group"}
										  primary={primary}
										  basic={basic}
										  fluid
										  size={"small"}
										  {...props}>{children}</ArrowButtonAlert>
		return <Button
		className={"group"}
		primary={primary}
		basic={basic}
		fluid
		size={"small"}
		{...props}
	>
		<div className="flex flex-row items-center justify-between w-full">
			<div className="text-sm ">{children}</div>
			<div>
				<div
					className={"group-hover:translate-x-[1px] group-hover:opacity-100 translate-x-[-8px] transition-all duration-500"}>
					<Icon className={"w-4 h-4"} icon={icon}/></div>
			</div>
		</div>
	</Button>;
}


const ArrowButtonAlert = ({children, icon=faArrowRight, basic, primary, ...props}) => {
	return <Button
		className={"group"}
		primary={primary}
		basic={basic}
		fluid
		size={"small"}
		{...props}
	>
		<div className="flex flex-row items-center justify-between w-full">
			<div className="font-bold"><Icon className={"-ml-2 mr-2 w-4 h-4"} icon={faWarning} />{children}</div>
			<div>
				<div className={"group-hover:translate-x-[1px] group-hover:opacity-100 translate-x-[-8px] transition-all duration-500"}>
					<Icon className={"w-4 h-4"} icon={icon} /></div>
			</div>
		</div>
	</Button>
}