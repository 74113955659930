import { action, observable, makeObservable } from "mobx";

class Manufacturer {
  constructor( data ) {
    Object.assign( this, data )
    this.img = `/img/stock/manufacturer/${this.key}.jpg`
  }
}


export default class CarStore {
  manufacturerByKey = new Map();
  carModelByKey = {};
  manufacturers = [];
  loading = false;
  brands = [];

  //
  // @action
  // loadCars( store = config.DEFAULT_STORE ) {
  //   this.loading = true
  //   return fetch( `/data/criteria/wheels-${store}.json` )
  //     .then( res => res.json() )
  //     .then( stores => {
  //         this.loading = false
  //         // // // --> console.log( `stores`, stores )
  //
  //         // TODO BRANDS
  //
  //         stores.forEach( store => {
  //           // // --> console.log( `store`, store.key )
  //           store.carManufacturer.forEach( this.parseManufacturer )
  //           this.brands = store.brands
  //         } )
  //         //console.info( "carStore READY" )
  //       }
  //     ).catch( err => {
  //       this.loading = false
  //       toast.error( "Fehler beim Laden der verfügbaren Fahrzeuge" )
  //     } )
  // }


  parseManufacturer = response => {
    let data = new Manufacturer( response )
    if ( !data.carModels || data.carModels.length <= 0 ) return

    data.carModelByKey = {}
    data.carModelsByGroup = {}
    data.carModelsByName = {}
    data.carDisplayNames = {}

    data.carModels.forEach( carModel => {
      this.carModelByKey[ carModel.key ] = carModel
      data.carModelByKey[ carModel.key ] = carModel
      data.carDisplayNames[ carModel.displayName ] = true

      // by Group Bucket
      let group = data.carModelsByGroup[ carModel.group ] || []
      group.push( carModel )
      data.carModelsByGroup[ carModel.group ] = group

      // by Display Name Bucket
      let byName = data.carModelsByName[ carModel.displayName ] || []
      byName.push( carModel )
      data.carModelsByName[ carModel.displayName ] = byName
    } )
    data.carModelNames = [ ...Object.keys( data.carModelsByName ) ]

    this.manufacturers.push( data )
    this.manufacturerByKey.set( data.key, data )
  };

  constructor() {
    makeObservable(this, {
      manufacturerByKey: observable,
      carModelByKey: observable,
      manufacturers: observable,
      loading: observable,
      brands: observable,
      parseManufacturer: action
    });
  }
}
