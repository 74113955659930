import config from "store/store.config"
// import searchStore from "store/searchStore"

export default class WheelStore {

  createWheelpackage( { vehicleId, tyreProductId, rimProductId, hasTPMS } ) {
    return fetch( `${config.API_BASE}/products/wheelpackages/after-market`, {
      headers: {
        'Accept':       'application/json',
        'Content-Type': 'application/json'
      },
      method:  "POST", body: JSON.stringify( {
        "VehicleID":     vehicleId,
        "TyreProductID": tyreProductId,
        "RimProductID":  rimProductId,
        "HasTPMS":       hasTPMS
      } )
    } )
      .then( res => {
        const location = res.headers.get( "location" )
        if( location){
          const wpkId = parseLocation( location )
          // --> console.log( { location, wpkId } )
          return { wpkId }
        } else {
          throw Error("Ungültiger Komplettradsatz")
        }
      } )
  }

  get( id ) {
    return searchStore.fetchWheelPackage( id )
  }
}


const parseLocation = ( location ) => {
  let parts = location.split( "/" )
  return parts[ parts.length - 1 ]
}
