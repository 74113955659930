import config from "store/store.config"
// // import sessionStore from "store/sessionStore"

export default class BaseModel {

    _url = ""
    data = {}

    constructor( data, url ) {
        Object.assign( this.data, data )
        Object.keys(this.data.vehicle).forEach(key => {
            if (!this.data.vehicle[key]) {
                delete this.data.vehicle[key];
            }
        });
        this._url = url
    }

    post(){
        return fetch( `${config.API_BASE}/${this._url}`, {
            method : "POST",
            body   : JSON.stringify( this.data ),
            headers: {
                "Content-Type" : "application/json; charset=utf-8",
                "Authorization": sessionStore.authorization
            },
        } )
    }

    put(){
        return fetch( `${config.API_BASE}/${this._url}`, {
            method : "PUT",
            body   : JSON.stringify( this.data ),
            headers: {
                "Content-Type" : "application/json; charset=utf-8",
                "Authorization": sessionStore.authorization
            },
        } )
    }

    get(){
        return fetch( `${config.API_BASE}/${this._url}`, {
            method : "GET",
            headers: {
                "Content-Type" : "application/json; charset=utf-8",
                "Authorization": sessionStore.authorization
            },
        } )
    }

    delete(){
        return fetch( `${config.API_BASE}/${this._url}`, {
            method : "DELETE",
            headers: {
                "Content-Type" : "application/json; charset=utf-8",
                "Authorization": sessionStore.authorization
            },
        } )
    }

}


