import axios from "axios"
import { action, observable, makeObservable } from "mobx";
import config from "./store.config"
import Product from "model/Product"
// import cartStore from "store/cartStore"

export default class ServiceStore {
    serviceGroupById = new Map();
    serviceGroups = [];
    loading = true;

    services = [];
    serviceById = new Map();
    servicesByGroup = {};
    serviceCategoryById = {};
    serviceCategoriesByGroup = {};

    vehicleTypes = [];
    vehicleTypeById = {}
    vehicleTypeName = {}
    groupNameById = {}

    getVehicleTypeName = ( key ) => {
        let k = this.vehicleTypeById[key]
        return k && k.displayName
    }

    products = [];
    productById = new Map();
    serviceGroupDetailsById = new Map();

    constructor() {
        makeObservable(this, {
            serviceGroupById: observable,
            serviceGroups: observable,
            loading: observable,
            services: observable,
            serviceById: observable,
            servicesByGroup: observable,
            serviceCategoryById: observable,
            serviceCategoriesByGroup: observable,
            vehicleTypes: observable,
            products: observable,
            productById: observable,
            serviceGroupDetailsById: observable,
            fetchGroups: action,
            search: action,
            addUnqualifiedAppointmentService: action,
            fetchVehicleTypes: action,
            groups: action,
            fetchServiceGroup: action
        });
    }

    fetchGroups({store = locationStore.activeStoreId} = {}) {
        axios.get( `${config.API_BASE}/services/groups?Store=${store}` )
            .then( res => {
                const serviceGroups = res.data
                serviceGroups.forEach( serviceGroup => {
                    serviceGroup.slug = serviceGroup.name && serviceGroup.name.split( "/" ).join( "" ).split( " " ).join( "-" )
                    this.groupNameById[`${serviceGroup.groupID}`] = serviceGroup.name
                    this.serviceGroupById.set( `${serviceGroup.groupID}`, serviceGroup )
               
                } )
                this.serviceGroups = serviceGroups
            } )
    }

    handleServiceResponse( res ) {
        let services = res.data.map( s => new Product( s ) )
        return services
    }

    async search(
        {store = locationStore.activeStoreId, manufacturerKey = locationStore.activeManufacturerKey, vehicleSeries = locationStore.activeVehicleClassId} = {}
    ) {
      // OBSOLETE

        return false
    }

    async addUnqualifiedAppointmentService() {
        const group = await this.fetchServiceGroup( "1015" )

        if ( !group || group.services.length === 0 ) {
            console.error( "Kein Service in der Gruppe 'Termin buchen' (1015) " )
            return false
        }

        const appointmentProduct = group.services[0]
        appointmentProduct.automaker = locationStore.activeManufacturerKey
        cartStore.addPosition( appointmentProduct, 1 )

        return true
    }

    fetchVehicleTypes(
        {store = locationStore.activeStoreId, manufacturerKey = locationStore.activeManufacturerKey} = {}
    ) {
       // OBSOLETE
        return false
    }

    groups(
        {store = locationStore.activeStoreId, manufacturerKey = locationStore.activeManufacturerKey}
    ) {
        //http://ncggttestapplication.versatio.de:1797/index.html#/default/GetServicesByVehicle
        // api/services/vehicles?VehicleSeries=TRAILER&Store=/NAGEL_SOHN_VERSMOLD&Brand=MERCEDES-BENZ
        return axios.get( `${config.API_BASE}/services/groups?Store=${store}&Brand=${manufacturerKey}` )
    }


    fetchServiceGroup(
        groupId,
        {store = locationStore.activeStoreId, brand = locationStore.activeManufacturerKey} = {}
    ) {
        return axios.get( `${config.API_BASE}/services/groups/${groupId}?Store=${store}&brand=${brand}` )
            .then( res => res.data )
            .then( group => {
                group.byCategory = {}
                group.groupID = groupId
                if ( group.services ) {
                    // // --> console.log( `Received ${group.services.length} services for ${group.name}` )
                    group.services.forEach( service => {
                        //this.serviceById.set( `${service.productID}`, service )
                        addIntoCategory( group.byCategory, service )
                    } )
                }

                if ( group.products ) {
                    // // --> console.log( `Received ${group.products.length} products for ${group.name}`, { products: group.products } )
                    group.products.forEach( product => {
                        this.productById.set( product.productID, product )
                    } )
                }

                // this.services = [...this.serviceById.values()]
                this.serviceGroupDetailsById.set( groupId, group )
                return group
            } )
    }
}

const addIntoCategory = ( byCategory, service ) => {
    let group = byCategory[service.category] || []
    group.push( service )
    byCategory[service.category] = group
}
