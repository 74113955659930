import React from "react"

export const priceSignalTitles = {
    1   : "zzgl. Material",
    2   : "Schätzpreis",
    2.75: "Aktionspreis",
    3   : "Festpreis",
    4   : "Kostenlos",
    0   : "noch offen"
}

export const priceSignalTitleShort = {
    1   : "zzgl. Material",
    2   : "Schätzpreis",
    2.75: "Aktionspreis",
    3   : "Festpreis",
    4   : "Kostenlos",
    0   : "noch offen"
}

export const getPriceSignal = ( priceFlag ) => {
    switch (priceFlag) {
        case "workprice":
        case "workprice+":
            return 1
        case "variantprice":
            return 2
        case "specialprice":
            return 2.75
        case "fixedprice":
            return 3
        case "free":
            return 4
        case "undetermined":
            return 0
        default:
            return 3
    }
}

export const getPriceFlag = ( priceSignal ) => {
    switch (priceSignal) {
        case 1:
            return "workprice"
        case 2:
            return "variantprice"
        case 2.7:
            return "specialprice"
        case 3:
            return "fixedprice"
        case 4:
            return "free"
        case 0:
            return "undetermined"
        default:
            return "fixedprice"
    }
}

export const getPriceTitle = ( priceFlag, priceTitles = priceSignalTitleShort ) => {
    switch (priceFlag) {
        case "workprice":
        case "workprice+":
            return priceTitles[ 1 ]
        case "variantprice":
            return priceTitles[ 2 ]
        case "fixedprice":
            return priceTitles[ 3 ]
        case "free":
            return priceTitles[ 4 ]
        case "undetermined":
            return priceTitles[ 0 ]
        default:
            return ""
    }
}

export const priceSignalDescriptions = {
    1: "Es kommen noch Materialkosten hinzu, für die keine genauen Angaben vorliegen.",
    2: "Es gibt Preisalternativen für ihren Service.",
    3: "Sie zahlen nur den angezeigten Preis.",
    4: "Diese Dienstleistung ist für sie kostenlos.",
    0: "Sie haben eine Werkstattleistung gewählt, deren Kosten “noch offen” sind. Ein Servicemitarbeiter wird sich Ihr Fahrzeug ansehen und dann mit einer genaueren Kostenschätzung auf Sie zukommen."
}

export const priceSignalDescriptionsLong = {
    1: "Es kommen noch Materialkosten hinzu, für die keine genauen Angaben vorliegen.",
    2: "Wir haben für Sie den Festpreis mit Ersatzteilen von “der Marke Ihres Vertrauens” auf Basis Ihrer Fahrzeugeingabe zusammengestellt.",
    3: "Sie zahlen nur den angezeigten Preis.",
    4: "Kostenlos.",
    0: "Sie haben eine Werkstattleistung gewählt, deren Kosten “noch offen” sind. Ein Servicemitarbeiter wird sich Ihr Fahrzeug ansehen und dann mit einer genaueren Kostenschätzung auf Sie zukommen."
}

const PriceSignalContainer = ( { priceSignal } ) => {
    return <div data-price-signal={priceSignal} className={"PriceSignalContainer flex flex-col"}>
        <div className="f4-ns f5 fw6">
            {priceSignalTitles[ priceSignal ]}
        </div>
        <div className="f6">
            {priceSignalDescriptions[ priceSignal ]}
        </div>
    </div>
}

export const PriceSignal = ( { level = 1, singleLine = false, align = "right", inverted = false, hideBoxes = false } ) => {
    const l = Math.min( 3, Math.max( 1, Math.ceil( level ) ) )
    const bgGood = "bg-green"
    const bgMedium = "bg-light-green"
    const bgNah = "bg-black-10"
    const border = inverted ? "b--black-50" : "b--white"
    
    return <div className={`PriceSignal ${singleLine ? "flex flex-row" : ""}`}>
        {!hideBoxes && <div className={`flex items-center flex-row pb-1 ${align === "left" ? "justify-start" : "justify-end"}`}>
            <div className={`w1 mr1 h1  ${inverted ? "o-100" : ""} ${border} ${bgGood}`} />
            <div className={`w1 mr1 h1  ${inverted ? "o-100" : ""} ${border} ${l >= 2 ? bgGood : bgMedium}`} />
            <div className={`w1  h1  ${inverted ? "o-100" : ""} ${border} ${l === 3 ? bgGood : l === 2 ? bgMedium : bgNah}`} />
        </div>}
        <div className={`nowrap f7 fw4 ${inverted ? "white-60" : "black-60"} ${align === "left" ? "tl pr1" : "tr pl1"}`}>{priceSignalTitles[ level ]}</div>
    </div>
}

export default PriceSignalContainer
