import {makeObservable, observable} from "mobx"
import config from "store/store.config"
import shop from "store/shop"
import Product from "model/Product"
import {getPriceFlag, getPriceSignal} from "component/Price/PriceSignalContainer"
import {flatten} from "lodash-es"
import VehicleMeta from "../../model/VehicleMeta"


class VehicleConfigurationsModel {

    constructor(configurations = []) {
        if (!configurations) {
            configurations = []
        }
        if (configurations.error) {
            console.warn(configurations.error)
            configurations = []
        }
        Object.assign(this, configurations.map(c => {
            if (c.includedOptions.length === 0) {
                if (configurations.length === 1) c.includedOptions.push({option: "Basisaustattung"})
            }
            return c
        }))

    }
}

export const baseQualityVariants = [{
    qualityType: "Recommended", status: 404
}, {
    qualityType: "Quality", status: 404
}, {
    qualityType: "Economy", status: 404
}]

class ServiceQualityGroup {
    constructor({services = [], quality}) {
        this.quality = quality
        this.services = flatten(services.map(s => {
            return s.variants.filter(sv => {
                return sv.type === quality
            })
        }))
        this.priceSignals = []
        this.priceFlags = []
        this.groupPrice = 0
        this.services.forEach(service => {
            this.groupPrice += service.priceGross
            let priceSignal = getPriceSignal(service.priceFlag)
            this.priceSignals.push(priceSignal)
            this.priceFlags.push(service.priceFlag)
            this.minPriceSignal = Math.min(priceSignal, this.minPriceSignal || 3)
        })

        this.minPriceFlag = getPriceFlag(this.minPriceSignal)

        // should we instead book just an appointment. Price will be shown in the
        this.replaceWithCustomProduct = this.minPriceSignal !== 3

        try {
            this.manufacturer = this.services[0].partGroups[0].parts[0].manufacturer || locationStore.activeManufacturerKey
        } catch (err) {
        }
    }
}

class ServiceCluster {
    constructor(opt) {
        const {clusterId, services, mainGroupId, subGroupId, status, clusterName} = opt
        this.serviceGroupByQuality = {}
        this.clusterName = clusterName
        this.clusterId = clusterId
        this.services = services
        this.mainGroupId = mainGroupId
        this.subGroupId = subGroupId
        this.status = status
        this.priceByType = {}
        if (!this.services) this.error = true
        this.services && this.services.map(service => {
            service.variants.map(variant => {
                let price = this.priceByType[variant.type] || 0
                price += variant.priceGross
                this.priceByType[variant.type] = price
            })
        })
        this.serviceGroups = baseQualityVariants.map(base => {
            return new ServiceQualityGroup({services: this.services, quality: base.qualityType})
        })

        this.serviceGroups.forEach(serviceGroup => {
            this.serviceGroupByQuality[serviceGroup.quality] = serviceGroup
        })

    }

}


class WorkListModel {
    constructor(data) {
        Object.assign(this, data)
        this.categories = this.subCategory.split("/")
        this.firstCategory = this.categories[0]
        this.tags = this.mainCategory.split(" ")
        this.position = this.tags[1] || ""
        this.shopCategory = `${this.tags[0] || ""}`.toLowerCase()
    }
}

class LabourServiceStore {
    loading = false
    services = []
    serviceById = {}
    workItemByItemMount = {}
    clusters = []
    clusterList = []
    clustersByGroupId = {}
    clusterById = {}

    handleResponse = async (data) => {
        if (!data) {
            return false
        }
        return data
    }

    async fetchClusterList({vehicleReferenceId, bodyId = 0}) {
        try {
            //  const response1 = await shop.cachedFetch( `${config.URL_BASE}pimcore/api/clusters?vehicleId=${vehicleReferenceId}` )
            const response = await cmsStore.fetchFeaturedClusters()
            // const response = await shop.cachedFetch( `${config.URL_BASE}pimcore/api/clusters` ) // always fetch anonymous clusters
            const clustersByGroupId = {}
            if (!response) return []

            let clusters = response
                // .filter( cluster => {
                //     if ( !cluster.hasAvailableServices ) console.warn( `Cluster ${cluster.id} without services` )
                //     return cluster.hasAvailableServices
                // } )
                .map(clusterData => {
                    clusterData.groups.forEach(({mainGroup, subGroup}) => {
                        let group = clustersByGroupId[mainGroup.id] || []
                        group.push(clusterData.clusterId)
                        clustersByGroupId[mainGroup.id] = group
                        clusterData.mainGroupId = mainGroup && mainGroup.id
                        clusterData.subGroupId = subGroup && subGroup.id
                    })
                    return clusterData
                })

            this.clustersByGroupId = clustersByGroupId
            this.clusters = clusters
            this.fetchClusterListDetails({vehicleReferenceId, activeBodyId: bodyId})
            cmsStore.createMetaData()
            return clusters
        } catch (err) {
            console.error(err)
            return false
        }
    }

    async fetchClusterListDetails({vehicleReferenceId, activeBodyId = 0} = {}) {
        const clusters = this.clusters
        // console.log( "=====", { clusters }, clusters.length )
        const promises = clusters
            .map(async cluster => {
                let clusterDetail

                clusterDetail = await labourServiceStore.fetchServiceCluster({
                    vehicleReferenceId, mainGroupId: cluster.mainGroupId, subGroupId: cluster.subGroupId, bodyId: activeBodyId, clusterId: cluster.clusterId
                })
                clusterDetail.clusterName = clusterDetail.clusterName || cluster.name

                return clusterDetail
            })
        const clusterListDetails = await Promise.all(promises)
        // console.log( { clusterListDetails } )
        let oilFound = false

        // REMOVE OIL CLUSTERS
        let oilClusters = clusterListDetails
            .filter(e => e)
            .filter((cluster, index) => cluster.clusterName.includes("Ölwechsel"))
            .sort((a, b) => {
                if (!a.priceByType || !b.priceByType) return 0
                // console.log( a.priceByType.Recommended, b.priceByType.Recommended )
                if (Number(a.priceByType.Recommended || 0) > Number(b.priceByType.Recommended || 0)) return -1
                if (Number(a.priceByType.Recommended || 0) < Number(b.priceByType.Recommended || 0)) return 1
                return 0
            })

        this.clusterList = clusterListDetails
            .filter(e => e)
            .filter((cluster, index) => !cluster.clusterName.includes("Ölwechsel"))
            .sort((a, b) => {
                let priceSignalA = a.serviceGroupByQuality?.Recommended.minPriceSignal
                let priceSignalB = b.serviceGroupByQuality?.Recommended.minPriceSignal
                let priceA = a.serviceGroupByQuality?.Recommended.groupPrice
                let priceB = b.serviceGroupByQuality?.Recommended.groupPrice
                if (priceSignalA > priceSignalB) return -1
                if (priceSignalB > priceSignalA) return 1
                if (priceA < priceB) return -1
                if (priceB < priceA) return 1
                return 0
            })

        if (oilClusters[0]) {
            this.clusterList.push(oilClusters[0])
        }
        return clusterListDetails
    }

    async fetchServiceCluster({vehicleReferenceId, clusterId, mainGroupId, subGroupId, bodyId = 0, store = locationStore.activeStoreId}) {
        // console.log( "XXX", { mainGroupId, clusterId, subGroupId } )
        if (!vehicleReferenceId) {
            return {error: true}
        }
        try {
            const response = await shop.cachedFetch(`${config.API_BASE}/v1/products/services/labortimes/cluster?Vehicle=${vehicleReferenceId}&ClusterId=${clusterId}&BodyId=${bodyId}&Store=${store}`)
            // // console.log({response})
            response.mainGroupId = mainGroupId
            response.subGroupId = subGroupId
            const serviceCluster = new ServiceCluster(response)
            this.clusterById[serviceCluster.clusterId] = serviceCluster
            return serviceCluster
        } catch (error) {
            console.error(error)
            return {error}
        }
    }

    vehicleMetaByReferenceId = {}

    async fetchVehicleMetadata(vehicleReferenceId) {
        if (!vehicleReferenceId) throw Error("No VehicleReference Id")
        try {
            return await shop.cachedFetch(`${config.API_BASE}/criterias/vehicles/models/${vehicleReferenceId}/summary`)
                .then(this.handleResponse)
                .then(meta => {
                    if (meta.error) {
                        this.vehicleMetaByReferenceId[vehicleReferenceId] = false
                    } else {
                        let vehicleMeta = new VehicleMeta(meta)
                        this.vehicleMetaByReferenceId[vehicleReferenceId] = vehicleMeta
                        return vehicleMeta
                    }
                    return meta
                })
        } catch (err) {
            console.error(err)
            return false
        }
    }

    async fetchVehicleConfigurations(vehicleReferenceId, gearBox) {
        if (!vehicleReferenceId || !gearBox) {
            console.error("Invalid Vehicle Configuration Request", {vehicleReferenceId, gearBox})
        }

        try {
            let configurationPromise = shop.cachedFetch(`${config.API_BASE}/criterias/vehicles/models/${vehicleReferenceId}/configuration?gearBox=${gearBox}`)
                .then(this.handleResponse)
            const response = await configurationPromise
            const configurations = new VehicleConfigurationsModel(response)
            if (!response) {
                return false
            }
            // // console.log( "response fetchVehicleConfigurations", { vehicleReferenceId, gearBox }, response )
            return response
        } catch (err) {
            console.error(err)
            return false
        }
    }

    async fetchWorkList(vehicleReferenceId = "1130133576370701432", bodyId = 0, storeId = locationStore.activeStoreId) {
        try {
            const response = await shop.cachedFetch(`${config.API_BASE}/v1/products/services/labortimes/worklist?Vehicle=${vehicleReferenceId}&BodyId=${bodyId}&Store=${storeId}`)
                .then(this.handleResponse)
                .then(res => {
                    let byId = {}
                    res.workList = res.workList.map(data => {
                        let worklistItem = new WorkListModel(data)
                        byId[worklistItem.itemMountPositionId] = worklistItem
                        return worklistItem
                    })
                    this.workItemByItemMount = byId
                    return res
                })
            return response
        } catch (err) {
            console.error(err)
            return false
        }
    }

    async fetchLabourServices({vehicleReferenceId = "1130133576370701432", configurationId, kindOfRepairId, itemMountPosition, bodyId, storeId = locationStore.activeStoreId}) {

        try {
            const response = await shop.cachedFetch(`${config.API_BASE}/v1/products/services/labortimes?Vehicle=${vehicleReferenceId}&Configuration=${configurationId}&KindOfRepairId=${kindOfRepairId}&ItemMountPositionId=${itemMountPosition}&BodyId=${bodyId}&Store=${storeId}`)
                .then(this.handleResponse)
                .then(res => {
                    res.services.forEach(service => {
                        service.variants = service.variants.map(data => {
                            data.itemMountPosition = service.itemMountPosition
                            data.isAdditionalService = service.isAdditionalService
                            data.isMaintenanceService = service.isMaintenanceService
                            data.configuration = service.configuration
                            data.isAdditionalService = service.isAdditionalService
                            data.isMaintenanceService = service.isMaintenanceService
                            data.isRecommended = service.isRecommended
                            data.itemMountPosition = service.itemMountPosition
                            data.kindOfRepair = service.kindOfRepair
                            data.status = service.status
                            return new Product(data)
                        })
                    })

                    return res

                })
            // console.log( "response fetchLabourServices", response )
            return response
        } catch (err) {
            console.error(err)
            return false
        }
    }


    constructor() {
        makeObservable(this, {
            loading: observable,
            services: observable,
            serviceById: observable,
            workItemByItemMount: observable,
            clusters: observable,
            clusterList: observable,
            clustersByGroupId: observable,
            clusterById: observable,
            vehicleMetaByReferenceId: observable
        })
    }
}


const labourServiceStore = new LabourServiceStore()
window.labourServiceStore = labourServiceStore
export default labourServiceStore
