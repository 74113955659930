import React, { Component, useEffect } from "react"
import { Container } from "semantic-ui-react"
import Icon from "utils/Icon"
import {faCheck, faCheckCircle, faHome} from "@fortawesome/pro-light-svg-icons"
import HomeLink from "component/HomeLink"
import { observer } from "mobx-react"
import { observable } from "mobx"
import "./ShopBreadcrumb.css"

import ErrorBoundary from "../ErrorBoundary"

const breadcrumb = observable( {
    stack: []
} )

let stackId = 0

/**
 * @return {null}
 */
export function ShopBreadcrumbContext( { children } ) {
    
    useEffect( () => {
        
        let entry = { id: Math.random().toString( 32 ) + stackId++ }
        entry.Component = children
        breadcrumb.stack.push( entry )
        
        return () => {
            breadcrumb.stack = breadcrumb.stack.filter( s => s.id !== entry.id )
        }
    }, [children] )
    return null
}


class ShopBreadcrumb extends Component {
    render() {
        const { activeStore } = locationStore
        let { store = activeStore, children } = this.props
        let { stack } = breadcrumb

        if(!children || children.length === 0) return null
        return <div className="ShopBreadcrumb print:hidden bg-ncg-black relative border-t border-gray-800 sticky top-0">
            
            <div className="">
                <Container>
                    <div className="flex flex-row justify-between items-center">
                        <div className="flex flex-row  f6 py-3 px-4 scroll w-full -mx-4 sm:mx-0">
                            <ErrorBoundary>
                                {store && <div className={"BreadcrumbFragment flex flex-row nowrap"}>
                                    <HomeLink className="pr2 text-xs sm:text-sm uppercase text-gray-200 hover-white"><Icon className={"white mr2"} icon={faHome} /><span className="store-name dn dib-ns">Shop</span></HomeLink>
                                </div>}
                                
                                {React.Children.map( children, function( child ) {
                                    return <div className={"BreadcrumbFragment flex flex-row nowrap"}>
                                        <div className="pr2 text-xs sm:text-sm uppercase text-gray-200 ">/</div>
                                        <div className="pr2 text-xs sm:text-sm uppercase text-gray-200 breadcrumb-child animate fadeIn">{child}</div>
                                    </div>
                                } )}
                                
                                {stack.map( ( entry, index ) => {
                                    if ( !entry.Component ) return null
                                    return <div key={index} className={"BreadcrumbFragment flex flex-row nowrap"}>
                                        <div className="pr2 text-xs sm:text-sm uppercase text-gray-200">/</div>
                                        <div className="pr2 text-xs sm:text-sm uppercase text-gray-200 breadcrumb-child animate fadeIn">{entry.Component}</div>
                                    </div>
                                } )}
                            
                            </ErrorBoundary>


                        </div>
                        
                    </div>
                </Container>

            </div>
        
        </div>
    }
}

export default observer( ShopBreadcrumb )

