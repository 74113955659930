import React from "react"
import {Image} from "semantic-ui-react";

export default function VehicleCardMinimal( { vehicle, showImage = false, vehicleProfile, color="ffffff", className = "" } ) {
  if ( !vehicle ) return null
  return <div className={`VehicleCardMinimal flex flex-col ${className}`}>
    <div className="flex flex-row items-center">
      {showImage && <Image className="ui image mr3"
                           width={60}
                           height={60}
                           src={`https://cdn.jfnet.de/carPerspective/${vehicle.imageName}/120x120-cc-${color}.jpg`}
      />}
      
      <div className="pb2">
        <div className="f4 b">
          <div>{vehicleProfile ? <span>{vehicleProfile.licensePlate}</span> : <span>  {vehicle.className}</span>}</div>
        </div>
        <div className="f6 black-60">
          {vehicle.version}
        </div>
      </div>
    </div>
  </div>
}
