import React from "react"
import { Container, Grid } from "semantic-ui-react"
import Responsive from "react-responsive"
import Icon from "utils/Icon"
import { faHistory, faShieldCheck, faShippingFast, faUserCheck } from "@fortawesome/pro-light-svg-icons"
import { faShieldAlt } from "@fortawesome/free-solid-svg-icons"
import Trustbadge from "../../page/Campaign/Trustbadge";


const TrustBarTires = () => {
    return <div className="mv4-ns mv2 TrustBar pv4-ns pv2 border-top border-bottom  ">
        <Responsive maxWidth={520}>
            <div>
                <div className="pa2-ns pa1 tl flex flex-row items-center">
                    <div className="f4-ns f5 pb3 black-60 outline-border dib pa3-ns pa3 mb3-ns "><Icon.Light name={"shipping-fast"}/></div>
                    <div className="pl3 f5-ns f6 black-60">Kostenloser Versand innerhalb Deutschlands
                    </div>
                </div>
            </div>
            
            <div>
                <div className="pa2-ns pa1 tl flex flex-row items-center">
                    <div className="f4-ns f5 pb3 black-60 outline-border dib pa3-ns pa3 mb3-ns "><Icon.Light name={"user-check"}/></div>
                    <div className="pl3 f5-ns f6 black-60">Montage durch unser Fachpersonal
                        inkl. Wuchten für 59,- EUR.
                    </div>
                </div>
            </div>
            
            <div>
                <div className="pa2-ns pa1 tl flex flex-row items-center">
                    <div className="f4-ns f5 pb3 black-60 outline-border dib pa3-ns pa3 mb3-ns "><Icon.Light name={"shield-check"}/></div>
                    <div className="pl3 f5-ns f6 black-60">Originalteile von einem der größten
                        Vertragshändler in Deutschland.
                    </div>
                </div>
            </div>
        
        </Responsive>
        
        <Responsive minWidth={520}>
            <Grid columns={3}>
                <Grid.Row>
                    
                    <Grid.Column>
                        <div className="pa2-ns pa1 tc">
                            <div className="f4-ns f5 pb3 black-60 outline-border dib pa3-ns pa3 mb3-ns mb2"><Icon.Light name={"shipping-fast"}/></div>
                            <div className="f5-ns f7 black-60">Kostenloser Versand innerhalb Deutschlands
                            </div>
                        </div>
                    </Grid.Column>
                    
                    <Grid.Column>
                        <div className="pa2-ns pa1 tc">
                            <div className="f4-ns f5 pb3 black-60 outline-border dib pa3-ns pa3 mb3-ns mb2"><Icon.Light name={"user-check"}/></div>
                            <div className="f5-ns f7 black-60">Montage durch unser Fachpersonal
                                inkl. Wuchten für 59,- EUR.
                            </div>
                        </div>
                    </Grid.Column>
                    
                    <Grid.Column>
                        <div className="pa2-ns pa1 tc">
                            <div className="f4-ns f5 pb3 black-60 outline-border dib pa3-ns pa3 mb3-ns mb2"><Icon.Light name={"shield-check"}/></div>
                            <div className="f5-ns f7 black-60">Originalteile von einem der größten
                                Vertragshändler in Deutschland.
                            </div>
                        </div>
                    </Grid.Column>
                
                </Grid.Row>
            </Grid>
        </Responsive>
    
    
    </div>
}


const TrustBarFooter = () => {
    return <div className="TrustBar">
        <Container>
            <Grid columns={5} stackable doubling>
                <Grid.Row>
                    
                    <Grid.Column>
                        <div className="p-4 tl flex flex-row">
                            <div className="f3 pb3 white-60  dib pt1 pr3 pb2 "><Icon icon={faHistory}/></div>
                            <div className="f6 white-80">30 Tage Widerrufsrecht & kostenloser
                                Rückversand <br/> innerhalb Deutschlands.
                            </div>
                        </div>
                    </Grid.Column>
                    
                    
                    <Grid.Column>
                        <div className="p-4 tl flex flex-row">
                            <div className="f3 pb3 white-60  dib pt1 pr3 pb2 "><Icon icon={faShippingFast}/></div>
                            <div className="f6 white-80">Versand innerhalb von <br/> Deutschland
                                per Spedition.
                            </div>
                        </div>
                    </Grid.Column>
                    
                    
                    <Grid.Column>
                        <div className="p-4 tl flex flex-row">
                            <div className="f3 pb3 white-60  dib pt1 pr3 pb2 "><Icon icon={faUserCheck}/></div>
                            <div className="f6 white-80">Montage durch unser <br/>Fachpersonal.
                            </div>
                        </div>
                    </Grid.Column>
                    
                    <Grid.Column>
                        <div className="p-4 tl flex flex-row">
                            <div className="f3 pb3 white-60  dib pt1 pr3 pb2 "><Icon icon={faShieldCheck}/></div>
                            <div className="f6 white-80">Originalteile vom <br/>Vetragshändler.</div>
                        </div>
                    </Grid.Column>
                    
                    <Grid.Column>
    
                        <div className="p-4 tl flex flex-row justify-end">
                            <div className="f6 white-80">
                                <div className="w-100 w-100-m w-auto-ns pb3 pb0-ns tc"><Trustbadge/></div>
                            </div>
                        </div>
       
                    </Grid.Column>
                
                </Grid.Row>
            </Grid>
        </Container>
    </div>
}


function TrustBarItem( { className = "pa3 tc", itemClass = "f6 black-50", icon = <Icon icon={faHistory}/>, children } ) {
    return <div className={`${className}`}>
        <div className="f3 pb3 black-40">{icon}</div>
        <div className={itemClass}>{children}
        </div>
    </div>
}

const TrustBar = ( { className = "" } ) => {
    return <div className={`TrustBar pv4 border-top border-bottom ${className}`}>
        <Container>
            <Grid columns={4} stackable doubling>
                <Grid.Row>
                    <Grid.Column>
                        <TrustBarItem icon={<Icon icon={faHistory}/>}>
                            30 Tage Widerrufsrecht und kostenloser
                            Rückversand innerhalb Deutschlands.
                        </TrustBarItem>
                    </Grid.Column>
                    
                    <Grid.Column>
                        <TrustBarItem icon={<Icon icon={faShippingFast}/>}>
                            Kostenloser Versand innerhalb von Deutschland
                            per Spedition.
                        </TrustBarItem>
                    </Grid.Column>
                    
                    <Grid.Column>
                        <TrustBarItem icon={<Icon icon={faUserCheck}/>}>
                            Kostenlose Montage durch unser Fachpersonal
                            inkl. Wuchten.
                        </TrustBarItem>
                    
                    </Grid.Column>
                    
                    <Grid.Column>
                        <TrustBarItem icon={<Icon icon={faShieldAlt}/>}>
                            Originalräder von einem der größten
                            Vertragshändler für {locationStore.activeManufacturerKey} Fahrzeuge.
                        </TrustBarItem>
                    </Grid.Column>
                
                </Grid.Row>
            </Grid>
        </Container>
    </div>
}

export { TrustBarTires, TrustBarItem, TrustBarFooter }
export default TrustBar
