import $fetch from "utils/$fetch"
import {action, observable, makeObservable} from "mobx"
import config from "./store.config"
import {toast} from "react-toastify"
import {reserveAppointmentSlot} from "page/BranchPage/Appointments"
// // import locationStore from "store/locationStore";

export default class PaymentStore {
    total = 0
    braintreeToken = false
    braintreeNonce = false
    braintreeDropinInstance = false
    braintreeTransaction = false
    braintreeError = false
    carbonCopyRecipients = []

    braintreePaymentCompleted = false
    paymentSuccess = false

    paymentMethod = false

    paymentOption = false
    paymentMethodRequestable = false

    paymentNonce = false
    lastBill = false

    products = []
    productsById = {}
    productsByType = {}

    loading = false
    wheelPackage = false

    canContinueWithCreditcard = false

    constructor() {
        makeObservable(this, {
            total: observable,
            braintreeToken: observable,
            braintreeNonce: observable,
            braintreeDropinInstance: observable,
            braintreeTransaction: observable,
            braintreeError: observable,
            carbonCopyRecipients: observable,
            braintreePaymentCompleted: observable,
            paymentSuccess: observable,
            paymentMethod: observable,
            paymentOption: observable,
            paymentMethodRequestable: observable,
            paymentNonce: observable,
            lastBill: observable,
            products: observable,
            productsById: observable,
            productsByType: observable,
            loading: observable,
            wheelPackage: observable,
            canContinueWithCreditcard: observable,
            setWheelPackage: action,
            setBraintreeNonce: action,
            onPaymentMethodRequestable: action,
            OnNoPaymentMethodRequestable: action,
            onPaymentOptionSelected: action,
            setBraintreeDropinInstance: action,
            setBraintreeTransaction: action,
            fetchBraintreeToken: action,
            requestPaymentMethod: action,
            submitTransactionOnBehalf: action,
            submitTransaction: action.bound,
            resolveTransaction: action,
            _executePaymentByInvoice: action,
            executePayment: action.bound,
            reset: action,
            evaluatePayment: action.bound
        })
    }

    setWheelPackage(wheelPackage) {
        this.wheelPackage = wheelPackage
        this.total = this.wheelPackage.priceGrossInEur || 0
    }

    setBraintreeNonce(nonce) {
        // // --> console.log( "NONCE!", nonce )
        this.braintreeNonce = nonce
    }


    onPaymentMethodRequestable = data => {
        this.paymentMethodRequestable = data
        this.paymentMethod = data && data.type
        if (data) {
            if (data.type === "CreditCard") {
                if (data.paymentMethodIsSelected) {

                }
            }
        }

        const paymentType = data && data.type
        // --> console.log( `ON paymentMethodRequestable`, { paymentType, data } )
        this.paymentType = paymentType
    }

    OnNoPaymentMethodRequestable = data => {
        this.paymentMethodRequestable = false
        this.canContinueWithCreditcard = false
        // // --> console.log( `ON noPaymentMethodRequestable`, data )
    }

    onPaymentOptionSelected = data => {
        this.paymentOption = data.paymentOption
        // // --> console.log( `ON paymentOptionSelected`, data )
    }

    setBraintreeDropinInstance(instance) {
        this.braintreeDropinInstance = instance
        this.paymentOption = false

        // bind events
        instance.on("paymentMethodRequestable", this.onPaymentMethodRequestable)
        instance.on("noPaymentMethodRequestable", this.OnNoPaymentMethodRequestable)
        instance.on("paymentOptionSelected", this.onPaymentOptionSelected)
        // // --> console.log( `braintree::dropin::init`, instance )
    }

    setBraintreeTransaction(transaction) {
        this.braintreeTransaction = transaction
        this.loading = false
        // // --> console.log( `braintree::dropin::transaction` )
        return transaction
    }

    handleError = error => {
        console.error("paymentStore:", error)
        this.error = error
        this.loading = false
    }

    fetchBraintreeToken() {
        const tokenURL = `${config.API_BASE}/payments/token`
        if (this.tokenPromise) return this.tokenPromise
        this.loading = true
        this.tokenPromise = $fetch(tokenURL, {
            headers: {
                "Authorization": sessionStore.authorization,
                "IdentityUserId": sessionStore.identityUserId
            }
        })
            .then(res => res.json())
            .then(data => {
                this.braintreeToken = data
                this.loading = false
                // // --> console.log( `payment::braintree-token-received` )
                return data
            })
            .catch(err => this.handleError)

        return this.tokenPromise
    }

    requestPaymentMethod = () => {
        // // --> console.log( "requestPaymentMethod", { cartStore } )

        this.canContinueWithCreditcard = false
        const billingAddress = cartStore.billingAddress
        const deliveryAddress = cartStore.deliveryAddress


        var threeDSecureParameters = {
            amount: cartStore.total,
            email: sessionStore.profile.email
            // billingAddress:        {
            //     givenName:         billingAddress.firstName, // ASCII-printable characters required, else will throw a validation error
            //     surname:           billingAddress.lastName, // ASCII-printable characters required, else will throw a validation error
            //     phoneNumber:       `${sessionStore.profile.phoneNumber}`,
            //     streetAddress:     `${billingAddress.street} ${billingAddress.housenumber}`,
            //     extendedAddress:   '',
            //     locality:          '',
            //     region:            ``,
            //     postalCode:        `${billingAddress.postalCode || billingAddress.zipCode}`,
            //     countryCodeAlpha2: 'DE'
            // },
            // additionalInformation: {
            //     workPhoneNumber: `${sessionStore.profile.phoneNumber}`,
            //     givenName:       deliveryAddress.firstName, // ASCII-printable characters required, else will throw a validation error
            //     surname:         deliveryAddress.lastName, // ASCII-printable characters required, else will throw a validation error
            //     shippingPhone:   `${sessionStore.profile.phoneNumber}`,
            //     shippingAddress: {
            //         streetAddress:     `${deliveryAddress.street} ${deliveryAddress.housenumber}`,
            //         extendedAddress:   '',
            //         locality:          '',
            //         region:            '',
            //         postalCode:        `${deliveryAddress.postalCode || deliveryAddress.zipCode}`,
            //         countryCodeAlpha2: 'DE'
            //     }
            // },
        }

        // // // --> console.log( { threeDSecureParameters } )

        this.loading = true
        return new Promise((resolve, reject) => {
            this.braintreeDropinInstance.requestPaymentMethod({
                    threeDSecure: threeDSecureParameters
                }, (err, payload) => {
                    this.paymentMethod = payload
                    // // --> console.log( "requestPaymentMethod", { err, payload } )
                    if (this.paymentMethod.type === "CreditCard" && !this.paymentMethod.liabilityShifted) {
                        this.loading = false
                        return reject("Ihre Kreditkarte konnte nicht über 3DS  bestätigt werden.")
                    }

                    if (this.paymentMethod.type === "CreditCard" && !this.paymentMethod.liabilityShifted) {
                        this.loading = false
                        return reject("Ihre Bank erlaubt keine Bestätigung ihrer Kreditkarte über 3D Secure.")
                    }
                    this.loading = false

                    if (err) {
                        console.error(err)
                        reject(err)
                        return err
                    }

                    this.canContinueWithCreditcard = true
                    console.info("===== Payment Nonce Received")
                    this.setBraintreeNonce(payload.nonce)
                    resolve(payload)
                }
            )
        })
    }

    submitTransactionOnBehalf({event, externalCustomer}) {
        const {currentCustomer, orderRef} = externalCustomer

        // TODO: SET PAYMENT METHOD INVOICE
        this.paymentType = "INVOICE"
        this.paymentMethod = "INVOICE"

        const email = externalCustomer.notificationEmail
        this.carbonCopyRecipients = [sessionStore.profile.email]

        return this.submitTransaction(
            event,
            {
                onBehalfOf: {
                    email,
                    licensePlate: orderRef
                },
                orderRef
            })
            .then(res => {
                // --> console.log( "SUCCESS", res )
                externalCustomer._success = res
                return {success: true, error: false}
            })
            .catch(error => {
                toast.error(`${error}`)
                return {error}
            })
    }

    submitTransaction(evt, {orderRef, onBehalfOf} = {}) {

        if (this.paymentType === "INVOICE") {
            return this.executePayment({orderRef, onBehalfOf})
        }

        this.loading = true
        evt && evt.preventDefault()
        return this.requestPaymentMethod()
            .then(() => {
                return this.executePayment({onBehalfOf})
            })
            .then(this.evaluatePayment)
            .then(res => res.json())
            .then(this.resolveTransaction)
            .catch(err => {
                console.error("Fehler bei der Bezahlung", err)
                this.loading = false
                toast.error(`Fehler bei der Bezahlung.`)
            })
    }

    resolveTransaction = transaction => {
        let type = this.paymentType
        this.lastBill = cartStore.setPaymentData({transaction, type})
        this.braintreePaymentCompleted = true
        this.paymentSuccess = true

        const appointment = checkoutStore.data.appointment
        if (appointment && appointment.slotId) {
            try {
                reserveAppointmentSlot(appointment.slotId, appointment.reserved)
            } catch (err) {
                console.error("Error while reserving slot", err)
            }
        }

        cartStore.resetOrder()
        this.loading = false
        return transaction
    }

    _executePaymentByInvoice = (paymentData) => {
        paymentData.paymentType = "Rechnung"
        //delete paymentData.paymentNonce
        paymentData.paymentNonce = "INVOICE_NONCE"
        this.loading = true
        let url = `${config.API_BASE}/payments`
        if (paymentData.onBehalfOf) {
            url = `${config.API_BASE}/payments/on-behalf-of/`
        }
        return fetch(url,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": sessionStore.authorization,
                    "IdentityUserId": sessionStore.identityUserId
                },
                body: JSON.stringify(paymentData)
            }
        )
            .then(res => {
                if (res.status > 300) {
                    this.loading = false
                    throw new Error(res.statusText)
                }
                return res
            })
            .then(res => res.json())
            .then(this.resolveTransaction)
            .then(transaction => {
                // // --> console.log( "PAYMENT BY INVOICE", transaction )
                return transaction
            })
    }

    executePayment({orderRef, onBehalfOf} = {}) {
        // // --> console.log( "executePayment Payment" )
        if (!this.braintreeNonce && this.paymentMethod !== "INVOICE") {
            console.error("KEINE NONCE")
        }
        if (!cartStore.order) {
            console.error("KEIN ORDER")
            // console.log( cartStore.shoppingCartID )
        }
        this.loading = true
        let checkInTime = false
        let checkOutTime = false
        if (checkoutStore.data && checkoutStore.data.appointment) {
            checkInTime = checkoutStore.data.appointment.checkInTime
            checkOutTime = checkoutStore.data.appointment.checkOutTime
        }

        if (!checkInTime) {
            checkInTime = appointmentStore.selectedAppointment.checkInTime
            checkOutTime = appointmentStore.selectedAppointment.checkOutTime
            console.warn("Keine Terminwahl")
        }
        const vehicle = false

        let licensePlate = checkoutStore.data.formData.carId || checkoutStore.data.formData.licensePlate || (locationStore.activeVehicleProfile && locationStore.activeVehicleProfile.licensePlate)

        const {activeVehicleProfile} = locationStore

        const paymentData = {
            store: cartStore.order.store,
            shoppingCartID: `${cartStore.shoppingCartID}`,
            userID: cartStore.userID,
            totalGross: parseFloat(cartStore.total),
            checkInTime: checkInTime && checkInTime.toISOString(),
            checkOutTime: checkOutTime && checkOutTime.toISOString(),
            orderNumber: cartStore.order.orderNumber,
            paymentNonce: this.braintreeNonce || "INVOICE_ORDER",
            paymentType: this.paymentType,
            class: locationStore.activeVehicleClass || "CAR",
            remark: checkoutStore.data.iWait ? "Ich warte vor Ort auf mein Fahrzeug" : "",
            orderRef,
            carbonCopyRecipients: this.carbonCopyRecipients,
            "vehicle": {
                manufacturerKeyNumber: vehicle && vehicle.hsn && `${vehicle.hsn}`.substr(0, 4),
                typeKeyNumber: vehicle && vehicle.tsn,
                vehicleReferenceId: vehicle && vehicle.referenceId || locationStore.activeVehicleReferenceId,
                vehicleIdentificationNumber: cartStore.getVIN() || (vehicle && vehicle.vehicleIdentificationNumber),
                mileage: activeVehicleProfile.mileage,
                firstRegistration: activeVehicleProfile.firstRegistration ? activeVehicleProfile.firstRegistration : null,
                licensePlate,
                versionKeyNumber: vehicle && vehicle.vsn,
                manufacturer: vehicle && vehicle.manufacturerName
            }
        }

        if (onBehalfOf) paymentData.onBehalfOf = onBehalfOf

        if (this.paymentType === "INVOICE") return this._executePaymentByInvoice(paymentData)

        let url = `${config.API_BASE}/payments`
        if (paymentData.onBehalfOf) {
            url = `${config.API_BASE}/payments/on-behalf-of/`
        }
        this.braintreeNonce = false

        return fetch(url,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "IdentityUserId": sessionStore.identityUserId,
                    "Authorization": sessionStore.authorization
                },
                body: JSON.stringify(paymentData)
            }
        )
            .then(data => this.setBraintreeTransaction(data))
            .catch(err => {
                console.error("Fehler im Payment")
                console.error(err)
                return new Error(err)
            })
    }

    reset() {
        this.braintreePaymentCompleted = false
        this.paymentSuccess = false
        this.paymentMethodRequestable = false
    }

    evaluatePayment(transaction) {
        // // --> console.log( `transaction`, transaction )

        if (transaction.status < 300) {
            return transaction
        } else {
            toast.error(`Transaktionsfehler: ${transaction.statusText ? transaction.statusText : "Keine Fehlermeldung"}, Fehlercode: ${transaction.status}`)
            this.braintreeError = transaction.message
            // fetch new nonce
            this.fetchBraintreeToken()
            return false
        }
    }
}


