// // import sessionStore from "store/sessionStore";

const $fetch = ( url, options ) => {
    let expiry = 5 * 60
    if ( typeof options === 'number' ) {
        expiry = options
        options = undefined
    } else if ( typeof options === 'object' ) {
        // I hope you didn't set it to 0 seconds
        expiry = options.seconds || expiry
    }
    // Use the URL as the cache key to sessionStorage
    let cacheKey = url
    let cached = sessionStore.getItem( cacheKey )
    let whenCached = sessionStore.getItem( cacheKey + ':ts' )
    if ( cached !== null && whenCached !== null ) {
        // it was in sessionStore! Yay!
        // Even though 'whenCached' is a string, this operation
        // works because the minus sign converts the
        // string to an integer and it will work.
        let age = (Date.now() - whenCached) / 1000
        if ( age < expiry ) {
            let response = new Response( new Blob( [cached] ) )
            return Promise.resolve( response )
        } else {
            // We need to clean up this old key
            sessionStore.removeItem( cacheKey )
            sessionStore.removeItem( cacheKey + ':ts' )
        }
    }
    
    return fetch( url, options ).then( response => {
        // let's only store in cache if the content-type is
        // JSON or something non-binary
        if ( response.status === 200 ) {
            let ct = response.headers.get( 'Content-Type' )
            if ( ct && (ct.match( /application\/json/i ) || ct.match( /text\//i )) ) {
                // There is a .json() instead of .text() but
                // we're going to store it in sessionStore as
                // string anyway.
                // If we don't clone the response, it will be
                // consumed by the time it's returned. This
                // way we're being un-intrusive.
                response.clone().text().then( content => {
                    sessionStore.setItem( cacheKey, content )
                    sessionStore.setItem( cacheKey + ':ts', Date.now() )
                } )
            }
        }
        return response
    } )
}

export default $fetch
