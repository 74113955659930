import React, {Component} from "react"
import {Button} from "semantic-ui-react"
import Icon from "utils/Icon"
import {faCheckCircle} from "@fortawesome/pro-light-svg-icons"
import "./AppointmentCard.css"
import moment from "moment";
import {ArrowButton} from "../Button/ArrowButton"

export default class AppointmentCard extends Component {

	render() {
		const {
				  appointment,
				  title
			  }            = this.props
		const notAvailable = appointment.isBoxenstopp && appointment.freeSlots <= 0
		if (notAvailable) {
			return <div className="AppointmentCard pb2">
				<Button tabIndex={0} fluid basic disabled className="AppointmentCard tl">
					<div className={"flex flex-row justify-between items-center"}>
						<div className="tl">
							<div className="f5 b pb1">{moment(appointment.checkInTime).format("HH:mm [Uhr]")}
								– {moment(appointment.checkOutTime).format("HH:mm [Uhr]")}
							</div>
							<div>{moment(appointment.checkInTime).format("ddd DD. MMM")}</div>
							{title && <div>{title}</div>}
						</div>

						<div className={"tl"}>
							{!appointment.isSameDay && <div className="f6 black-60">Abholung {appointment.isSameDay ? "" : "am nächsten Tag"}</div>}
						</div>
						{appointment.isBoxenstopp && <div className={"tr"}>
							<div className={"f5 f4-ns b pb1"}><Icon className={"blue"} icon={faCheckCircle}/> Boxenstopp</div>
							<div className="f6 black-60">Ausverkauft</div>
						</div>}
						{/*<span className="black-50 ttu ">Check-Out</span><br/>*/}
						{/*<span>{appointment.checkOutTime.format( "DD.MM. HH:mm" )}</span><br/>*/}
					</div>
				</Button>
			</div>
		}
		return (<div className="AppointmentCard pb2">
				<ArrowButton tabIndex={0}
							 basic
							 primary={false}
							 onClick={(evt) => {
								 this.props.onClick && this.props.onClick(evt, appointment)
							 }} className="tl p-4 border w-full border rounded-md border-gray-400 shadow-md">
					<div className={"flex flex-row justify-between items-center"}>
						<div className="tl">
							<div className="text-xl font-bold">{moment(appointment.checkInTime).format("HH:mm [Uhr]")}</div>
							<div className="text-sm text-gray-600 uppercase">{moment(appointment.checkInTime).format("DD. MMMM, dddd")}</div>
							{/*<div className="text-sm">*/}
							{/*	{!appointment.isSameDay && <span className="f6 black-60">Abholung {appointment.isSameDay ? "" : "am nächsten Tag"}</span>}*/}
							{/*	{appointment.isSameDay && moment(appointment.checkInTime).format("dddd DD. MMM")}*/}
							{/*</div>*/}
							{title && <div>{title}</div>}
						</div>

						{appointment.isBoxenstopp && <div className={"tr"}>
							<div className={"f5 f4-ns b pb1"}><Icon className={"blue"} icon={faCheckCircle}/> Boxenstopp</div>
							<div className="f6 black-60">Noch {appointment.freeSlots} verfügbar</div>
						</div>}
						{/*<span className="black-50 ttu ">Check-Out</span><br/>*/}
						{/*<span>{appointment.checkOutTime.format( "DD.MM. HH:mm" )}</span><br/>*/}
					</div>
				</ArrowButton>
			</div>)
	}

}
