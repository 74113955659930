import queryString from "query-string"
import React from "react"
import config from "store/store.config"
import { action, observable, makeObservable } from "mobx";
import { toast } from "react-toastify"
import ProductModel from "model/Product"
import _ from "lodash-es"
import shop from "store/shop"

global._ = _ // TODO: Just for testing/debugging

export const seasonLookup = {
    "W": "winter",
    "w": "winter",
    "S": "summer",
    "s": "summer",
    "A": "yearround",
    "a": "yearround",
}

export const seasonKeyLookup = {
    "Winter"   : "W",
    "winter"   : "W",
    "Summer"   : "S",
    "summer"   : "S",
    "yearround": "A",
    "Yearround": "A",
}

export default class TireStore {
    season = "";
    speedIndex = "";
    lastResultLength = false;
    lastResult = false;
    tireMeasures = "";
    criteria = {};
    tireById = new Map();
    tiresByMatchcode = new Map();
    tiresByMatchcodeCompact = new Map();
    qualityByKey = {};
    loading = false;
    criteriaReady = false;
    filterResult = [];

    options = {
        width   : [],
        height  : [],
        diameter: [],
    };

    keys = {};

    constructor() {
        makeObservable(this, {
            season: observable,
            speedIndex: observable,
            lastResultLength: observable,
            lastResult: observable,
            tireMeasures: observable,
            criteria: observable,
            tireById: observable,
            tiresByMatchcode: observable,
            tiresByMatchcodeCompact: observable,
            qualityByKey: observable,
            loading: observable,
            criteriaReady: observable,
            filterResult: observable,
            options: observable,
            keys: observable,
            setFilterResult: action,
            loadCriteria: action,
            loadTire: action,
            search: action,
            searchV2: action,
            loadTires: action,
            loadTiresCompactFromFilterstring: action,
            loadTiresCompact: action
        });
    }

    setFilterResult(tires) {
        this.filterResult = tires
    }

    parseMatchcode( matchcode = "" ) {
        // const matchcode = `${season}${width}${height}${inch}${speedIndex}`
        const season = matchcode.substr( 0, 1 )
        const seasonName = seasonLookup[season]
        const width = matchcode.substr( 1, 3 )
        const height = matchcode.substr( 4, 2 )
        const inch = matchcode.substr( 6, 2 )
        const speedIndex = matchcode.substr( 8, 1 )
        const loadIndex = matchcode.substr( 9, 9 )
        // // --> console.log( "PARSE MATCHCODE", matchcode, { seasonName, season, width, height, inch, loadIndex, speedIndex } )
        return {seasonName, season, width, height, inch, loadIndex, speedIndex}
    }

    loadCriteria({store = locationStore.activeStoreId} = {}) {

        if ( this._criteriaRequested ) return

        this._criteriaRequested = true
        this.criteria = {}
        this.criteria.categories = []
        this.criteria.dimensionsByWidth = new Map()
        this.criteria.dimensionsByHeight = new Map()
        this.criteria.dimensionsByDiameter = new Map()
        this.criteria.qualityByKey = {}

        // return fetch( `/data/criteria/tyres-${store}.json` ) // OLD CALL
        return shop.cachedFetch( `${config.API_BASE}/criterias/tyres` )
            .then( res => {
                this.criteria = res || {}
                // pre-filter dimensions
                this.criteria.dimensionsByWidth = new Map()
                this.criteria.dimensionsByHeight = new Map()
                this.criteria.dimensionsByDiameter = new Map()
                this.criteria.keys = this.criteria.dimensions.map( item => `${item.width}-${item.height}-${item.diameter}` )
                this.criteria.qualityByKey = {}

                this.criteria.qualities.forEach( quality => {
                    this.criteria.qualityByKey[quality.key] = quality
                } )

                this.criteria.speedRatings = _.sortBy( this.criteria.speedRatings, ["maxSpeedKph"] )
                this.criteria.speedRatingsByKey = new Map()

                this.criteria.speedRatings.forEach( rating => {
                    this.criteria.speedRatingsByKey.set( rating.key, rating )
                } )

                this.options.season = _.uniqBy( this.criteria.seasons.map( d => {
                        return {key: `${d.key}`, value: `${d.key}`, text: `${d.displayName}`}
                    } ),
                    e => e.key )

                this.options.width = _.uniqBy( this.criteria.dimensions.map( d => {
                        return {key: `${d.width}`, value: `${d.width}`, text: `${d.width}`}
                    } ),
                    e => e.key )
                    .sort( ( a, b ) => {
                        return Number( a.key ) > Number( b.key ) ? 1 : -1
                    } )

                this.options.height = _.uniqBy( this.criteria.dimensions.map( d => {
                        return {key: `${d.height}`, value: `${d.height}`, text: `${d.height}`}
                    } ),
                    e => e.key )
                    .sort( ( a, b ) => {
                        return Number( a.key ) > Number( b.key ) ? 1 : -1
                    } )

                this.options.diameter = _.uniqBy( this.criteria.dimensions.map( d => {
                        return {key: `${d.diameter}`, value: `${d.diameter}`, text: `${d.diameter}`}
                    } ),
                    e => e.key )
                    .sort( ( a, b ) => {
                        return Number( a.key ) > Number( b.key ) ? 1 : -1
                    } )

                this.criteria.dimensions.forEach( d => {
                    let byWidth = this.criteria.dimensionsByWidth.get( d.width ) || []
                    let byHeight = this.criteria.dimensionsByWidth.get( d.height ) || []
                    let byDiameter = this.criteria.dimensionsByWidth.get( d.diameter ) || []

                    byWidth.push( d )
                    byHeight.push( d )
                    byDiameter.push( d )

                    this.criteria.dimensionsByWidth.set( d.width, byWidth )
                    this.criteria.dimensionsByHeight.set( d.width, byHeight )
                    this.criteria.dimensionsByDiameter.set( d.width, byDiameter )
                    this.criteriaReady = true
                } )
            } )
            .catch( this.handleError )
    }

    loadTire({productID, store = locationStore.activeStoreId}) {
        // --> console.log( "loadTire", productID )
        return shop.cachedFetch( `${config.API_BASE}/products/v2/tyres/${productID}?store=${store}` )
            .then( this.handleResponse )
            .then( tire => {
                if ( !tire || !tire.productID ) return false
                const tireModel = new ProductModel( tire )
                tireModel.productType = "tire"
                // // --> console.log( "TIRE SET", productID, tireModel.productID )
                this.tireById.set( productID, tireModel )
                return tireModel
            } )
            .catch( this.handleErrorToLog )
    }

    isBoxenstop( product ) {
        return product && (product.automaker === "Jaguar" || product.automaker === "Land Rover") && product.availableFromStock
    }

    handleTireResponse( res, matchcode ) {
        let products = res.map( r => {
            let tire = new ProductModel( r )
            this.tireById.set( tire.eanProductID, tire )
            return tire
        } )

        this.tiresByMatchcode.set( matchcode, products )
        this.lastResultLength = res.length
        this.lastResult = products
        this.loading = false
        this.assignCollectionLabels( products )
        return products
    }

    search(
        {width, height, inch, loadIndex, manufacturer, profile, season, quality = null, speedIndex, store = locationStore.activeStoreId}
    ) {
        const matchcode = `${season}${width}${height}${inch}${speedIndex}`
        this.loading = true
        
        return this.searchV2( {width, height, inch, loadIndex, manufacturer, profile, season, quality, speedIndex, store} )

        // return fetch( `${config.API_BASE}/products/tyres?${queryString.stringify( { matchcode, store, quality } )}` )
        //     .then( response => response.json() )
        //     .then( this.handleResponse )
        //     .then( res => this.handleTireResponse(res, matchcode))
        //     .catch( this.handleError )
    }


    searchV2(
        {width, category = {}, height, inch, loadIndex, minLoadIndex, manufacturer, profile, season, quality = null, speedIndex, store = locationStore.activeStoreId}
    ) {
        const matchcode = `${season}${width}${height}${inch}${speedIndex}`
        this.loading = true

        console.log( "TYRES", locationStore.activeStoreId, {store} )
        loadIndex = loadIndex || minLoadIndex

        if ( speedIndex === "Alle" ) speedIndex = null

        return shop.cachedFetch( `${config.API_BASE}/products/v2/tyres?${queryString.stringify( {
                width,
                height,
                diameter     : inch,
                season,
                minLoadIndex : loadIndex,
                minSpeedIndex: speedIndex,
                store,
                category: category?.id || "CAR",
                quality
            } )}` )
            .then( this.handleResponse )
            .then( res => this.handleTireResponse( res, matchcode ) )
            .catch( this.handleError )
    }

    loadTires(
        {matchcode, quality, category = "car", speed, season, store = locationStore.activeStoreId}
    ) {
        // http://localhost:2879/api/products/tyres?matchcode=W1757014H&store=NAGEL_SOHN_VERSMOLD&quality=premium
        let hash = this.hashTireRequestParams( {matchcode, quality, store} )
        // // --> console.log( { hash } )
        let cachedResponse = this.tiresByMatchcode.get( hash )
        if ( cachedResponse ) {
            return Promise.resolve( cachedResponse )
                .then( ( res ) => {
                    this.lastResultLength = res.length
                    return res
                } )
        }

        this.loading = true
        if ( quality === "x" ) quality = undefined

        season = season || matchcode.substr( 0, 1 )
        let width = matchcode.substr( 1, 3 )
        let height = matchcode.substr( 4, 2 )
        let diameter = matchcode.substr( 6, 2 )

        return shop.cachedFetch( `${config.API_BASE}/products/v2/tyres?${queryString.stringify( {category, season, width, height, diameter, store, quality} )}` )
            .then( this.handleResponse )
            .then( res => {
                let products = res.map( r => {
                    let tire = new ProductModel( r )
                    this.tireById.set( tire.eanProductID, tire )
                    return tire
                } )

                this.tiresByMatchcode.set( hash, products )
                this.lastResultLength = res.length
                this.loading = false
                return products
            } )
            .catch( this.handleError )
    }


    assignCollectionLabels = ( products ) => {
        if ( products.length === 0 ) return products

        let cheapestTire = products[0]
        let fuelTire = products[0]
        let gripTire = products[0]
        let silentTire = products[0]

        products.forEach( tire => {
            if ( silentTire.labelNoiseEmissionDB >= tire.labelNoiseEmissionDB ) {
                if ( silentTire.labelNoiseEmissionDB === tire.labelNoiseEmissionDB ) {
                    if ( silentTire.price > tire.price ) silentTire = tire
                } else {
                    silentTire = tire
                }
            }

            if ( gripTire.labelWetGrip >= tire.labelWetGrip ) {
                if ( gripTire.labelWetGrip === tire.labelWetGrip ) {
                    if ( gripTire.price > tire.price ) gripTire = tire
                } else {
                    gripTire = tire
                }
            }

            if ( fuelTire.labelFuelEfficiency >= tire.labelFuelEfficiency ) {
                if ( fuelTire.labelFuelEfficiency === tire.labelFuelEfficiency ) {
                    if ( fuelTire.price > tire.price ) fuelTire = tire
                } else {
                    fuelTire = tire
                }

            }

        } )

        gripTire.isLocalGripChampion = true
        this.tireById.set( gripTire.eanProductID, fuelTire )

        fuelTire.isLocalFuelChampion = true
        this.tireById.set( fuelTire.eanProductID, fuelTire )

        silentTire.isLocalNoiseChampion = true
        this.tireById.set( silentTire.eanProductID, silentTire )

        return products
    }

    loadTiresCompactFromFilterstring({matchcode, quality, store = locationStore.activeStoreId}) {
        // API IS OBSOLETE
        console.error("API IS OBSOLETE: loadTiresCompactFromFilterstring")
        return []
        return shop.cachedFetch( `${config.API_BASE}/products/v1/tyres?${queryString.stringify( {matchcode, store, quality} )}` )
            .then( this.handleResponse )
            .then( res => {
                let products = res.map( r => {
                    let tire = new ProductModel( r )
                    this.tireById.set( tire.eanProductID, tire )
                    return tire
                } )

                this.assignCollectionLabels( products )

                this.loading = false
                return products
            } )
            .catch( this.handleError )
    }

    loadTiresCompact(
        {matchcode, category = "car", quality, speed, season, store = locationStore.activeStoreId}
    ) {

        // API IS OBSOLETE
        console.error("API IS OBSOLETE: loadTiresCompact")
        return []
        if ( quality && quality !== "x" ) return this.loadTires( {matchcode, quality, speed, season, store} )
        let hash = this.hashTireRequestParams( {matchcode, quality, store} )
        this.loading = true
        if ( quality === "x" ) quality = undefined
        return shop.cachedFetch( `${config.API_BASE}/products/v1/tyres/compact?${queryString.stringify( {matchcode, category, store, quality} )}` )
            .then( this.handleResponse )
            .then( res => {

                const premium = new ProductModel( res.premium )
                const budget = new ProductModel( res.budget )
                const defaultTire = new ProductModel( res["default"] )

                // TODO: API might send incomplete list
                budget.eanProductID && this.tireById.set( budget.eanProductID, budget )
                premium.eanProductID && this.tireById.set( premium.eanProductID, premium )
                defaultTire.eanProductID && this.tireById.set( defaultTire.eanProductID, defaultTire )

                let tires = [budget, premium, defaultTire].filter( e => e )
                this.lastResultLength = tires.length
                this.loading = false
                let byQuality = {
                    budget,
                    default: defaultTire,
                    premium,
                }

                this.assignCollectionLabels( tires )
                this.tiresByMatchcodeCompact.set( hash, byQuality )
                return tires
            } )
            .catch( this.handleError )
    }

    hashTireRequestParams = ( {matchcode, quality} ) => `${matchcode}-${quality}`

    handleError = error => {
        console.error( "tireStore::", error )
        toast.error( `Fehler während der Suche.` )
        this.error = error
        this.loading = false
    }

    handleErrorToLog = error => {
        console.error( "tireStore::", error )
        console.error( "TIRE STORE", error )
        this.error = error
        this.loading = false
    }

    handleResponse = response => {
        // --> console.log({response})
        if ( response.status === 404 ) return false
        if ( !response.statusCode || response.statusCode < 400 ) {
            return response
        } else {
            throw(new Error( response.message ))
        }
    }
}
