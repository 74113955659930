import {observer} from "mobx-react"
import React, {Component, useEffect} from "react"
import {Link} from "react-router-dom"
import {Button, Container, Image, Modal} from "semantic-ui-react"
import "./FooterGrey.css"
import Icon from "utils/Icon"
import {faHomeAlt, faLink, faPhone} from "@fortawesome/pro-light-svg-icons"
import {TrustBarFooter} from "component/Trust/TrustBar"
import parseQuery from "utils/parseQuery"
import BrandLogo from "component/Brand/BrandLogo"
import {faComment} from "@fortawesome/free-solid-svg-icons"
import Trustbadge from "page/Campaign/Trustbadge"
import {toast} from "react-toastify"
import {chunk, map} from "lodash-es"
import {prioritizedPrimaryBrands} from "../Brand/SortBrandsPrioritized"
import {faFacebook, faInstagram, faYoutube} from "@fortawesome/free-brands-svg-icons";


function DebugModal({
                        title,
                        displayName,
                        value
                    }) {
    const [active, setActive] = React.useState(false)
    return <div>
        <div>{title}</div>
        <div className="b">{displayName || "-"}</div>
        <Button basic inverted size="tiny" onClick={() => {
            setActive(!active)
        }}>{active ? "HIDE" : "VIEW"}</Button>
        {active && <Modal onClose={() => setActive(false)} open className="f7">
            <div className="ModalHeader p-4 lg:p-6 lg:px-12 border-bottom md:text-2xl font-bold">{title} – {displayName}</div>
            <Modal.Content>
                <pre className="f7"> {JSON.stringify(value, null, 2)}</pre>
            </Modal.Content>
        </Modal>}
    </div>
}

const FooterDebug = observer(() => {
    if (!locationStore.debugMode) return null
    return <div className="bg-red white pv4 ph4 f6">
        <div className="flex flex-row na2 ">
            <div className="px-4">
                <DebugModal title={"Kunde"} displayName={sessionStore.profile?.name} value={sessionStore.profile}/>
            </div>
            <div className="px-4">
                <DebugModal title={"Fahrzeug"} displayName={locationStore.activeVehicleReferenceId} value={locationStore.activeVehicle}/>
            </div>
            <div className="px-4">
                <DebugModal title={"Fahrzeugprofil"} displayName={locationStore.activeVehicleProfileId} value={locationStore.activeVehicleProfile}/>
            </div>
        </div>
    </div>
})

export class BrandSelectionGrey extends Component {

    render() {
        const {hideTrustBadge} = this.props
        const {activeStore = locationStore.stores[0]} = locationStore
        const query = parseQuery(this.props)
        if (!activeStore) return null
        return <div className="BrandSelectionGrey pv4">
            <div className="flex  flex-wrap flex-row-ns justify-start items-center">

                <div className={"ph3 flex flex-row pb4 pb0-ns w-100 w-auto-ns"}>
                    <div className={"dn db-ns"}>
                        <Icon className={"f3 white-60 mr3 "} icon={faHomeAlt}/>
                    </div>

                    <div className="white-80">
                        <div className="b">{activeStore.name} {activeStore.legalForm}</div>
                        <div className="">{activeStore.street} {activeStore.streetNumber}</div>
                        <div className="">{activeStore.postalCode} {activeStore.city}</div>
                    </div>

                </div>
                {/* SHOW ALL BRANDS */}
                {/*{images.map( ( {src, brand} ) => {*/}
                {/*    return <Link key={brand} className={query.manufacturerKey === brand ? "active" : ""} to={`/standort/${locationStore.activeStoreId || "NACARMOS"}/${brand}`}>*/}
                {/*        <Image width={84} src={src} />*/}
                {/*    </Link>*/}
                {/*} )}*/}

                {/*SHOW ACTIVE STORE BRANDS => FIRST BRAND IS "ALLE" */}
                {activeStore.brands
                    .filter(brand => brand !== "Alle")
                    .sort((a, b) => {
                        let brand0 = criteriaStore.manufacturerByKey.get(a.toUpperCase())
                        let brand1 = criteriaStore.manufacturerByKey.get(b.toUpperCase())
                        let rank0 = brand0 && brand0.ranking
                        let rank1 = brand1 && brand1.ranking
                        if (!rank0) rank0 = -Infinity
                        if (!rank1) rank1 = -Infinity
                        if (rank0 > rank1) {
                            return -1
                        } else {
                            return 1
                        }
                    })
                    .map(key => {
                        let brand = key.toUpperCase()
                        return <Link key={brand}
                                     className={`${query.manufacturerKey === brand ? "active" : ""} pb3 pb0-ns`}
                                     to={`/standort/${locationStore.activeStoreId || "NACARMOS"}/${brand}`}>
                            <BrandLogo size="md-sq" width={75} height={75} brand={brand} color={"offwhite"}/>
                        </Link>
                    })}


                <div className={"ml-auto pr4-ns"}>
                    {!hideTrustBadge && <div className="w-100 w-100-m w-auto-ns pb3 pb0-ns tc"><Trustbadge/></div>}
                </div>

            </div>
        </div>
    }
}

const StoreList = observer(() => {
    const {stores} = locationStore
    const chunkStores = chunk(stores, Math.ceil(stores.length / 4))
    return <div className="white ph3-ns bt b--white-10 pv4">
        <div>
            <h4 className="white-50 pb-4">Standorte</h4>
        </div>
        <div className="flex flex-col flex-row-ns justify-between">
            {map(chunkStores, (stores, index) => {

                return <div key={index} className="pb4 pb0-ns ">
                    {/*<h4 className="white-50">{index}</h4>*/}
                    {stores.map(store => {
                        return <Link key={store.key} className={"db white b pv1 f4"} to={`/?store=${store.key}`} onClick={() => {
                            locationStore.setActiveManufacturerKey(false)
                            locationStore.setActiveStoreId(store.key)
                        }}>{store.city}</Link>
                    })}
                </div>
            })}
        </div>
    </div>
})

const BrandList = () => {
    return <div className="white ph3-ns bt b--white-10 bb pv4">
        <div className="flex flex-col flex-col-m flex-row-ns justify-between">
            {prioritizedPrimaryBrands.map(brand => {
                return <Link to={"/"} key={brand} className="white f7 b" onClick={() => {
                    locationStore.setActiveManufacturerKey(brand)
                }}>{brand}</Link>
            })}
        </div>
    </div>
}


const FooterGrey = () => {

    useEffect(() => {
        const script = document.createElement("script");

        script.src = "https://apps.autohauskenner.de/Widget_Js/4222/444093/Widget.js";
        script.async = true;

        document.body.appendChild(script);
    }, [])

    return <footer className="bg-ncg-black  pt-12  md:pt-24 pb-[87px] hidden md:block relative">
        <div className="ui container">

            <div className="absolute top-1 right-1">

                <Button
                    basic
                    inverted
                    onClick={() => {

                        let pathname = window.location.pathname
                        const url = `https://${window.location.host}${pathname}?${locationStore.toQuery()}`
                        navigator.clipboard.writeText(url).then(function () {
                            // console.log( 'CLIPBOARD SUCCESS' );
                            toast.success("Seitenlink kopiert")
                        }, function (err) {
                            console.error("Async: Could not copy text: ", err)
                            toast.info(url)
                        })
                    }}
                    size={"tiny"}>
                    <div className="flex flex-row items-center gap-x-2">
                       <div> Link teilen</div>
                        <Icon icon={faLink}/>
                    </div>
                </Button>

            </div>

            <div className="grid grid-cols-4 gap-4">
                <div className="col-span-3 md:px-12">

                    <h2 className="text-gray-200 border-b pb-0 text-2xl font-bold uppercase mb-3 max-w-[260px]">Standorte</h2>
                    <ul className=" location-list grid grid-cols-1 sm:grid-cols-none sm:grid-rows-5 md:grid-rows-4 gap-[10px] sm:grid-flow-col">
                        <li>
                            <a href="https://auto-nagel.de/standorte/bielefeld" target="" title="Bielefeld"
                               className="text-gray-200 hover:text-white py-4 hover:underline hover:text-white transition-all text-[14px]">
                                Bielefeld
                            </a>
                        </li>

                        <li>
                            <a href="https://auto-nagel.de/standorte/borgholzhausen" target="" title="Borgholzhausen"
                               className="text-gray-200 hover:text-white py-4 hover:underline hover:text-white transition-all text-[14px]">
                                Borgholzhausen
                            </a>
                        </li>

                        <li>
                            <a href="https://auto-nagel.de/standorte/dinslaken" target="" title="Dinslaken"
                               className="text-gray-200 hover:text-white py-4 hover:underline hover:text-white transition-all text-[14px]">
                                Dinslaken
                            </a>
                        </li>

                        <li>
                            <a href="https://auto-nagel.de/standorte/erding" target="" title="Erding"
                               className="text-gray-200 hover:text-white py-4 hover:underline hover:text-white transition-all text-[14px]">
                                Erding
                            </a>
                        </li>

                        <li>
                            <a href="https://auto-nagel.de/standorte/essen" target="" title="Essen" className="text-gray-200 hover:text-white py-4 hover:underline hover:text-white transition-all text-[14px]">
                                Essen
                            </a>
                        </li>

                        <li>
                            <a href="https://auto-nagel.de/standorte/hamm" target="" title="Hamm" className="text-gray-200 hover:text-white py-4 hover:underline hover:text-white transition-all text-[14px]">
                                Hamm
                            </a>
                        </li>

                        <li>
                            <a href="https://auto-nagel.de/standorte/hannover" target="" title="Hannover"
                               className="text-gray-200 hover:text-white py-4 hover:underline hover:text-white transition-all text-[14px]">
                                Hannover
                            </a>
                        </li>

                        <li>
                            <a href="https://auto-nagel.de/standorte/kempen" target="" title="Kempen"
                               className="text-gray-200 hover:text-white py-4 hover:underline hover:text-white transition-all text-[14px]">
                                Kempen
                            </a>
                        </li>

                        <li>
                            <a href="https://auto-nagel.de/standorte/mainburg" target="" title="Mainburg"
                               className="text-gray-200 hover:text-white py-4 hover:underline hover:text-white transition-all text-[14px]">
                                Mainburg
                            </a>
                        </li>

                        <li>
                            <a href="https://auto-nagel.de/standorte/muenster-amelsbueren" target="" title="Münster Amelsbüren"
                               className="text-gray-200 hover:text-white py-4 hover:underline hover:text-white transition-all text-[14px]">
                                Münster Amelsbüren
                            </a>
                        </li>

                        <li>
                            <a href="https://auto-nagel.de/standorte/muenster-hafen" target="" title="Münster Hafen"
                               className="text-gray-200 hover:text-white py-4 hover:underline hover:text-white transition-all text-[14px]">
                                Münster Hafen
                            </a>
                        </li>

                        <li>
                            <a href="https://auto-nagel.de/standorte/nettetal" target="" title="Nettetal"
                               className="text-gray-200 hover:text-white py-4 hover:underline hover:text-white transition-all text-[14px]">
                                Nettetal
                            </a>
                        </li>

                        <li>
                            <a href="https://auto-nagel.de/standorte/neufahrn" target="" title="Neufahrn"
                               className="text-gray-200 hover:text-white py-4 hover:underline hover:text-white transition-all text-[14px]">
                                Neufahrn
                            </a>
                        </li>

                        <li>
                            <a href="https://auto-nagel.de/standorte/osnabrueck" target="" title="Osnabrück"
                               className="text-gray-200 hover:text-white py-4 hover:underline hover:text-white transition-all text-[14px]">
                                Osnabrück
                            </a>
                        </li>

                        <li>
                            <a href="https://auto-nagel.de/standorte/steinfurt" target="" title="Steinfurt"
                               className="text-gray-200 hover:text-white py-4 hover:underline hover:text-white transition-all text-[14px]">
                                Steinfurt
                            </a>
                        </li>

                    </ul>

                    <div className="pt-12 pb-10">

                        <h2 className="text-gray-200 border-b pb-0 text-2xl font-bold uppercase mb-3 max-w-[260px]">Marken</h2>
                        <ul className="location-list grid grid-cols-1 sm:grid-cols-none sm:grid-rows-3 md:grid-rows-5 lg:grid-rows-6 gap-[10px] sm:grid-flow-col">


                            <li>
                                <a href="https://auto-nagel.de/audi" target="" title="Audi" className="text-gray-200 hover:text-white py-4 hover:underline hover:text-white transition-all text-[14px]">
                                    Audi
                                </a>
                            </li>

                            <li>
                                <a href="https://auto-nagel.de/baic" target="" title="BAIC" className="text-gray-200 hover:text-white py-4 hover:underline hover:text-white transition-all text-[14px]">
                                    BAIC
                                </a>
                            </li>

                            <li>
                                <a href="https://www.mercedes-benz-trucks-vm.de" target="_blank" title="Daimler Trucks"
                                   className="text-gray-200 hover:text-white py-4 hover:underline hover:text-white transition-all text-[14px]">
                                    Daimler Trucks
                                </a>
                            </li>

                            <li>
                                <a href="https://auto-nagel.de/dfsk" target="" title="DFSK" className="text-gray-200 hover:text-white py-4 hover:underline hover:text-white transition-all text-[14px]">
                                    DFSK
                                </a>
                            </li>

                            <li>
                                <a href="https://auto-nagel.de/ford" target="" title="Ford" className="text-gray-200 hover:text-white py-4 hover:underline hover:text-white transition-all text-[14px]">
                                    Ford
                                </a>
                            </li>

                            <li>
                                <a href="https://auto-nagel.de/fuso" target="" title="Fuso" className="text-gray-200 hover:text-white py-4 hover:underline hover:text-white transition-all text-[14px]">
                                    Fuso
                                </a>
                            </li>

                            <li>
                                <a href="https://auto-nagel.de/jaguar" target="" title="Jaguar" className="text-gray-200 hover:text-white py-4 hover:underline hover:text-white transition-all text-[14px]">
                                    Jaguar
                                </a>
                            </li>

                            <li>
                                <a href="https://auto-nagel.de/land-rover" target="" title="Land Rover"
                                   className="text-gray-200 hover:text-white py-4 hover:underline hover:text-white transition-all text-[14px]">
                                    Land Rover
                                </a>
                            </li>

                            <li>
                                <a href="https://auto-nagel.de/lynk" target="" title="Lynk &amp; Co" className="text-gray-200 hover:text-white py-4 hover:underline hover:text-white transition-all text-[14px]">
                                    Lynk &amp; Co
                                </a>
                            </li>

                            <li>
                                <a href="https://auto-nagel.de/man" target="" title="MAN" className="text-gray-200 hover:text-white py-4 hover:underline hover:text-white transition-all text-[14px]">
                                    MAN
                                </a>
                            </li>

                            <li>
                                <a href="https://auto-nagel.de/maserati" target="" title="Maserati" className="text-gray-200 hover:text-white py-4 hover:underline hover:text-white transition-all text-[14px]">
                                    Maserati
                                </a>
                            </li>

                            <li>
                                <a href="https://auto-nagel.de/mercedes-benz" target="" title="Mercedes-Benz"
                                   className="text-gray-200 hover:text-white py-4 hover:underline hover:text-white transition-all text-[14px]">
                                    Mercedes-Benz
                                </a>
                            </li>

                            <li>
                                <a href="https://auto-nagel.de/mg" target="" title="MG" className="text-gray-200 hover:text-white py-4 hover:underline hover:text-white transition-all text-[14px]">
                                    MG
                                </a>
                            </li>

                            <li>
                                <a href="https://auto-nagel.de/opel" target="" title="Opel" className="text-gray-200 hover:text-white py-4 hover:underline hover:text-white transition-all text-[14px]">
                                    Opel
                                </a>
                            </li>

                            <li>
                                <a href="https://auto-nagel.de/polestar" target="" title="Polestar" className="text-gray-200 hover:text-white py-4 hover:underline hover:text-white transition-all text-[14px]">
                                    Polestar
                                </a>
                            </li>

                            <li>
                                <a href="https://auto-nagel.de/seres" target="" title="Seres" className="text-gray-200 hover:text-white py-4 hover:underline hover:text-white transition-all text-[14px]">
                                    Seres
                                </a>
                            </li>

                            <li>
                                <a href="https://auto-nagel.de/sirius-anhaenger" target="" title="Sirius Anhänger"
                                   className="text-gray-200 hover:text-white py-4 hover:underline hover:text-white transition-all text-[14px]">
                                    Sirius Anhänger
                                </a>
                            </li>

                            <li>
                                <a href="https://auto-nagel.de/smart" target="" title="Smart" className="text-gray-200 hover:text-white py-4 hover:underline hover:text-white transition-all text-[14px]">
                                    Smart
                                </a>
                            </li>

                            <li>
                                <a href="https://auto-nagel.de/triumph" target="" title="Triumph" className="text-gray-200 hover:text-white py-4 hover:underline hover:text-white transition-all text-[14px]">
                                    Triumph
                                </a>
                            </li>

                            <li>
                                <a href="https://auto-nagel.de/volkswagen" target="" title="Volkswagen"
                                   className="text-gray-200 hover:text-white py-4 hover:underline hover:text-white transition-all text-[14px]">
                                    Volkswagen
                                </a>
                            </li>

                            <li>
                                <a href="https://auto-nagel.de/volvo" target="" title="Volvo" className="text-gray-200 hover:text-white py-4 hover:underline hover:text-white transition-all text-[14px]">
                                    Volvo
                                </a>
                            </li>

                        </ul>
                    </div>


                </div>


                <div id="kenner-widget"></div>
            </div>


            <div>
                <ul className="hidden md:flex sub-footer pt-8 uppercase text-xs  flex-row items-center">

                    <li>
                        <a href="https://www.auto-nagel.de/shop/cms/agb/" target="" title="AGB"
                           className="pr-4 text-gray-400 hover:underline hover:text-white transition-all text-xs">
                            AGB
                        </a>
                    </li>

                    <li>
                        <a href="https://auto-nagel.de/datenschutz" target="" title="Datenschutz"
                           className="pr-4 text-gray-400 hover:underline hover:text-white transition-all text-xs">
                            Datenschutz
                        </a>
                    </li>

                    <li>
                        <a href="https://auto-nagel.de/hinweisgeberschutz" target="" title="Hinweisgeberschutz"
                           className="pr-4 text-gray-400 hover:underline hover:text-white transition-all text-xs">
                            Hinweisgeberschutz
                        </a>
                    </li>

                    <li>
                        <a href="https://auto-nagel.de/impressum" target="" title="Impressum"
                           className="pr-4 text-gray-400 hover:underline hover:text-white transition-all text-xs">
                            Impressum
                        </a>
                    </li>

                    <li>
                        <a href="https://auto-nagel.de/kontakt" target="" title="Kontakt" className="pr-4 text-gray-400 hover:underline hover:text-white transition-all text-xs">
                            Kontakt
                        </a>
                    </li>

                    <li>
                        <a href="https://www.auto-nagel.de/shop/cms/versand-und-zahlung/" target="" title="Versand"
                           className="pr-4 text-gray-400 hover:underline hover:text-white transition-all text-xs">
                            Versand
                        </a>
                    </li>

                    <li>
                        <a href="https://www.auto-nagel.de/shop/cms/widerruf/" target="" title="Widerruf"
                           className="pr-4 text-gray-400 hover:underline hover:text-white transition-all text-xs">
                            Widerruf
                        </a>
                    </li>


                    <li className="ml-auto">
                        <a rel="noreferrer" href="https://instagram.com/autonagel/" target="_blank" title="Instagram" className="text-xl text-white hover:text-gray-300  transition-all px-3">
                            <Icon icon={faInstagram}/>
                        </a>
                    </li>

                    <li>
                        <a rel="noreferrer" href="https://www.facebook.com/autonagel/" target="_blank" title="Facebook" className="text-xl text-white hover:text-gray-300  transition-all px-3">
                            <Icon icon={faFacebook}/>
                        </a>
                    </li>

                    <li>
                        <a rel="noreferrer" href="https://www.youtube.com/@autonagel" target="_blank" title="YouTube" className="text-xl text-white hover:text-gray-300  transition-all px-3">
                            <Icon icon={faYoutube}/>
                        </a>
                    </li>


                    <li className="mt-[-21px] pl-4">
                        <img alt="Passion für Mobilität" width={608 / 4} src="/shop/img/passion-for-mobility-w.png"/>

                    </li>
                </ul>
            </div>

        </div>
    </footer>
}


const FooterGreyOld = ({
                           store,
                           hideTrustBadge,
                           className = ""
                       }) => {
    store = store || locationStore.activeStore
    return <footer className={`FooterGrey no-print ${className}`}>
        <FooterDebug/>

        <div className="f6 bg-ncg-black text-white ">
            <Container>
                {store && <div className="pv2 flex flex-row justify-between bb b--white-10 ">
                    <div className="md:w-auto w-full flex flex-row justify-center items-center">
                        {store.phone && <a className={"white-60 dib pr3"} href={`tel:${store.phone.slice(4)}`}>
                            <Icon icon={faPhone}/><span className="dib pl2 ttu"></span> {store.phone}
                        </a>}
                        {!store.phone &&
                            <a className={"white-60 dib pr3"} href="tel:05425-93207-51"> <Icon icon={faPhone}/><span className="dib pl2 ttu"> </span> 05425 93207
                                51</a>}

                        <Link className="white-60 dn dib-ns pa2 ph3" to={"/standorte"}>Filiale Suchen</Link>
                        <Link className="white-60 dn dib-ns pa2 ph3" to={`/standorte`}>Marken</Link>
                        {locationStore.activeManufacturerKey &&
                            <Link className="white-60 pa2 ph3 dn dib-ns" to={locationStore.getPath("/", {vehicleModal: "modal"})}>Fahrzeuge</Link>}
                    </div>

                    <div className="hidden md:block ml-auto">
                        <Button
                            inverted
                            basic
                            onClick={() => {
                                window.LeadBooster.trigger("open")
                            }}
                            size={"small"}><Icon icon={faComment}/> Service Chat starten</Button>
                        <Button
                            basic
                            inverted
                            onClick={() => {

                                let pathname = window.location.pathname
                                const url = `https://${window.location.host}${pathname}?${locationStore.toQuery()}`
                                navigator.clipboard.writeText(url).then(function () {
                                    // console.log( 'CLIPBOARD SUCCESS' );
                                    toast.success("Seitenlink kopiert")
                                }, function (err) {
                                    console.error("Async: Could not copy text: ", err)
                                    toast.info(url)
                                })
                            }}
                            size={"small"} icon={<Icon icon={faLink}/>}/>
                    </div>

                    {/*<div className={"dn db-ns"}>*/}
                    {/*    <Link className="black-60 dib pa2 ph3" to={"/"}>Räder & Reifen</Link>*/}
                    {/*    <Link className="black-60 dib pa2 ph3" to={"/"}>Werkstatt-Termine</Link>*/}
                    {/*    <Link className="black-60 dib pa2 ph3" to={"/"}>Räderwechsel</Link>*/}
                    {/*    <Link className="black-60 dib pa2 ph3 pr0" to={"/"}>Inspektionen</Link>*/}
                    {/*</div>*/}
                </div>}

            </Container>
        </div>

        <div className="bg-ncg-black pb4-ns overflow-hidden f6 pt3 hidden md:block">
            {/*<Container>*/}
            {/*  <Grid columns={5} stackable doubling>*/}
            {/*    <Grid.Row>*/}
            {/*      /!*<Grid.Column>*!/*/}
            {/*      /!*    <div className="pa3 w-100 white-80 bg-transparent pointer hover-white transition-color">*!/*/}
            {/*      /!*        <div><span className="b">VIN/HSN</span> <Icon icon={faArrowCircleRight}/></div>*!/*/}
            {/*      /!*        <div>Direkt zu Ihrem Fahrzeug</div>*!/*/}
            {/*      /!*    </div>*!/*/}
            {/*      /!*</Grid.Column>*!/*/}
            {/*      */}
            {/*      <Grid.Column>*/}
            {/*        <Link to={"/account"} className="pa3 w-100 white-80 bg-transparent pointer hover-white transition-color">*/}
            {/*          <div className="tc tl-ns lh-title">*/}
            {/*            <div><span className="b">Kundenkonto</span> <Icon icon={faArrowCircleRight} /></div>*/}
            {/*            <div>Mit Ihrem Fahrzeug weiter</div>*/}
            {/*          </div>*/}
            {/*        </Link>*/}
            {/*      </Grid.Column>*/}
            {/*      */}
            {/*      <Grid.Column>*/}
            {/*        <Link to={"#"} className="pa3 w-100 white-80 bg-transparent pointer hover-white transition-color" onClick={() => {*/}
            {/*          window.LeadBooster.trigger( "open" )*/}
            {/*        }}>*/}
            {/*          <div className="tc tl-ns lh-title">*/}
            {/*            <div><span className="b">Service Chat</span> <Icon icon={faArrowCircleRight} /></div>*/}
            {/*            <div>Fragen Sie einen Mitarbeiter</div>*/}
            {/*          </div>*/}
            {/*        </Link>*/}
            {/*      </Grid.Column>*/}
            {/*    </Grid.Row>*/}
            {/*  </Grid>*/}
            {/*  */}
            {/*  <Divider />*/}

            {/*</Container>*/}


            <div className="pb4 pt4">


                <Container>

                    <div className="dn db-ns">
                        <TrustBarFooter/>
                    </div>

                    {/*<div className="pv3-ns pt4-ns dn db-ns">*/}
                    {/*    <BrandSelectionGrey hideTrustBadge={hideTrustBadge}/>*/}
                    {/*</div>*/}

                </Container>
            </div>

            <div className="dn db-ns">
                <Container>
                    <div>
                        <StoreList/>
                    </div>
                </Container>

                <Container>
                    <div>
                        <BrandList/>
                    </div>
                </Container>
            </div>

        </div>

        {/*<Container>*/}
        {/*    <ServiceHotline />*/}
        {/*    <div className="pt2">*/}
        {/*        <Grid stackable columns={2}>*/}
        {/*            <Grid.Row>*/}
        {/*                <Grid.Column>*/}
        {/*                    <div className="ServiceHotline flex items-center h-100 pt0-ns pt4 flex-col flex-row-ns">*/}
        {/*                        <Image src={logoGrey} height="40" className={"mr3 pb4 pb0-ns"} />*/}
        {/*                        <div className="f7 black-50"> 2019 Nagel Car Group. Alle Preise inkl. MwSt.</div>*/}
        {/*                    </div>*/}
        {/*                </Grid.Column>*/}
        {/*                <Grid.Column>*/}
        {/*                    <div className="tc fr-ns footer-menu">*/}
        {/*                        <Menu secondary text vertical>*/}
        {/*                            <Menu.Item as={Link} to="/cms/agb/" target="_blank">AGB</Menu.Item>*/}
        {/*                            /!*<Menu.Item as={Link} to="/cms/agb/" target="_blank">Kontakt</ Menu.Item>*!/*/}
        {/*                            <Menu.Item as={Link} to="/cms/widerruf/" target="_blank">Widerruf</ Menu.Item>*/}
        {/*                            <Menu.Item as={Link} to="/cms/versand-und-zahlung/" target="_blank">Versand</ Menu.Item>*/}
        {/*                            <Menu.Item as={"a"} href="https://www.auto-nagel.de/startseite/datenschutz.html" target="_blank">Datenschutz</Menu.Item>*/}
        {/*                            <Menu.Item as={"a"} href="https://www.auto-nagel.de/startseite/impressum.html" target="_blank">Impressum</Menu.Item>*/}
        {/*                        </Menu>*/}
        {/*                    </div>*/}
        {/*                </Grid.Column>*/}
        {/*            </Grid.Row>*/}
        {/*        </Grid>*/}
        {/*    </div>*/}

        {/*</Container>*/}
        <div className="bg-ncg-black pb-8 md:pb-32 md:pb-0 ">
            <Container>

                <div className="tl pv3 pb3-ns tc-ns footer-menu flex flex-wrap flex-row-ns items-center ">
                    <div className="mr-auto  flex-col flex-row-ns w-100 ph2 tc tl-ns flex pb-24 md:pb-0">
                        <LegalLinks className="hidden md:block"/>
                        <div className="flex-1 flex flex-row justify-end-ns justify-center pt-8 md:pt-0">
                            <Image alt={"Passion für Mobilität"} src={"/shop/img/passion-for-mobility-w.png"} width={152} height={45}/>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    </footer>
}


export function LegalLinks({
                               className = "",
                               spacing = false
                           }) {
    return <div className={`LegalLinks ${className}`}>
        <Link className={`f7  white-50 ttu pa3 ${spacing ? "pl4-l" : ""} `} as={Link} to="/cms/agb/" target="_blank">AGB</Link>
        <Link className={`f7  white-50 ttu pa3 ${spacing ? "pl4-l" : ""} `} as={Link} to="/cms/widerruf/" target="_blank">Widerruf</ Link>
        <Link className={`f7  white-50 ttu pa3 ${spacing ? "pl4-l" : ""} `} as={Link} to="/cms/versand-und-zahlung/" target="_blank">Versand</ Link>
        <a className={`f7  white-50 ttu pa3 ${spacing ? "pl4-l" : ""} `}
           href="https://www.auto-nagel.de/startseite/datenschutz.html"
           rel="noopener noreferrer"
           target="_blank">Datenschutz</a>
        <a className={`f7  white-50 ttu pa3 ${spacing ? "pl4-l" : ""} `}
           href="https://www.auto-nagel.de/startseite/impressum.html"
           rel="noopener noreferrer"
           target="_blank">Impressum</a>
    </div>
}

export default FooterGrey
