import React from "react"

import allYearSrc from "icons/snow-sun-flake-132x132.png"
import sommerSrc from "icons/sommer.svg"
import winterSrc from "icons/winter.svg"

import engineSrc from "icons/engine.svg"
import floorSrc from "icons/floor.svg"
import starsSrc from "icons/stars.svg"
import carwashSrc from "icons/carwash.svg"
import platformSrc from "icons/platform.svg"
import uvvSrc from "icons/uvv.svg"
import huSrc from "icons/hu.svg"

import toolsSrc from "icons/tools.svg"
import hotelSrc from "icons/hotel-b.svg"
import mechanicSrc from "icons/mechanic.svg"
import diskBreakSrc from "icons/disc-brake.svg"
import windshieldSrc from "icons/windshield.svg"
import batterySrc from "icons/battery.svg"
import carkeySrc from "icons/carkey.svg"
import rentalCarSrc from "icons/rental-car.svg"
import tiresSrc from "icons/three-wheels.svg"

const Picto = ({src, ...rest}) => {
  const attributes = {
    width: 32,
    src,
    ...rest
  }
  return <img  {...attributes} />
}

Picto.Summer = (props) => <Picto src={sommerSrc} {...props} />
Picto.Winter = (props) => <Picto src={winterSrc} {...props} />
Picto.AllYear = (props) => <Picto src={allYearSrc} {...props} />

Picto.Engine = (props) => <img src={engineSrc}  {...props} />
Picto.Floor = (props) => <img src={floorSrc}  {...props} />
Picto.Stars = (props) => <img src={starsSrc}  {...props} />
Picto.Carwash = (props) => <img src={carwashSrc}  {...props} />
Picto.Platform = (props) => <img src={platformSrc}  {...props} />
Picto.UVV = (props) => <img src={uvvSrc}  {...props} />
Picto.HU = (props) => <img src={huSrc}  {...props} />
Picto.Mechanic = (props) => <img src={mechanicSrc}  {...props} />
Picto.DiskBreak = (props) => <img src={diskBreakSrc}  {...props} />
Picto.Windshield = (props) => <img src={windshieldSrc}  {...props} />
Picto.Battery = (props) => <img src={batterySrc}  {...props} />
Picto.CarKey = (props) => <img src={carkeySrc}  {...props} />
Picto.RentalCar = (props) => <img src={rentalCarSrc}  {...props} />
Picto.Tires = (props) => <img src={tiresSrc}  {...props} />
Picto.Tools = (props) => <img src={toolsSrc}  {...props} />
Picto.Hotel = (props) => <img src={hotelSrc}  {...props} />

export default Picto
