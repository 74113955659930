import React from "react"
import Icon from "utils/Icon"
import {Label} from "semantic-ui-react"

import "./BadgeAvailability.css"
import {faCheck, faTruck} from "@fortawesome/pro-light-svg-icons"

const TEXT_AVAILABLE = "Sofort verfügbar"
const TEXT_NOT_AVAILABLE = "Abholung 3-5 Werktage"

const BadgeAvailability = ( { product } ) => <div className="BadgeAvailability">
    {product.availableFromStock &&
    <Label className={"availability"} color='green' ribbon="right">
        {TEXT_AVAILABLE}
    </Label>}
    {!product.availableFromStock &&
    <Label className={"availability"} color='blue' ribbon="right">{getDeliveryText( product )}</Label>}
</div>

const Availability = ( { product } ) => <div className="Availability">
    {product.deliveryDateText ? <>
      <span className={"black-70"}><Icon icon={faTruck} /> {product.deliveryDateText}</span>
    </> : <>
        {product.availableFromStock && <span><Icon icon={faCheck}/> {TEXT_AVAILABLE}</span>}
        {!product.availableFromStock && <span className={"black-70"}><Icon icon={faTruck} /> {getDeliveryText( product )}</span>}
    </>}
</div>

const getDeliveryText = product => {
    let  deliveryPeriodInDaysMin = product.deliveryPeriodInDaysMin
    let  deliveryPeriodInDaysMax = product.deliveryPeriodInDaysMax

    if(product.deliveryPeriodInDaysMin === undefined ){
        console.warn(`Keine Lieferzeiten für Produkt`, product)
        deliveryPeriodInDaysMin = 8
    }

    if(product.deliveryPeriodInDaysMax === undefined ){
        deliveryPeriodInDaysMax = 10
    }

    return `${deliveryPeriodInDaysMin} - ${deliveryPeriodInDaysMax} Werktage`
}


export default BadgeAvailability
export { Availability }
