import React from "react"

const placeTrustedBadge = ( id ) => {
    if ( window.trustedShopScriptHasExecuted ) return
    
    var _tsid = 'X18F029389254B64897B5C6CC02693B43';
    window[ "_tsConfig" ] = {
        'yOffset'           : '0', /* offset from page bottom */
        'variant'           : 'custom_reviews', /* default, reviews, custom, custom_reviews */
        'customElementId'   : id, /* required for variants custom and custom_reviews */
        'trustcardDirection': '', /* for custom variants: topRight, topLeft, bottomRight, bottomLeft */
        'customBadgeWidth'  : '120px', /* for custom variants: 40 - 90 (in pixels) */
        'customBadgeHeight' : '120px', /* for custom variants: 40 - 90 (in pixels) */
        'disableResponsive' : 'true', /* deactivate responsive behaviour */
        'disableTrustbadge' : 'false' /* deactivate trustbadge */
    };
    var _ts = document.createElement( 'script' );
    _ts.type = 'text/javascript';
    _ts.charset = 'utf-8';
    _ts.async = true;
    _ts.src = '//widgets.trustedshops.com/js/' + _tsid + '.js';
    var __ts = document.getElementsByTagName( 'script' )[ 0 ];
    __ts.parentNode.insertBefore( _ts, __ts );
    window.trustedShopScriptHasExecuted = true
}

export default class Trustbadge extends React.Component {
    
    componentDidMount() {
        placeTrustedBadge( this.props.id || "trustBadgeId" )
    }
    
    render() {
        const { id = "trustBadgeId" } = this.props
        return <div id={id} className="flex">
            &nbsp;
        </div>
    }
}
