


const QUERY_SERVICES = `query ($location:String, $brand: String, $vehicleType: VehicleType){
                labourPackages: getPackageServices(serviceType: labourPackage,  location: $location, brand: $brand) {
                id
                brand
                name
                sortOrder
                tags
                shortName
                rating {
                  amount
                  average
                }
                services {
                  included
                  excluded
                }
                labourTimesGroup
                categories{
                  mainCategory{
                    id
                    name
                  }
                  subCategory{
                    id
                    name
                  }
                }
                workDuration
                workPositionNumber
                vehicleType
                image
                text
                videoId
                pricing {
                  priceFlag
                  availableDays
                  location
                  brand
                  price
                  srp
                }
                  }
                  
              getPackageServices(brand: $brand, location: $location, vehicleType: $vehicleType ) {
                id
                brand
                sortOrder
                name
                tags
                shortName
                rating {
                  amount
                  average
                }
                services {
                  included
                  excluded
                }
                categories{
                  mainCategory{
                    id
                    name
                  }
                  subCategory{
                    id
                    name
                  }
                }
                workDuration
                workPositionNumber
                vehicleType
                image
                text
                videoId
                pricing {
                  priceFlag
                  availableDays
                  location
                  brand
                  price
                  srp
                }
              }
            }
`
export default QUERY_SERVICES
