import React, {useEffect, useState} from "react"
import {observer} from "mobx-react"
import {Button, Divider, Form, Grid, Image, Input} from "semantic-ui-react"
import ShopBreadcrumb from "component/Shop/ShopBreadcrumb"
import {Link} from "react-router-dom"
import Footer from "component/Footer"
import ShopContainer, {ShopSidebarContext} from "component/Shop/ShopContainer"
import currency from "utils/currency"
import CartPreviewMinimal, {CartLinks} from "component/Cart/CartPreviewMinimal"
import {faArrowLeft, faMinus, faPlus, faTimes} from "@fortawesome/pro-light-svg-icons"
import Icon from "../../utils/Icon"
import Picto from "../../component/Picto"
import {sidebarStates} from "../../store/uiStore"


export const MAX_CUSTOMER_REMARK_LENGTH = 256


const sortByPrice = (a, b) => {
	if (a.price > b.price) return -1
	if (a.price < b.price) return 1
	return 0
}

function PositionRow({
	className,
	basic,
	showRemove,
	position,
	canEdit = true
}) {

	const [count, setCount] = useState()
	const changeCount       = (count) => {
		cartStore.addPosition(position, Math.round(Math.max(0, count)))
		setCount(position.quantity)
	}

	const savePositionRemark = (customerRemark) => {
		// cartStore.addCustomServiceProduct( productTitle, "Reparaturwunsch", comment )
		position.metafield                = position.metafield || {}
		position.metafield.customerRemark = customerRemark.substring(0,MAX_CUSTOMER_REMARK_LENGTH)
		cartStore.save()
		setCount(position.quantity)
	}

	const showImage                       = true // position.type !== "SRV" // index === 0 && position.type !== "SRV"
	const imgSrc                          = position && position.images && position.images.length >= 1 && position.images[0]
	const supportsTransparency            = false // imgSrc && imgSrc.includes( ".png" )
	const [remark, setRemark]             = useState(position.metafield?.customerRemark || "")
	const [remarkEditor, setRemarkEditor] = useState(false)

	const Element = position.href ? Link : "div"
	return <>
		<div className={`lg:col-span-3 md:col-span-2 col-span-6 w-full  ${className}`}>
			<div className="flex flex-row">

				{!basic && <Element
					to={position.href}
					className="w-16 h-16">
					{showImage && <React.Fragment>
						{imgSrc && <div className={`pb3 ${supportsTransparency ? "" : "bg-white pt3"} mb3`}>
							<Image alt={"Produktabbildung"}
								   centered
								   style={{
									   maxHeight: "160px",
									   maxWidth:  "50.33%"
								   }}
								   src={imgSrc}
								   alt=""/>
						</div>}
						{!imgSrc && <div className="flex flex-row items-center justify-center h-100">
							<Picto.Mechanic width={32} className={"opacity-50"}/>
						</div>}
					</React.Fragment>}
				</Element>}
				<div
					className="pl-4 text-gray-800">
					<div
						className="text-sm text-gray-400">Artikelnr. {position.productIDOriginal || position.productID}</div>
					<div className="font-bold">{position.shortName || position.name} <span
						className="font-normal"></span></div>

					{canEdit && <>
						{!remarkEditor && <button onClick={() => setRemarkEditor(true)}>
							{position.metafield?.customerRemark &&
								<div className="f6 pl2 bl b--black-20 lh-title mb2 mt1">
									<div
										className="f7 black-40 tracked-narrow i">{position.metafield.customerRemark}</div>
								</div>}
							{!position.metafield?.customerRemark && <div className="f6 lh-title mb2 mt1">
								<div className="f6 blue hover:underline tracked-narrow">Kommentar hinzufügen</div>
							</div>}
						</button>}
					</>}

					{remarkEditor && <div>
						<Form.Input
							className="quicksearch-comment"
							placeholder="Anmerkung hinzufügen"
							fluid
							// rows={1 }
							size="mini"
							value={remark}
							onChange={(e) => {
								const value = e.currentTarget.value
								if (value.length < 120) setRemark(value)
								// checkoutStore.data.comment = comment
							}}/>

						<div className="pt1 w-full">
							{/* <label htmlFor="i" className="f7 black-50">Anmerkung hinzufügen (optional)</label> */}
							<div className="flex flex-row justify-end">
								<Button.Group size="tiny">
									<Button
										type="button"
										onClick={() => {
											setRemark(position.metafield?.customerRemark || "")
											setRemarkEditor(false)
										}} basic size="tiny"
									>Abbrechen</Button>
									<Button
										type="button"
										onClick={() => {
											savePositionRemark(remark)
											setRemarkEditor(false)
										}} size="tiny" primary
									>Speichern</Button>
								</Button.Group>
							</div>
						</div>
					</div>}
				</div>
			</div>
		</div>

		<div className="md:col-span-1 col-span-1 hidden flex-row items-top md:flex">
			<div className="pt-3">
				{position.hasDiscount && <div className="text-sm text-gray-500 strike">{currency(position.recommendedRetailPriceGrossInEur)}</div>}
				<div className={`text-lg font-bold ${position.hasDiscount && "text-red-500"}`}>{currency(position.priceGrossInEur,
																										2,
																										position.priceText || "kostenlos")}</div>
			</div>
		</div>

		<div className="lg:col-span-1 md:col-span-2 col-span-4 flex flex-col items-top">
			{canEdit &&
				<div className="flex flex-row items-center md:justify-start sm:justify-center md:ml-0 sm:ml-auto">
					<Button style={{margin: 0}} basic icon={<Icon icon={faMinus}/>} onClick={() => {
						changeCount(position.quantity - 1)
					}}/>
					<div className="mx-2"><Input
						className="text-center"
						value={position.quantity} style={{width: "62px"}}
						onChange={(e) => {
							changeCount(e.currentTarget.value)
						}}/></div>
					<Button style={{margin: 0}} basic icon={<Icon icon={faPlus}/>} onClick={() => {
						changeCount(position.quantity + 1)
					}}/>
				</div>}
		</div>
		<div className="md:col-span-1 col-span-2">
			<div className="flex flex-row items-center">
				<div className="pr-2 text-right w-full">
					{position.hasDiscount &&
						<div
							className="text-sm text-gray-500 strike"> {currency(position.recommendedRetailPriceGrossInEur * position.quantity)}</div>}

					{position.quantity > 1 &&
						<div
							className={`text-sm text-gray-700 md:hidden ${position.hasDiscount && "text-red-500"}`}> Stückpreis {currency(position.priceGrossInEur)}</div>}
					<div
						className={`text-lg font-bold whitespace-pre ${position.hasDiscount && "text-red-500"}`}>{currency(position.priceGrossInEur * position.quantity,
																														   2,
																														   position.priceText || "kostenlos")}</div>


				</div>
				{showRemove && <div className="ml-auto pl-4">
					<Button
						basic
						style={{marginRight: 0}}
						icon={<Icon icon={faTimes}/>}
						className={"p-4 text-xl"}
						onClick={() => {
							cartStore.removePosition(position)
							setCount(0)
						}}/>
				</div>}
			</div>
		</div>
		<div className="md:hidden md:pb-0 pb-2 mb-2 border-b col-span-4 col-start-2"></div>
	</>
}


const Positions = observer(() => <div className="Positions">
	<div className="grid grid-cols-2 sm:grid-cols-6 gap-4 mt-8 mb-8">

		<div className="hidden md:block lg:col-span-3 md:col-span-2 col-span-2">&nbsp;</div>
		<div className="hidden md:block col-span-1 text-gray-400">Stückpreis</div>
		<div className="hidden md:block lg:col-span-1 md:col-span-2 col-span-2 text-gray-400">Menge</div>
		<div className="hidden md:block col-span-1 text-gray-400">Gesamtpreis</div>

		{cartStore.products
			.slice()
			.sort(sortByPrice)
			.map(position => {
				return <>
					<PositionRow position={position} key={position.productID} showRemove/>
					{position.associatedProducts && <>
						{position.associatedProducts
							.slice()
							.sort(sortByPrice)
							.map(subPosition => {
								return <PositionRow
									key={subPosition.productID}
									canEdit={position.type === "WPK" || position.type === "TYR"}
									basic
									className={"bl border-gray-300 mt-2 ml-8 pl-8"}
									position={subPosition}/>
							})}
						<div className="md:hidden col-span-4">&nbsp;</div>
					</>}
				</>
			})}

	</div>
	<div className="py-4 border-top flex flex-row justify-end items-center f4 b">
		<div className="pr-2"></div>
		<Button as={Link} to={"/services"} size={"small"} style={{marginRight: 0}} circular>
			<div className="flex flex-row items-center">
				<span className="font-bold">Leistung hinzufügen</span>
				<Icon className={"ml-2"} icon={faPlus}/>
			</div>
		</Button>
	</div>
</div>)


const CartSummaryShort = observer(({hideTitle = false}) => <Grid stackable>
	<Grid.Row>

		<Grid.Column width={11}>
			{!hideTitle && <div className="pr4 ph3 ph0-ns pt3 pt0-ns">
				<h1 className={"f2 b ma0"}>Warenkorb</h1>
				<Link to={"/werkstatt/"} className="mt-2 text-gray-800 uppercase text-xs tracking-wide font-bold"><Icon
					className="text-sm mr-2"
					icon={faArrowLeft}/> Weiter
					Einkaufen</Link>
				{/*<div className={"black-60 f5 pb4"}>{cartStore.products.length} {cartStore.products.length !== 1 ? "Produkte" : "Produkt"}</div>*/}
			</div>}
		</Grid.Column>

		<Grid.Column width={5}>
			<div className="pb-4  ">
				<Divider/>
				<div className={"f4 black flex flex-row justify-between pt2"}>
					<div className="">
						<div>Gesamtsumme:</div>
						<div className="black-60 f6">inkl. MwSt.</div>
					</div>
					<div className=""><span className="b">{currency(cartStore.total)}</span></div>
				</div>
				<CartLinks showCouponInput={false} className={"pt3"}/>
			</div>

		</Grid.Column>
	</Grid.Row>
</Grid>)

function CartPage(props) {

	const flag = 0
	useEffect(() => {
		uiStore.setSidebarState(sidebarStates.HIDDEN)
	}, [flag	])

	const {path}       = props.match
	const {groupID}    = props.match.params
	const store        = locationStore.activeStore
	const groupDetails = serviceStore.serviceGroupDetailsById.get(groupID)
	const {profile}    = sessionStore
	if (!store) return <Button loading/>
	const baseUrl = locationStore.baseUrl
	return (<div className="BranchServicePage bg-white">

		<ShopBreadcrumb>
			<Link to={locationStore.getPath("/cart/")}>Warenkorb</Link>
		</ShopBreadcrumb>

		<ShopContainer
			fluid
			className={"overflow-hidden px-4"}
		>
			<div className="pt3 pt0-ns mx-auto ">

				<ShopSidebarContext>
					<div className="h-100">
						<div className="h3-mb3-old unused-spacing-element" />
						<div className="w-100">
							<CartPreviewMinimal showStandardTitle />
							<CartLinks toCart />
						</div>
					</div>
				</ShopSidebarContext>
				<Grid>
					<Grid.Row>
						<Grid.Column width={11}>
							<div className="pr4 ph3 ph0-ns pt3 pt0-ns">
								<h1 className={"f2 b ma0"}>Warenkorb</h1>
								<Link to={"/werkstatt/"}
									  className="mt-2 text-gray-800 uppercase text-xs tracking-wide font-bold"><Icon
									className="text-sm mr-2"
									icon={faArrowLeft}/> Weiter
									Einkaufen</Link>
								{/*<div className={"black-60 f5 pb4"}>{cartStore.products.length} {cartStore.products.length !== 1 ? "Produkte" : "Produkt"}</div>*/}
							</div>
						</Grid.Column>

						<Grid.Column width={5}>
							{/*<div className="pb-4  ">*/}
							{/*    <Divider />*/}
							{/*    <div className={"f4 black flex flex-row justify-between pt2"}>*/}
							{/*        <div className="">*/}
							{/*            <div>Gesamtsumme:</div>*/}
							{/*            <div className="black-60 f6">inkl. MwSt.</div>*/}
							{/*        </div>*/}
							{/*        <div className=""><span className="b">{currency( cartStore.total )}</span></div>*/}
							{/*    </div>*/}
							{/*    <CartLinks showCouponInput={false} className={"pt3"} />*/}
							{/*</div>*/}
						</Grid.Column>
					</Grid.Row>
				</Grid>

				<Positions/>

				{!profile && <div className={"p-8 border border-gray-200"}>
					<Link to={"/cart/?login=modal&referrer=%2Fcart"} className={"hover:underline font-bold"}> Melden Sie
						sich
						an</Link> um Ihren Warenkorb zu speichern oder
					bisherige Warenkörbe wieder herzustellen.
				</div>}

				<CartSummaryShort hideTitle/>

				{/*<div className="text-xs">*/}
				{/*    <pre>{JSON.stringify( cartStore.products, null, 2 )}</pre>*/}
				{/*</div>*/}


			</div>
		</ShopContainer>

		<div className="">
			<Footer/>
		</div>

	</div>)
}

export default observer(CartPage)
