import React, {Component} from "react"
import {Appointment, sortByDate} from "store/appointmentStore"
import {toast} from "react-toastify"
import {Button, Segment} from "semantic-ui-react"
import AppointmentCardGroup from "component/AppointmentCardGroup"
import moment from "moment"
import _ from "lodash-es"

let lastRes = false
let lastKey = false

export function reserveAppointmentSlot(id, reserved = 0) {
    let url = `https://api.sheety.co/c244d864e64e67ddbf0def8c44c07969/nagelStopkaDe/tkp/${id}`
    let body = {
        tkp: {
            reserved: reserved + 1
        }
    }
    fetch(url, {
        method: "PUT",
        body: JSON.stringify(body),
        headers: {
            "content-type": "application/json"
        }
    })
        .then((response) => response.json())
        .then(json => {
        })
}

window.reserveAppointmentSlot = reserveAppointmentSlot

// function fetchAppointmentSlots(storeKey) {
//     return []
//     if (lastRes && lastKey === storeKey) {
//         return lastRes
//     }
//     let url = "https://api.sheety.co/c244d864e64e67ddbf0def8c44c07969/nagelStopkaDe/tkp"
//     const momentNow = moment()
//     lastKey = storeKey
//     lastRes = fetch(url)
//         .then((response) => response.json())
//         .then(json => {
//             let appointments = json.tkp
//                 .filter(s => {
//                     const termin = moment(s.checkInTime, "MM/DD/YYYY hh:mm:ss")
//                     return momentNow.isBefore(termin)
//                 })
//                 .map((s, index) => {
//                     return {
//                         slotId: s.id,
//                         _checkInTime: s.checkInTime,
//                         _checkOutTime: s.checkOutTime,
//                         isBoxenstopp: true,
//                         availability: s.availability,
//                         reserved: s.reserved,
//                         storeKey: s.storeKey,
//                         freeSlots: s.availability - (s.reserved || 0),
//                         checkInTime: moment(s.checkInTime, "MM/DD/YYYY hh:mm:ss").toISOString(),
//                         checkOutTime: moment(s.checkOutTime, "MM/DD/YYYY hh:mm:ss").toISOString()
//                     }
//                 })
//                 .filter(f => f.storeKey === storeKey)
//
//
//             let dates = appointments.map(event => new Appointment({event}))
//                 .sort(sortByDate)
//             const byDate = _.groupBy(dates, "startOfDayString")
//             // lastKey = false
//             return {
//                 appointments,
//                 byDate,
//                 boxenstoppAppointments: true,
//                 store: storeKey
//             }
//
//         })
//     return lastRes
// }

export class Appointments extends Component {

    state = {
        appointments: {},
        busy: false,
        hash: false
    }

    handleAppointmentSelection = (data, appointment) => {
        this.props.onSelect({appointment})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.hash !== this.props.hash) {
            this.fetchAppointments(this.props.hash)
        } else {
            // DO NOTHING
        }
    }

    componentDidMount() {
        this.fetchAppointments(this.props.hash)
    }

    async fetchAppointments(hash) {
        this.setState({busy: true})
        let {
            favoredCheckinDate,
            favoredCheckinTime,
            wantsAssistance,
            wantsReplacementVehicle
        } = this.props
        const {hasFixedDeliveryDates} = cartStore

        favoredCheckinDate = favoredCheckinDate || cartStore.getMinDeliveryDate
        // console.log( "FETCH APPOINTMENTS", { favoredCheckinDate } )
        if (!cartStore.hasShoppingCartID) {
            await cartStore.save()
        }

        const brand = locationStore.activeVehicle?.manufacturerName || locationStore.activeManufacturerKey
        try {
            let appointments = await appointmentStore
                .getAppointmentsForCart({
                    storeKey: locationStore.activeStoreId,
                    dontExpandRangeOnFailure: this.props.dontExpandRangeOnFailure || false,
                    brand,
                    isBoxenstopp: this.props.boxenstopp,
                    cartId: cartStore.shoppingCartID,
                    from: moment(favoredCheckinDate).toISOString(),
                    until: moment(favoredCheckinDate).add(this.props.singleDayMode ? 1 : 7, "days").toISOString(),
                    vehicleClass: locationStore.activeVehicleClassIdRequestKey || "CAR",
                    wantsAssistance: this.props.wantsAssistance || false,
                    wantsReplacementVehicle: this.props.wantsReplacementVehicle || checkoutStore.data.wantsReplacementVehicle || false,
                })
            this.setState({
                appointments,
                busy: false
            })
        } catch (err) {
            console.error("Fehler bei der Terminabfrage  (V2)")
            console.error(err)
            toast.warn("Fehler bei der Terminabfrage (V2)")
            this.setState({
                busy: false,
                error: true
            })
        } finally {
            this.setState({hash})
        }


    }

    render() {
        const {className = ""} = this.props
        const {
            appointments,
            slots,
            busy,
            error
        } = this.state
        const hasResult = appointments && (this.state.hash === this.props.hash)

        // console.log("APPPOINTMENTS", this.props, {hasResult, appointments})
        return (<div className={`Appointments ${className}`}>
            {busy && (<React.Fragment>
                <div className="animate fadeInUp delay3">
                    <Segment loading>
                        <div className="pa5  tc text-sm text-gray-500">
                            <Button basic loading/>
                        </div>
                    </Segment>
                </div>
            </React.Fragment>)}


            {!busy && !error && appointments?.timeSlots?.length === 0  && (<Segment>Keine Termine gefunden</Segment>)}

            {hasResult && <AppointmentCardGroup
                hideSecondary={this.props.hideSecondary}
                highlightDay={this.props.highlightDay}
                itemsPerRow={1}

                appointments={appointments.byDate}
                onSelect={this.handleAppointmentSelection}
            />}

        </div>)
    }
}
