const BASE = process.env.REACT_APP_API_URL

const API_KEY = process.env.REACT_APP_PIMCORE_KEY || "6fad107720155a70d6d052e690af3d88"
const PIMCORE_URL = process.env.REACT_APP_PIMCORE_URL || process.env.REACT_APP_API_URL + "/pimcore"

const config = {
    // API_BASE: "http://178.208.99.236:9000"
    API_BASE:           `${BASE}/api`,
    URL_BASE:           `${BASE}/`,
    LOGIN_CLIENT_ID:    `ncgprod`,
    LOGIN_REDIRECT_URI: `${document.location.origin}/redirect/login`,
    PIMCORE_URL,
    store: false
}


// TODO
export default config
