import {action, makeObservable, observable} from "mobx"


export const sidebarStates = {
	HIDDEN:  "HIDDEN",
	VISIBLE: "VISIBLE",
	MINI:    "MINI",
}

const filterDefaults = {
	"sort": "availability",
	"category": "all",
	"groupId": "H70",
	"description": "store",
	"subCategoryId": "U204",
	"subCategorySlug": "Merchandise"
}
export default class UiStore {

	constructor() {
		makeObservable(this, {
			sidebarState:    observable,
			setSidebarState: action,
			filterState:     observable,
			setFilterState:  action,
			showSearch:      observable,
		})

		this.filterState = filterDefaults
	}

	sidebarState = sidebarStates.HIDDEN
	showSearch = true

	setSidebarState = (showCartSidebar) => {
		this.sidebarState = showCartSidebar
	}

	setShowSearch = (showSearch) => {
		this.showSearch = showSearch
	}

	filterState = {}

	setFilterState = (name, value, history ) => {
		const filter     = {...this.filterState}
		filter[name]     = value
		this.filterState = filter
		if (history) {
			history.push("./" + "?" + new URLSearchParams(filter).toString())
		}
	}
}
