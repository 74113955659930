import {ServiceProductModel, stripHtml} from "./ServiceProductModel";
import {slugify} from "../utils/slugify";
import uniq from "lodash/uniq"


export default class StoreProductModel extends ServiceProductModel {


	variantProducts = []

	constructor({mainProduct, variantFields, variants}) {
		super(mainProduct);
		this.variantFields = variantFields
		this.activeVariants = {}
		this.variants = variants

		this.variantFields = this.variantFields.map( (field) => {
			let values = this.variants.map( v => {
				if(Array.isArray(v[field.name])) return v[field.name].join(", ")
				return v[field.name]
			})
				.filter(v => v)
			const hasDifferentValues = uniq(values).length > 1
			return {...field, hasDifferentValues}
		})

		//console.log("NEW P", this)
		this.href         = `/werkstatt/details/H70/store/${this.subCategoryId}/${slugify(this.subCategoryName || "subcategory")}/${this.id}/${this.slug}/`
		this.categoryHref = `/werkstatt/details/H70/store/${this.subCategoryId}/${slugify(this.subCategoryName || "subcategory")}/`
		this.text         = this.descriptionText = stripHtml(this.summaryHtml)

		this.minPrice = this.pricing.reduce((min, p) => p.price && p.price < min ? p.price : min, this.pricing[0].price)
		this.minOriginalPrice = this.pricing.reduce((min, p) => p.originalPrice && p.originalPrice < min ? p.originalPrice : min, this.pricing[0].originalPrice)
		this.isAvailableSomewhere = this.pricing.some(e => e.stock > 0 && e.price > 0)

		this.cartDescription = this.variantFields.map(e => this[e.name]).filter(e => e === "true").join(", ")
		// TODO: TESTING SERVERS
		// this.image        = this.image && `${this.image}`.replace("nagel.staging.pretzelhands.com", "nagel.store")

		// TODO:
		// for
		// testing																								 // testing
		// this.images       = this.images.map(e => e.replace("nagel.staging.pretzelhands.com", "nagel.store"))

		this.updateLocation(locationStore.activeStoreId)
	}

	updateLocation(locationId) {
		super.updateLocation(locationId)
		this._currentStoreKey = locationId
		this.price = this.pricing.find(e => e.location === locationId)?.price
		this.originalPrice = this.pricing.find(e => e.location === locationId)?.originalPrice
		this.stock = this.pricing.find(e => e.location === locationId)?.stock
		this.isAvailable = this.stock > 0 && this.price > 0
		// TODO
		this.price = this.minPrice
	}
}
