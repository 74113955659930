import React from "react"


const Text = ( { as: Component = "div", className, children } ) => <Component className={`${className}`}>{children}</Component>


Text.Heading = ( { as: Component = "div", className, children } ) => <Component className={`f4 fw7 white  pb3 ${className}`}>{children}</Component>
Text.H4 = ( { as: Component = "div", className, children } ) => <Component className={`f4 fw4   pb3 ${className}`}>{children}</Component>
Text.H3 = ( { as: Component = "div", className, children } ) => <Component className={`f3 fw4   pb3 ${className}`}>{children}</Component>
Text.H2 = ( { as: Component = "div", className, children } ) => <Component className={`f2 fw4   pb3 ${className}`}>{children}</Component>
Text.H1 = ( { as: Component = "div", className, children } ) => <Component className={`f1 fw4   pb3 ${className}`}>{children}</Component>


export default Text
