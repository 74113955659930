import React, {Component} from "react"
import {NavLink, withRouter} from "react-router-dom"
import {Container, Grid, Menu} from "semantic-ui-react"
import {observer} from "mobx-react"
import "./Footer.css"
import Text from "pattern/Text"
import FooterGrey from "component/Footer/FooterGrey"

var url = document.location.href
url = url.split( "/" )[2] // Get the hostname


class FooterFromStore extends Component {

    render() {
        return <div>
            <FooterGrey store={this.props.store} hideTrustBadge={this.props.hideTrustBadge}/>
        </div>
    }
}

class Footer extends Component {

    render() {
        
        const store = this.props.store ||  locationStore.storeById.get( locationStore.activeStoreId )
        if ( store ) return <FooterFromStore store={store}  hideTrustBadge={this.props.hideTrustBadge}/>
        if(!store) return null
        const {Filiale, Adresse, Telefon, Facebook} = store
        const adressFormated = Adresse.split( "|" )
            .map( line => <div key={line}>{line}</div> )
        return (
            <div>
                <div className="black-70 tc f6">Alle Preiseangaben inkl. 19%MwSt.</div>
                <div className="Footer white pt6-ns pv5 no-print">
                    <Container>
                        <Grid stackable>
                            <Grid.Row columns={3}>
                                <Grid.Column>
                                    <Text.Heading as="h4" className="pb4 " inverted>{Filiale}</Text.Heading>
                                    <div className="pb4">{adressFormated}
                                        <br />
                                        {Telefon}
                                    </div>
                                    <p>
                                        <img src={"/shop/img/logo_w.png"} width={112} alt="NagelCarGroup" />
                                    </p>

                                </Grid.Column>
                                <Grid.Column>
                                    <Text.Heading as="h4" className="pb4 " inverted>Folgen Sie uns </Text.Heading>
                                    <Menu text borderless inverted secondary vertical>
                                        <Menu.Item target="_blank" as={NavLink} exact to={Facebook}>Facebook</Menu.Item>
                                        {/*<Menu.Item target="_blank" as={NavLink} exact to={Youtube}>Youtube</Menu.Item>*/}
                                    </Menu>

                                </Grid.Column>
                                <Grid.Column>
                                    <Text.Heading as="h4" className="pb4 " inverted>Über {store.name}</Text.Heading>
                                    <Menu text borderless inverted secondary vertical>
                                        <Menu.Item target="_blank" as={NavLink} exact
                                                   to={store.imprintUrl}>Impressum</Menu.Item>
                                        <Menu.Item target="_blank" as={NavLink} exact
                                                   to={store.dataProtectionUrl}>Datenschutz</Menu.Item>
                                        <Menu.Item as={NavLink} exact to={`/cms/agb`}> AGB </Menu.Item>
                                        <Menu.Item as={NavLink} exact
                                                   to={`/cms/widerruf`}> Widerrufsbelehrung</Menu.Item>
                                        <Menu.Item as={NavLink} exact to={`/cms/versand-und-zahlung`}> Versand und
                                                                                                       Zahlung</Menu.Item>
                                    </Menu>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Container>
                </div>
            </div>
        )
    }
}

export default withRouter(observer(Footer));
