import _ from "lodash-es"
import vehicleServiceStore from "./vehicleServiceStore"
import {toast} from "react-toastify"
import {sidebarStates} from "../../store/uiStore";


export default class ServiceRecommendationModel {

	priceMinByQuality = {}
	priceByQuality    = {}
	valid             = true

	constructor(parent, recommendation) {
		let maxPrice   = 0
		const services = recommendation.serviceIds
			.map(e => e.id)
			.map(serviceId => {
				let service = parent.serviceById[serviceId]
				if (!service) return false
				this.valid = service.status === "ok" || service.status === "OK"

				const p = service.variants?.[0]?.priceGross
				maxPrice += Number(p)
				return service
			})
			.filter(e => e)

		this.recommendationId = recommendation.serviceIds.map(e => e.id).join("-")
		// PRICES FOR EACH RECOMMENDATIONS QUALITY LEVEL
		let priceByQuality    = {}
		services.forEach(service => {
			const qualityLevels = vehicleServiceStore.qualityLevels
			qualityLevels.forEach(type => {
				priceByQuality[type] = priceByQuality[type] || 0
				let qualityService   = service.variants.find((subService) => subService.type === type)
				if (qualityService) {
					const {
							  isMaintenanceService,
							  isRecommended
						  } = qualityService.service
					// console.log(type, isMaintenanceService, isRecommended, qualityService.priceGross, {qualityService})
					if (isMaintenanceService ) priceByQuality[type] = priceByQuality[type] + qualityService.priceGross
				}
			})
		})

		// Pick THE MIN PRICE
		Object.entries(priceByQuality).forEach(entry => {
			const [quality, price]          = entry
			this.priceMinByQuality[quality] = Math.min(this.priceMinByQuality[quality] || Infinity, maxPrice) || 0
		})

		Object.assign(this, {
			...recommendation,
			price:    maxPrice,
			priceAll: maxPrice,
			services
		})
		this.displayName    = this.services?.[0]?.name || "Inspektion"
		this.priceByQuality = priceByQuality
	}

}
