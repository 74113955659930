const GTM = true

function parseProduct( product ) {
    return {                        //  adding a product to a shopping cart.
        'name'    : product.name,
        'id'      : `${product.productID}`,
        'price'   : product.priceGrossInEur,
        'brand'   : product.tyreManufacturer,
        'category': product.type,
        'variant' : product.season,
        'quantity': product.quantity
    }
}


export default class Track {

    static postTrackJSMessage(){
        fetch("https://capture.trackjs.com/capture?token=b42f7edc394e44658d07862a1afb20d5", {
            method: "POST",
            body: JSON.stringify({
                "console": [{
                    "message": "a console message",
                    "severity": "log",
                    "timestamp": "2018-11-27T12:11:20.932Z"
                }],
                "customer": {
                    "application": "app-prod",
                    "correlationId": "123",
                    "sessionId": "",
                    "token": "b42f7edc394e44658d07862a1afb20d5",
                    "userId": "system@nagel-stopka.de",
                    "version": ""
                },
                "entry": "direct",
                "version": "3.0.0"
            })
        })
    }

    static purchase( { bill, products } ) {
        if ( !GTM || !window.dataLayer ) return
        // --> console.log( "TRACK purchase", { bill, products } )
        window.dataLayer.push( {
            'event'    : 'purchase',
            'ecommerce': {
                'purchase': {
                    'actionField': {
                        'id'         : bill.payment.orderNumber,                         // Transaction ID. Required for purchases and refunds.
                        'affiliation': bill.payment.store ? bill.payment.store.storeKey : "Online Store",
                        'revenue'    : bill.payment.totalPrice,                     // Total transaction value (incl. tax and shipping)
                        'tax'        : bill.payment.totalValueAddedTax,
                        'shipping'   : '0',
                    },
                    'products'   : products.map( parseProduct )
                }
            }
        } );
    }

    static checkout( { products, step = 1, option = "" } ) {
        if ( !GTM || !window.dataLayer ) return
        // --> console.log( "TRACK checkout", { products } )
        window.dataLayer.push( {
            'event'    : 'checkout',
            'ecommerce': {
                'checkout': {
                    'actionField': { 'step': step, 'option': option },
                    'products'   : products.map( parseProduct )
                }
            }
        } );
    }


    static viewDetails( { product, list } ) {
        if ( !GTM || !window.dataLayer ) return
        // --> console.log( "TRACK viewDetails", { product } )
        window.dataLayer.push( {
            'event'    : 'viewDetail',
            'ecommerce': {
                'detail': {
                    'actionField': { 'list': `${list ? list : ""}` },    // 'detail' actions have an optional list property.
                    'products'   : [parseProduct( product )]
                }
            }
        } )
    }

    static removeProduct( { product } ) {
        if ( !GTM || !window.dataLayer ) return
        // --> console.log( "TRACK removeProduct", { product } )

        window.dataLayer.push( {
            'event'    : 'removeFromCart',
            'ecommerce': {
                'currencyCode': 'EUR',
                'remove'      : {                                // 'add' actionFieldObject measures.
                    'products': [parseProduct( product )]
                }
            }
        } );

    }

    static addProduct( { product } ) {
        if ( !GTM || !window.dataLayer ) return
        if ( !product ) return
        // --> console.log( "TRACK addProduct", { product } )

        window.dataLayer.push( {
            'event'    : 'addToCart',
            'ecommerce': {
                'currencyCode': 'EUR',
                'add'         : {                                // 'add' actionFieldObject measures.
                    'products': [{                        //  adding a product to a shopping cart.
                        'name'    : product.title,
                        'id'      : `${product.article_nr}`,
                        'price'   : product.price,
                        'brand'   : product.brand,
                        'category': product.category && product.category.title,
                        'variant' : product.variant,
                        'quantity': product.count
                    }]
                }
            }
        } );

        // window.ga( 'gtm1.ec:addProduct', {               // Provide product details in a productFieldObject.
        //   'id':         'P12345',                   // Product ID (string).
        //   'name':       'Android Warhol T-Shirt', // Product name (string).
        //   'category':   'Apparel',            // Product category (string).
        //   'brand':      'Google',                // Product brand (string).
        //   'variant':    'Black',               // Product variant (string).
        //   'position':   1,                    // Product position (number).
        //   'dimension1': 'Member'            // Custom dimension (string).
        // } );
        // window.ga( 'gtm1.ec:setAction', 'add' );
        // window.ga( 'gtm1.send', 'event', 'UX', 'click', 'add to cart' );
    }

}


