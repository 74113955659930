import { action, observable, makeObservable } from "mobx"
import Product from "../component/Product/Product"

const API_KEY = process.env.REACT_APP_PIMCORE_KEY || "6fad107720155a70d6d052e690af3d88"
const PIMCORE_URL = process.env.REACT_APP_PIMCORE_URL || process.env.REACT_APP_API_URL + "/pimcore"
const API_URL = process.env.REACT_APP_API_URL

export default class ProductStore {
    productsById = new Map()

    constructor() {
        makeObservable( this, {
            productsById           : observable,
            getBrandByProduct      : action,
            fetchProduct           : action,
            fetchPackageService    : action,
            fetchMaintenanceService: action
        } )
    }

    async getBrandByProduct( productId ) {
        if ( !productId ) return false
        let product = await this.fetchProduct( productId )

        return product?.brand || product?.automaker
    }

    async fetchProduct( productId ) {
        let product = null

        const productType = productId.slice( 0, 3 )
        switch (productType) {
            case "SRV":
                product = await this.fetchPackageService( productId )
                break

            case "PRD":
                product = await this.fetchMaintenanceService( productId )
                break

            case "CST":
                product = await this.fetchCustomService( productId )
                break
        }

        return product
    }

    async fetchPackageService( productId ) {
        if ( this.productsById.has( productId ) ) {
            return this.productsById.get( productId )
        }

        const res = await fetch(
            `${PIMCORE_URL}/pimcore-graphql-webservices/packageServices?apikey=${API_KEY}`,
            {
                method : "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept        : "application/json"
                },
                mode: "cors",
                body   : JSON.stringify( {
                    query: `query {
                        getPackageServices(id: "${productId}") {
                            slug
                            brand
                            workPositionNumber
                            categories {
                                mainCategory {
                                    id
                                }
                            }
                        }
                    }`
                } )
            }
        )

        const results = await res.json()
        const product = results.data.getPackageServices[ 0 ]

        this.productsById.set( productId, product )
        return product
    }


    //TODO: UNTESTED @
    async fetchCustomService( productId ) {
        if ( this.productsById.has( productId ) ) {
            return this.productsById.get( productId )
        }

        const res = await fetch(
            `${PIMCORE_URL}/pimcore-graphql-webservices/packageServices?apikey=${API_KEY}`,
            {
                method : "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept        : "application/json"
                },
                body   : JSON.stringify( {
                    query: `
                    query {
                         getCustomProducts(ids: "${productId}") {
                            id
                            customId
                            name
                            customType: type
                            description
                            price
                            vendorNumber
                            store
                            brand
                            image
                            categories {
                              fullName
                              mainCategory {
                                name
                              }
                              subCategory {
                                name
                              }
                            }
                          }
                        }
                    `
                } )
            }
        )

        const results = await res.json()
        const product = new Product(results.data.getCustomProducts[ 0 ])

        this.productsById.set( productId, product )

        return product
    }

    // TODO: THIS FAILS IN PRODUCTION
    async fetchMaintenanceService( productId, store = "NAGEL_SOHN_BORGHOLZHAUSEN" ) {
        if ( this.productsById.has( productId ) ) {
            return this.productsById.get( productId )
        }

        try{
            const res = await fetch(
                `${PIMCORE_URL}/api/service-variants?store=${store}&service[]=${productId}`,
                {
                    mode: "cors",
                    method : "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Accept        : "application/json"
                    }
                }
            )

            const result = await res.json()
            const [product] = result

            this.productsById.set( productId, product )
        } catch(err){
            return null
        }


    }
}
