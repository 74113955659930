import moment from "moment";


export default class StoreModel {
	constructor(data) {
		Object.assign(this, data)


		const today    = moment()
		// sort campaigns
		this.campaigns = this.campaigns.sort((a, b) => {
			return moment(a.from).isAfter(b.from) ? 1 : -1
		})

		this.futureCampaigns = this.campaigns
			.filter(campaign => {
				return !moment(campaign.to).isBefore(today);
			})

		this.repairClasses.forEach(repairClass => {
			if (repairClass === "SMALLTRAILERS") {
				this.repairClasses.push("TRAILER_HORSE")
				this.repairClasses.push("CARAVAN")
			}
		})

	}
}
