import { action, observable, makeObservable } from "mobx";

const DEFAULT_STORE = false

// const DEFAULT_STORE = "AUTOHAUS_NAGEL_ERDING"

export default class CheckoutStore {
    activeIndex = 0;
    activeSegmentId = false;
    activeSegment = false;
    segments = [];
    segmentById = [];

    constructor() {
        makeObservable(this, {
            activeIndex: observable,
            activeSegmentId: observable,
            activeSegment: observable,
            segments: observable,
            segmentById: observable,
            setActiveSegmentById: action,
            setActiveSegmentByIndex: action,
            nextSegment: action,
            addCheckoutSegment: action,
            resetSegments: action,
            vehicle: observable,
            needsCar: observable,
            car: observable,
            data: observable,
            setSteps: action,
            assign: action
        });
    }

    setActiveSegmentById(segmentId) {
        try {
            this.activeSegment = this.segmentById[segmentId]
            this.activeIndex = this.activeSegment.index
            this.activeSegmentId = this.activeSegment.segmentId
        } catch ( err ) {
            this.setActiveSegment( 0 )
        }
    }

    setActiveSegmentByIndex(index) {
        this.activeSegment = this.segments[index]
        this.activeIndex = index
        this.activeSegmentId = this.activeSegment.segmentId
    }d

    nextSegment() {
        this.setActiveSegmentByIndex( this.activeIndex + 1 )
    }

    addCheckoutSegment(segmentId, segment) {
        segment.index = this.segments.length
        this.segmentById[segmentId] = segment
        this.segments.push( segment )
        if ( !this.activeSegmentId ) {
            this.setActiveSegmentByIndex( 0 )
        }
        return this.segments.length
    }

    resetSegments() {
        this.segments = []
        this.segmentById = {}
        this.activeIndex = 0
        this.activeSegment = false
        this.activeSegmentId = false
    }

    vehicle = false;
    needsCar = false;
    car = false;

    data = {
        storeId: DEFAULT_STORE,
        formData: {}
    };

    setSteps(steps) {
        this.steps = steps
    }

    assign(data) {
        // --> console.log( "checkoutStore:assign", {data} )
        if ( data.car ) {
            this.car = data.car
        }

        this.data = Object.assign( this.data, data )
        console.log("Adding to checkout:", data, this.data)
    }
}
export class Step {
    title
    isDefault = false
    component = null

    constructor( {title, isDefault, component, validate} ) {
        this.title = title
        this.isDefault = isDefault
        this.component = component
        this.validate = validate
    }
}
