export class VehicleModel {
    
    constructor( data ) {
        this._vehicleModel = true
        Object.assign( this, data )
        this.kbaNumber = `${this.hsn}${this.tsn}`.toUpperCase()
    }
    
    toJSON() {
        const obj = { ...this }
        delete obj.summary
        return obj
    }
    
}
