import React from "react"

import {Carousel} from "react-responsive-carousel"
import ImageWithFallback from "component/ImageWithFallback"

const ProductCarousel = ({ images }) => (
  <Carousel infiniteLoop={false} showThumbs={false} showArrows={true} showIndicators={true} showStatus={false}>
    {images && images.map( src => <ImageWithFallback key={`img-${src}`} fallbackSrc="/shop/img/wheel-placeholder.png" src={src}/> )}
  </Carousel>
)

export default ProductCarousel


