import React from "react"

const AttributeSmall = ( {value, label} ) => (
    <div className="attribute-button tl pr3">
        <div className={"ttu black-60 f6"}>{label}</div>
        <div className={"black-60 b"}>{value}</div>
    </div>
)

const ProductOverviewRow = ( {product} ) => (
    <div className="flex flex-row pb3">
        <AttributeSmall label="Zoll" value={product.rimDiameter} />
        <AttributeSmall label="Breite" value={<span>{product.frontAxle[0].tyreWidth}/{product.rearAxle[0].tyreWidth}</span>} />
        <AttributeSmall label="Speed-Index" value={<span>{product.maxKmPerHour} km/h</span>} />
        <AttributeSmall label="RDKS" value={product.hasTPMS ? "Ja" : "Nein"} />
        {/*<AttributeSmall label="Saison" value={product.season === "summer" ? "Sommer" : "Winter"}/>*/}
    </div>
)

export {AttributeSmall}
export default ProductOverviewRow
