import React, {Component, useEffect, useState} from "react"
import {Button, Container} from "semantic-ui-react"
import Icon from "utils/Icon"
import "./ShopContainer.css"
import {observable} from "mobx"
import {observer} from "mobx-react"
import ErrorBoundary from "../ErrorBoundary"
import {faArrowLeft, faArrowRight, faCube, faTimes} from "@fortawesome/pro-light-svg-icons"
import {sidebarStates} from "../../store/uiStore"
import {motion} from "framer-motion"
import currency from "../../utils/currency"
import CartPreviewMinimal, {CartLinks} from "../Cart/CartPreviewMinimal"

let sidebarVisible = false


const RightSidebar = observer(({stack}) => {

	const [showMini, setShowMini] = useState(false)
	const isMobile                = locationStore.isMobile

	let width            = "320px"
	let offset           = "0"
	const {sidebarState} = uiStore

	switch (uiStore.sidebarState) {
		case sidebarStates.HIDDEN:
			width  = "320px"
			offset = "100%"
			break;
		case sidebarStates.MINI:
			width  = "120px"
			offset = "203px"
			break;
		case sidebarStates.VISIBLE:
			width  = "320px"
			offset = "0"
			break;
	}


	const container = {
		[sidebarStates.HIDDEN]:  {
			opacity: 0,
			scale:   1
		},
		[sidebarStates.MINI]:    {
			opacity: 1,
			scale:   1,
			width:   "120px",
		},
		[sidebarStates.VISIBLE]: {
			opacity:    0,
			scale:      1,
			transition: {
				delayChildren:   0.3,
				staggerChildren: 0.2
			}
		}
	};


	const containerFull = {
		[sidebarStates.HIDDEN]:  {
			opacity: 0,
			left:    "100%",
		},
		[sidebarStates.MINI]:    {
			opacity: 0,
			scale:   1,
		},
		[sidebarStates.VISIBLE]: {
			opacity:    1,
			scale:      1,
			transition: {
				delayChildren:   0.5,
				staggerChildren: 0.25
			}
		}
	};

	const itemMini = {
		[sidebarStates.HIDDEN]:  {
			opacity: 0,
			scale:   1
		},
		[sidebarStates.MINI]:    {
			opacity: 1,
			scale:   1
		},
		[sidebarStates.VISIBLE]: {
			opacity:    0,
			scale:      1,
			transition: {
				delayChildren:   0.3,
				staggerChildren: 0.2
			}
		}
	};

	const item = {
		[sidebarStates.HIDDEN]:  {
			opacity: 1,
		},
		[sidebarStates.MINI]:    {
			opacity: 0,
			left:    -100,
		},
		[sidebarStates.VISIBLE]: {
			opacity:    1,
			scale:      1,
			transition: {
				delayChildren:   0.3,
				staggerChildren: 0.2
			}
		}
	};

	return <div
		style={{
			transform: `translateX(${offset}) translateY(0px)`,
		}}
		className={`RightSidebar border-l-4 border-gray-300 bg-white md:px-8 px-4 shadow-lg fixed right-0 top-0 bottom-0 transition-all duration-300 ease-in-out z-999`}>
		<ErrorBoundary>
			<motion.div
				key={"sidebar"}
				style={{
					width: isMobile ? "320px" : "320px" // 100% doesn't work on mobile
				}}
				className={`w-100 relative z-999 px-4 sm:px-0 h-100 `}
			>

				<motion.div
					key={"sidebar-top"}
					variants={{
						[sidebarStates.HIDDEN]:  {
							opacity: 0,
							scale:   1
						},
						[sidebarStates.MINI]:    {
							opacity: 1,
							scale:   1,
							width:   "120px",
						},
						[sidebarStates.VISIBLE]: {
							opacity:    1,
							scale:      1,
							transition: {
								delayChildren:   0.3,
								staggerChildren: 0.2
							}
						}
					}}
					animate={sidebarState}
					className="ok pt-4">
					<div className="flex justify-end sm:justify-between items-center pb-3 sm:pb-0">
						<div className="hidden sm:block w-full pr-1">
							<Button basic size="small" fluid

									onClick={() => {
										uiStore.setSidebarState(sidebarState === sidebarStates.MINI ? sidebarStates.HIDDEN : sidebarStates.MINI)
									}}
							>
								<Icon icon={faArrowRight}/>
							</Button>
						</div>
						{sidebarState === sidebarStates.VISIBLE && <div className="w-1/3">
							<Button
								basic size="small" fluid
								onClick={() => {
									uiStore.setSidebarState(sidebarStates.HIDDEN)
								}}
								icon={<Icon icon={faTimes}/>}
							/>
						</div>}

					</div>
				</motion.div>

				<motion.div
					variants={container}
					initial="hidden"
					animate={sidebarState}
					className="sidebar-wrapper transition-all duration-800 ease-in-out absolute z-999"
					onClick={() => {
						//console.log("CLICK")
						uiStore.setSidebarState(sidebarStates.VISIBLE)
					}}
				>

					<div className="sidebar-header pt-4 ">

						<div className="text-center">

							<div className="leading-tight pb-2">
								<div className="text-xs text-gray-500">Zwischensumme</div>
								<div className="text-lg font-bold">
									{currency(cartStore.total, 2, "-")}
								</div>
							</div>

							<div>
								<Button fluid primary size={"tiny"}>
									<div className="text-xs">zum Warenkorb</div>
								</Button>
							</div>
						</div>


					</div>


					<div className={`sidebar-header pt-4 absolute left-0 right-0 ${sidebarState !== sidebarStates.MINI && "pointer-events-none"}`}>
						{cartStore.products.map((product, index) => {
							return <motion.div
								className="mb-2"
								variants={itemMini}
								animate={sidebarState}
								key={"mini-" + product.productID}
							>
								<div
									className="p-3 border border-gray-200 flex flex-row justify-center rounded text-xs">
									<div className="text-2xl text-gray-400 font-bold leading-none">
										<Icon icon={faCube}/>
									</div>
								</div>
								<div
									className="text-sm text-left text-gray-500 font-bold pt-1 pb-2">{currency(product.price)}</div>
							</motion.div>
						})}
					</div>
				</motion.div>

				<div className="max-h-[100dvh] overflow-y-auto pb-8 sm:pb-0 h-full">
					<motion.div
						variants={containerFull}
						animate={sidebarState}
						className={`${sidebarState === sidebarStates.VISIBLE ? "z-999" : "z-10"}`}>
						<CartPreviewMinimal showStandardTitle key={"cart-prv"}/>
						<CartLinks
							toCart
							onClick={() => {
							uiStore.setSidebarState(sidebarStates.HIDDEN)
						}}/>
						{stack.length === 0 && <>

						</>}

					</motion.div>
				</div>
			</motion.div>

		</ErrorBoundary>
	</div>
})


// TODO: Unify in ShopContainer
const ToggleSidebar = ({
	className = "",
	inverted = false,
	children = "Filter anzeigen",
	...rest
}) => {
	const [sidebar, setSidebar] = React.useState(true)

	const switchSidebar = () => {
		const $body      = document.querySelector("body")
		const hasSidebar = document
			.querySelector("body")
			.classList.contains("sidebar-left-on")
		// console.log( "========== TOGGLE SIDEBAR", {sidebar, sidebarVisible} )
		if ($body) {
			if (!hasSidebar) {
				$body.classList.add("sidebar-left-on")
				$body.classList.remove("sidebar-left-off")
				sidebarVisible = true
			}
			else {
				$body.classList.add("sidebar-left-off")
				$body.classList.remove("sidebar-left-on")
				sidebarVisible = false
			}
		}
	}

	return (<Button
		basic
		className={`ToggleSidebar ${className}`}
		inverted={inverted}
		fluid
		{...rest}
		onClick={() => {
			setSidebar(!sidebar)
			switchSidebar()
		}}
	>
		{children}
	</Button>)
}

export {ToggleSidebar}

const sidebarRight = observable({
									stack:    [],
									hide:     false,
									optional: false
								})

let stackId = 0

/**
 * @return {null}
 */
export function ShopSidebarContext({
	children,
	id,
	hide,
	replace = true,
	optional = false
}) {
	useEffect(() => {
		let entry       = {id: id || Math.random().toString(32) + stackId++}
		entry.Component = children
		entry.optional  = optional
		entry.replace   = replace
		sidebarRight.stack.push(entry)
		let oldhide = sidebarRight.hide
		if (hide !== undefined) sidebarRight.hide = hide

		let oldOptional = sidebarRight.optional
		if (optional !== undefined) sidebarRight.optional = optional

		return () => {
			sidebarRight.stack = sidebarRight.stack.filter(
				(s) => s.id !== entry.id
			)
			if (hide !== undefined) sidebarRight.hide = oldhide
			if (optional !== undefined) sidebarRight.optional = oldOptional
		}
	}, [children, hide, optional])
	return null
}

const sidebarLeft = observable({
								   stack:    [],
								   hide:     false,
								   optional: false
							   })

let stackLeftId = 0

function DefaultSidebarFooter() {
	return null
}

/**
 * @return {null}
 */
export function ShopSidebarLeftContext({
	wide,
	children,
	id,
	header,
	footer = <DefaultSidebarFooter/>,
	hide,
	slot,
	replace = true,
	optional = false,
	mobile = {}
}) {
	useEffect(() => {
		let entry       = {id: id || Math.random().toString(32) + stackLeftId++}
		entry.Component = children || slot
		entry.optional  = optional
		entry.header    = header
		entry.footer    = footer
		entry.replace   = replace
		entry.wide      = wide
		sidebarLeft.stack.push(entry)
		let oldhide = sidebarLeft.hide
		if (hide !== undefined) sidebarLeft.hide = hide

		let oldOptional = sidebarLeft.optional
		if (optional !== undefined) sidebarLeft.optional = optional

		return () => {
			sidebarLeft.stack = sidebarLeft.stack.filter(
				(s) => s.id !== entry.id
			)
			if (hide !== undefined) sidebarLeft.hide = oldhide
			if (optional !== undefined) sidebarLeft.optional = oldOptional
		}
	}, [children, hide, optional, slot])
	return null
}

export let bottomDrawerBaseStack = []
export const bottomDrawer        = observable({
												  stackIds: [],
												  hide:     false
											  })

let bottomDrawerId = 0

/**
 * @return {null}
 */
export function ShopBottomDrawerContext({
	children,
	hide,
	replace = true
}) {
	useEffect(() => {
		let entry = {id: Math.random().toString(32) + stackLeftId++}

		entry.Component = children
		entry.replace   = replace
		bottomDrawerBaseStack.push(entry)
		bottomDrawer.stackIds.push(entry.id)
		let oldhide = bottomDrawer.hide
		if (hide !== undefined) bottomDrawer.hide = hide

		return () => {
			bottomDrawer.stackIds = bottomDrawer.stackIds.filter((id) => id !== entry.id)
			bottomDrawerBaseStack = bottomDrawerBaseStack.filter((s) => s.id !== entry.id)
			if (hide !== undefined) bottomDrawer.hide = oldhide
		}
	}, [children, hide])
	return null
}

class ShopContainer extends Component {
	render() {
		let {
				children,
				sidebar,
				fluid     = false,
				loading,
				className = "px-4 lg:px-6"
			} = this.props

		// const showCartSidebar = shop.uiStore.showCartSidebar
		const {activeStore} = locationStore
		let {
				stack,
				hide
			}               = sidebarRight

		const rightSidebarStack = [stack[stack.length - 1]].filter((e) => e)
		const hasRightSidebar   = !hide && stack.length > 0

		const leftSidebarStack = [sidebarLeft.stack[sidebarLeft.stack.length - 1]].filter(e => e)
		const hasLeftSidebar   = (!sidebarLeft.hide && sidebarLeft.stack.length > 0)

		const bottomDrawerStack = [bottomDrawerBaseStack[bottomDrawer.stackIds.length - 1]].filter((e) => e)
		const hasbottomDrawer   = !bottomDrawer.hide && bottomDrawer.stackIds.length > 0
		return (<div
			className={` ${loading ? "loading" : ""} ${hasRightSidebar ? "hasRightSidebar" : ""} ${hasLeftSidebar ? "hasLeftSidebar" : ""}`}
		>

			<Container
				className={`ShopContainer print:p-0 md:pt-4 pb-8 lg:pb-10 flex sm:flex-row ${fluid ? "isFluid" : "isNonFluid"}`}>
				{hasLeftSidebar ? (<div
					className={`ShopSidebar print:hidden SidebarHome ShopSidebarContext ShopSidebarLeft ${sidebarLeft.optional ? "optional" : ""}`}
					style={{minWidth: hasRightSidebar ? "230px" : "320px"}}
				>
					<ErrorBoundary>

						{leftSidebarStack.map((entry, index) => {
							// console.log( "LSS entry", {entry} )
							if (!entry.Component) return null
							let {Component} = entry
							return (<div
								key={`left-${entry.id}`}
								className={`SidebarContext h-100 SidebarLeftEntry key-${entry.id}`}
							>
								{entry.header && (<div className="SidebarHeader SidebarLeftHeader">
									{entry.header}
								</div>)}
								<div
									key={`content-key-${entry.id}`}
									className="SidebarContent h-100 SidebarLeftContent ph4-m pb4-m"
								>
									{Component}
								</div>
								{entry.footer && (<div className="SidebarFooter SidebarLeftFooter ph4-m">
									{entry.footer}
								</div>)}
							</div>)
						})}
					</ErrorBoundary>
				</div>) : (<ErrorBoundary>
					{sidebar && !sidebarLeft.hide && (<div
						className="ShopSidebar print:hidden ShopSidebarLeft "
						style={{
							maxWidth: hasRightSidebar ? "240px" : "320px"
						}}
					>
						{sidebar}
					</div>)}
				</ErrorBoundary>)}
				<div className={`ShopContent  w-100  ${className}`}>
					{React.Children.map(children, (c) => c)}
				</div>


			</Container>

			<RightSidebar stack={rightSidebarStack}/>


		</div>)
	}
}


export default observer(ShopContainer)


ShopContainer.defaultProps = {
	sidebarRight: null,
	sidebar:      null
}
