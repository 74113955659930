import React from "react"
import {toast} from "react-toastify"
import {Button, Form, Modal, Search, TextArea} from "semantic-ui-react"
import shop from "store/shop"
import Icon from "utils/Icon"
import {faArrowRight, faPlus} from "@fortawesome/pro-solid-svg-icons"
import {Link} from "react-router-dom"
import {sidebarStates} from "store/uiStore"
import "./QuickOrderSearchBar.css"
import {faSearch} from "@fortawesome/pro-light-svg-icons";

export function QuickorderInput(props) {
    return (
        <div className={`QuickorderInput ${props.className || ""}`}>
            <div className="relative">
                <QuickOrderSearch {...props}/>
            </div>
        </div>
    )
}

export function QuickOrderSearchBar(props) {
    return (
        <div className={`QuickOrderSearchBar ${props.className || ""}`}>
            <div className="flex flex-row items-center relative">
                <div className="absolute left-0 pl-6 z-10"><Icon icon={faSearch} className={"text-2xl text-gray-400"}/></div>
                <div className="relative w-full">
                    <QuickOrderSearch/>
                </div>
            </div>
        </div>
    )
}


export function QuickOrderSearch({
                                     onSubmit = () => {
                                     },
                                     insetPlaceholder = false,
                                     showSidebarAfterwards = true
                                 }) {
    const [searchString, setSearchString] = React.useState("")
    const [gotFocus, setGotFocus] = React.useState(false)
    const [searchResult, setSearchResult] = React.useState([])

    const [newEntry, setNewEntry] = React.useState(["", null])
    const [remark, setRemark] = React.useState("")

    const addEntry = (productTitle, product, comment) => {
        try {
            if (product && product.type) {
                let success = false
                switch (product.type) {
                    case "SRV":
                        success = cartStore.addPosition(product, 1, comment)
                        break
                    default:
                        if (showSidebarAfterwards) {
                            uiStore.setSidebarState(sidebarStates.VISIBLE)
                        }
                        success = cartStore.addCustomServiceProduct("", "Reparaturwunsch", comment)
                }

                if(success){
                    setSearchString("")
                    setRemark("")
                    setNewEntry(["", null])
                    if (showSidebarAfterwards) {
                        uiStore.setSidebarState(sidebarStates.VISIBLE)
                    }
                    toast.success("Warenkorb aktualisiert", {position: "bottom-center"})
                } else {
                    toast.error("Bitte Standort & Marke wählen.", {position: "bottom-center"})
                }

            }
        } catch (addEntryException) {
            // don't show toast.
        }
    }
    const [entryTitle, entry] = newEntry

    const searchPlaceholderText = locationStore.isMobile ? "Beschreiben Sie Ihren Reparaturwunsch." : "z.B. Motorlampe blinkt, Inspektion oder TÜV..."

    return <div className="QuickOrderSearch flex flex-row relative items-center">
        <Search
            disabled={!!entry}
            onResultSelect={(event, selected) => {
                if (selected.result.item?.inert) return
                const product = selected.result.item
                if (!product) return

                let productTitle = ""
                if (product.type === "SRV") {
                    productTitle = `${product.title} - ${product.priceText ? product.priceText : ""}`
                } else {
                    if (entryTitle) {
                        productTitle = `${product.title} - "${entryTitle}"`
                    } else {
                        productTitle = `${product.title}`
                    }

                }
                product.value = productTitle
                setNewEntry([productTitle, product])
                setRemark( `${searchString}` )
            }}
            noResultsMessage={"Keine passenden Produkte gefunden"}
            selectFirstResult
            resultRenderer={SearchResult}
            results={[
                {
                    key: "FREE_PRODUCT_KEY",
                    title: "",
                    item: {
                        productID: "FREE_PRODUCT",
                        title: `Ihr Werkstattbesuch`,
                        categoryName: "Termin",
                        descriptionText: `Ihr Reparatur- oder Servicewunsch. Wir helfen vor Ort zum Thema: "${searchString}"`,
                        type: "FREE_PRODUCT"
                    }
                },
                {
                    key: "DIVIDER",
                    title: "Weitere Produkte",
                    item: searchResult.length > 0 ? {
                        title: "Weitere Produkte",
                        inert: true
                    } : undefined
                }
                , ...searchResult.slice(0, 4)]}
            onSearchChange={(e) => {
                const value = e.currentTarget.value
                setSearchString(value)
                if (!shop.searchStore.itemsSearcher) {
                    console.error("Search not available")
                    return
                }
                const search = shop.searchStore.itemsSearcher.search(value)
                setSearchResult(search.map(e => {
                    e.title = e.item.title
                    return e
                }))
            }}
            value={searchString}
            size={"mini"}
            fluid
            placeholder={searchPlaceholderText}
            className={`flex-1 z-3 absolute ${gotFocus || !insetPlaceholder ? "o-100" : "o-0"}`}
            onFocus={() => setGotFocus(true)}
            onBlur={() => setGotFocus(false)}
        />
        {entry &&
            <>
                <div className="fixed left-0 right-0 top-0 bottom-0 z-2" onClick={() => setNewEntry(["", null])}/>
                <Modal
                    open
                    className=""
                    as={Form}
                    onSubmit={() => {
                        addEntry(entryTitle, entry, remark)
                    }}>
                    <div className="ModalHeader p-4 lg:p-6 lg:px-12 border-bottom md:text-2xl font-bold">
                        <div className="flex-1 mb2">
                            {entry.priceText &&
                                <div className="ml-auto pl3 b text-xl xl:text-3xl nowrap fr"> {entry.priceText} </div>
                            }
                            <div className="text-xl xl:text-3xl">{entry.title}</div>
                            {entry.categoryName && (entry.descriptionText || entry.text) &&
                                <div className="fw4 text-base ellipsis lh-copy pt-2">{entry.categoryName} - {entry.descriptionText || entry.text}</div>
                            }
                        </div>
                    </div>
                    <Modal.Content className="">

                        <div className="">
                            <Form.Input
                                autoFocus
                                className="quicksearch-comment"
                                placeholder="Anmerkung hinzufügen (optional)"
                                value={remark}
                                onChange={(e) => {
                                    const value = e.currentTarget.value
                                    if (value.length < 120) setRemark(value)
                                }}/>
                        </div>

                    </Modal.Content>
                    <Modal.Actions>
                        <div className=" ">
                            {/* <label htmlFor="i" className="f7 black-50">Anmerkung hinzufügen (optional)</label> */}

                            <div className="flex flex-row justify-end">
                                <Button
                                    type="button"
                                    onClick={() => {
                                        setNewEntry(["", null])
                                    }} basic
                                >Abbrechen</Button>
                                <Button
                                    type="submit" primary
                                >In den Warenkorb</Button>
                            </div>
                        </div>
                    </Modal.Actions>
                </Modal>
            </>
        }
        {insetPlaceholder && !gotFocus && (
            <div style={{boxShadow: "inset 0 0 6px rgb(34 36 38 / 25%)"}}
                 className={`${gotFocus ? "dn" : "flex"} flex-row absolute flex-1 pointer-events-none top-0 right-0 bottom-0 left-0 items-center pa3 black-30 bg-black-05 rounded-md`}>
                <Icon className={"blue f6 mr2"} icon={faPlus}/>
                <span className="f6">Reparaturwunsch ...</span>
            </div>
        )}
    </div>
}


function SearchResult({item}) {
    return !item
        ? <></>
        : item.inert
            ? <div key={item.productID} className="text-xs uppercase text-gray-500 pointer-events-none">{item.title}</div>
            : (!item?.priceText && item?.href)
                ? <Link to={item.href} key={item.productID}>
                    <div className="f6 text-gray-600">
                        <Icon icon={faArrowRight} size="sm" className="fr ml3 mt1"/>
                        <div className="b">{item.title}</div>
                        {item.categoryName && (item.descriptionText || item.text) &&
                            <div className="fw4 ellipsis line-clamp-3">{item.categoryName} - {item.descriptionText || item.text}</div>
                        }
                    </div>
                </Link>
                : <div key={item.productID}>
                    <div className="flex flex-row items-start text-gray-600 ">
                        <div>
                            <div className="b f6">{item.title}</div>
                            {item.categoryName && (item.descriptionText || item.text) &&
                                <div className="fw4 ellipsis line-clamp-3">{item.categoryName} - {item.descriptionText || item.text}</div>
                            }
                        </div>
                        {item.priceText &&
                            <div className="ml-auto pl3 b f6 nowrap"> {item.priceText} </div>
                        }
                    </div>
                </div>
}

export function StoreProductMainTileInputProduct(props) {
    const [remark, setRemark] = React.useState("")
    const clickHandler = () => {
        setRemark("")
        onClick && onClick(remark)
    }
    const {className = "", title, onClick, icon, body, cta, to} = props

    return (
        <div className={`StoreProductMainTile outline-border pa3 ${className}`}>
            <div className={`w-100 ${cta && "mb5"}`}>
                <div className="tile-icon">{icon}</div>
                <div className="f3 b uppercase lh-title mb2">{title}</div>
                <div className={"mb1 lh-copy"}>{body}</div>
                <div className="pt2">
                    <Form>
                        <TextArea
                            onChange={(e) => {
                                setRemark(e.currentTarget.value)
                            }} size={"small"} value={remark} placeholder={"z.B. Klappern/Rasseln im Kofferraum. Warnlampensignale etc."}
                        />
                    </Form>
                </div>
            </div>

            <div className="tile-buttons">
                {cta && <Button primary size="small" onClick={clickHandler}>
                    {cta}
                </Button>
                }
            </div>
        </div>
    )
}
