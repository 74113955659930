const QUERY_VIP = `query ($brand: String, $storeId: String) {
  getPackageServices(serviceType: vipService, brand: $brand,  location: $storeId) {
    id
    brand
    name
    tags
    shortName
    rating {
      amount
      average
    }
    services {
      included
      excluded
    }
    categories{
      mainCategory{
        id
        name
      }
      subCategory{
        id
        name
      }
    }
    workDuration
    workPositionNumber
    vehicleType
    image
    text
    videoId
    pricing {
      priceFlag
      availableDays
      location
      brand
      price
      srp
    }
  }
}
`

export default QUERY_VIP
